import Vue from 'vue';
import { cloneDeep } from 'lodash';
import ApiService from '@/common/api.service';
import { CartService } from '@/common/api.service';
import JwtService from '@/common/jwt.service';

import {
  FETCH_CART,
  ADD_TO_CART,
  DELETE_ENTRY,
  CHECK_DOMAIN,
  CART_RESET_STATE,
  UPDATE_PRODUCT_QUANTITY,
  UPDATE_ITEMS_IN_CART
} from './actions.type';

import {
  SET_ADD_ERROR,
  SET_AVAILABILITY,
  SET_CART_DATA_FETCHED,
  SET_CHECK_AVAILABILITY_ERROR,
  SET_CART_LOADING,
  RESET_CART_DATA_FETCHED,
  RESET_STATE,
  SET_ID,
  SET_SHOW_SEARCHED_DOMAINS,
  SET_TOTAL_AMOUNT_EURO,
  SET_TOTAL_AMOUNT_RON,
  SET_TOTAL_AMOUNT_EURO_NO_VAT,
  SET_TOTAL_AMOUNT_RON_NO_VAT,
  SET_TOTAL_TAX_EURO,
  SET_TOTAL_TAX_RON,
  SET_UPDATE_CART,
  SET_CHECK_AVAILABILITY_LOADER,
  SET_ITEMS_IN_CART
} from './mutations.type'

const initialState = {
  id: 0,
  totalAmountEuro: 0,
  totalAmountRon: 0,
  totalAmountEuroNoVat: 0,
  totalAmountRonNoVat: 0,
  totalTaxEuro: 0,
  totalTaxRon: 0,
  itemsInCart: 0,
  entries: [],
  cartDataFetched: false,
  domains: [{
    domainName: '',
    domainPriceEuro: 0,
    available: Boolean
  }],
  checkAvailabilityLoader: false,
  showSearchedDomains: false,
  addToCartError: false,
  checkAvailabilityError: false,
  isCartLoading: false,
};

export const state = cloneDeep(initialState);

const getters = {
  cartDataFetched(state) {
    return state.cartDataFetched;
  },
  entries(state) {
    return state.entries;
  },
  totalAmountEuro(state) {
    return state.totalAmountEuro;
  },
  domains(state) {
    return state.domains;
  },
  showSearchedDomains(state) {
    return state.showSearchedDomains;
  },
  addToCartError(state) {
    return state.addToCartError;
  },
  totalAmountEuroNoVat(state) {
    return state.totalAmountEuroNoVat;
  },
  totalAmountRonNoVat(state) {
    return state.totalAmountRonNoVat;
  },
  totalTaxEuro(state) {
    return state.totalTaxEuro;
  },
  totalTaxRon(state) {
    return state.totalTaxRon;
  },
  checkAvailabilityError(state) {
    return state.checkAvailabilityError;
  },
  isCartLoading(state) {
    return state.isCartLoading;
  },
  checkAvailabilityLoader(state) {
    return state.checkAvailabilityLoader;
  },
  itemsInCart(state) {
    return state.itemsInCart;
  },
};

const actions = {
  [FETCH_CART]({ commit }) {
    if (JwtService.getToken()) {
      if (!state.cartDataFetched) {
        commit(SET_CART_DATA_FETCHED);
        commit(SET_ITEMS_IN_CART);
        ApiService.setHeader();

        return CartService.getCart()
          .then(({ data }) => {
            commit(SET_UPDATE_CART, data.entries);
            commit(SET_TOTAL_AMOUNT_EURO, data.totalAmountEuro);
            commit(SET_TOTAL_AMOUNT_RON, data.totalAmountRon);
            commit(SET_TOTAL_AMOUNT_EURO_NO_VAT, data.totalAmountEuroNoVat);
            commit(SET_TOTAL_AMOUNT_RON_NO_VAT, data.totalAmountRonNoVat);
            commit(SET_TOTAL_TAX_EURO, data.totalTaxEuro);
            commit(SET_TOTAL_TAX_RON, data.totalTaxRon);
            commit(SET_ID, data.id);
          })
          .catch(errorResponse => {
            console.log('Error: ', errorResponse)
          });
      }
    }
  },
  [ADD_TO_CART]({ commit }, params) {
    return CartService.addToCart(params)
      .then(({ data }) => {
        const dataObject = data[data.length - 1];
        commit(SET_UPDATE_CART, dataObject.entries);
        commit(SET_TOTAL_AMOUNT_EURO, dataObject.totalAmountEuro);
        commit(SET_TOTAL_AMOUNT_RON, dataObject.totalAmountRon);
        commit(SET_TOTAL_AMOUNT_EURO_NO_VAT, dataObject.totalAmountEuroNoVat);
        commit(SET_TOTAL_AMOUNT_RON_NO_VAT, dataObject.totalAmountRonNoVat);
        commit(SET_TOTAL_TAX_EURO, dataObject.totalTaxEuro);
        commit(SET_TOTAL_TAX_RON, dataObject.totalTaxRon);
        commit(SET_ID, dataObject.id);
      })
      .catch(error => {
        if (error.response && (error.response.status === 400 || error.message.includes('400'))) {
          commit(SET_ADD_ERROR, true);
        }
      });
  },
  [DELETE_ENTRY]({ commit }, slug) {
    return CartService.deleteEntry(slug)
      .then(({ data }) => {
        commit(SET_UPDATE_CART, data.entries);
        commit(SET_TOTAL_AMOUNT_EURO, data.totalAmountEuro);
        commit(SET_TOTAL_AMOUNT_RON, data.totalAmountRon);
        commit(SET_TOTAL_AMOUNT_EURO_NO_VAT, data.totalAmountEuroNoVat);
        commit(SET_TOTAL_AMOUNT_RON_NO_VAT, data.totalAmountRonNoVat);
        commit(SET_TOTAL_TAX_EURO, data.totalTaxEuro);
        commit(SET_TOTAL_TAX_RON, data.totalTaxRon);
        commit(SET_ID, data.id);
      })
      .catch(errorResponse => {
        console.log('Error: ', errorResponse)
      });
  },
  [CHECK_DOMAIN]({ commit }, params) {
    if (params.length > 3 || params.length == 0) {
      commit(SET_CHECK_AVAILABILITY_ERROR, true);
    }
    commit(SET_CHECK_AVAILABILITY_LOADER, true);

    return CartService.checkAvailability(params)
      .then(({ data }) => {
        commit(SET_AVAILABILITY, data.result);
        commit(SET_CHECK_AVAILABILITY_LOADER, false);
      })
      .catch(errorResponse => {
        console.log('Error: ', errorResponse)
        commit(SET_CHECK_AVAILABILITY_LOADER, false);
      });
  },
  [UPDATE_PRODUCT_QUANTITY]({ commit }, params) {
    return CartService.updateProductQuantity(params)
      .then(({ data }) => {
        commit(SET_UPDATE_CART, data.entries);
        commit(SET_TOTAL_AMOUNT_EURO, data.totalAmountEuro);
        commit(SET_TOTAL_AMOUNT_RON, data.totalAmountRon);
        commit(SET_TOTAL_AMOUNT_EURO_NO_VAT, data.totalAmountEuroNoVat);
        commit(SET_TOTAL_AMOUNT_RON_NO_VAT, data.totalAmountRonNoVat);
        commit(SET_TOTAL_TAX_EURO, data.totalTaxEuro);
        commit(SET_TOTAL_TAX_RON, data.totalTaxRon);
        commit(SET_ID, data.id);
      })
      .catch(errorResponse => {
        console.log('Error: ', errorResponse)
      });
  },
  [UPDATE_ITEMS_IN_CART]({ commit }, items) {
    commit(SET_ITEMS_IN_CART, items);
  },
  [CART_RESET_STATE]({ commit }) {
    commit(RESET_STATE);
  }
};

const mutations = {
  [SET_CART_LOADING](state, flag) {
    state.isCartLoading = flag;
  },
  [SET_CART_DATA_FETCHED](state) {
    state.cartDataFetched = true;
  },
  [SET_UPDATE_CART](state, data) {
    state.entries = data;
  },
  [SET_TOTAL_AMOUNT_EURO](state, data) {
    state.totalAmountEuro = data;
  },
  [SET_TOTAL_AMOUNT_RON](state, data) {
    state.totalAmountRon = data;
  },
  [SET_ID](state, data) {
    state.id = data;
  },
  [SET_AVAILABILITY](state, data) {
    state.domains = data;
  },
  [SET_SHOW_SEARCHED_DOMAINS](state, flag) {
    state.showSearchedDomains = flag;
  },
  [RESET_CART_DATA_FETCHED](state) {
    state.cartDataFetched = false;
  },
  [SET_ADD_ERROR](state, flag) {
    state.addToCartError = flag;
  },
  [SET_TOTAL_AMOUNT_EURO_NO_VAT](state, data) {
    state.totalAmountEuroNoVat = data;
  },
  [SET_TOTAL_AMOUNT_RON_NO_VAT](state, data) {
    state.totalAmountRonNoVat = data;
  },
  [SET_TOTAL_TAX_EURO](state, data) {
    state.totalTaxEuro = data;
  },
  [SET_TOTAL_TAX_RON](state, data) {
    state.totalTaxRon = data;
  },
  [SET_CHECK_AVAILABILITY_ERROR](state, flag) {
    state.checkAvailabilityError = flag;
  },
  [SET_CHECK_AVAILABILITY_LOADER](state, flag) {
    state.checkAvailabilityLoader = flag;
  },
  [SET_ITEMS_IN_CART](state, value) {
    state.itemsInCart = value;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  }
};

export default {
  actions,
  getters,
  mutations,
  state
};