<template>
  <div class="TheBreadcrumbs">
    <div class="o-container">
      <ul v-if="computeCrumbs.length > 1">
        <li v-for="(crumb, index) in computeCrumbs" :key="index">
          <span v-if="index < computeCrumbs.length - 1" class="step">
            <router-link :to="crumb.path">{{ crumb.name }}</router-link> /&nbsp;
          </span>
          <span class="step last" v-else>{{ crumb.name }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Helpers from '@/common/helpers.js';

export default {
  name: "TheBreadcrumbs",
  props: {
    breadcrumbsRoot: String
  },
  data() {
    return {
      links: {
        aboutUs: {
          text: this.$t('common.aboutUs')
        },
        accountSecurity: {
          text: this.$t('breadcrumbs.accountSecurity')
        },
        accountSettings: {
          text: this.$t('breadcrumbs.accountSettings')
        },
        activateUser: {
          text: this.$t('breadcrumbs.activateUser')
        },
        auctions: {
          text: this.$t('breadcrumbs.auctions'),
          path: '/auctions'
        },
        billingDetails: {
          text: this.$t('breadcrumbs.billingDetails')
        },
        buyWebsite: {
          text: this.$t('breadcrumbs.buyWebsite')
        },
        cart: {
          text: this.$t('breadcrumbs.cart')
        },
        changePassword: {
          text: this.$t('breadcrumbs.changePassword')
        },
        cookies: {
          text: this.$t('breadcrumbs.cookies')
        },
        contact: {
          text: this.$t('common.contact')
        },
        dns: {
          text: this.$t('breadcrumbs.dns')
        },
        domainNames: {
          text: this.$t('breadcrumbs.domainNames')
        },
        domainTransfer: {
          text: this.$t('breadcrumbs.domainTransfer')
        },
        faq: {
          text: this.$t('breadcrumbs.faq')
        },
        forgotPassword: {
          text: this.$t('breadcrumbs.forgotPassword')
        },
        help: {
          text: this.$t('common.help')
        },
        home: {
          text: this.$t('common.home')
        },
        myAccount: {
          text: this.$t('breadcrumbs.myAccount')
        },
        newsletterSubscribe: {
          text: this.$t('breadcrumbs.subscribe')
        },
        newsletterUnsubscribe: {
          text: this.$t('breadcrumbs.unsubscribe')
        },
        news: {
          text: this.$t('breadcrumbs.news')
        },
        payments: {
          text: this.$t('breadcrumbs.payments')
        },
        privacyPolicy: {
          text: this.$t('breadcrumbs.privacyPolicy')
        },
        resetPassword: {
          text: this.$t('breadcrumbs.resetPassword')
        },
        sellWebsite: {
          text: this.$t('breadcrumbs.sellWebsite')
        },
        signIn: {
          text: this.$t('common.auth.signin')
        },
        signUp: {
          text: this.$t('common.auth.signUp')
        },
        status: {
          text: this.$t('breadcrumbs.status')
        },
        terms: {
          text: this.$t('breadcrumbs.terms')
        },
        userAuctions: {
          text: this.$t('breadcrumbs.userAuctions')
        },
        userDetails: {
          text: this.$t('breadcrumbs.userDetails')
        },
        userDomain: {
          text: this.$t('breadcrumbs.userDomain')
        },
        userDomains: {
          text: this.$t('breadcrumbs.userDomain')
        },
        userDomainsReserve: {
          text: this.$t('breadcrumbs.userDomainsReserve')
        },
        userDomainsRenew: {
          text: this.$t('breadcrumbs.userDomainsRenew')
        },
        whois: {
          text: this.$t('breadcrumbs.whois')
        },
        paymentResponse: {
          text: this.$t('breadcrumbs.paymentResponse')
        },
      },
      sourcePagePath: 'sourcePageNumberAuctions'
    };
  },
  computed: {
    computeCrumbs() {
      let path = '';
      let { title = 'Home' } = { title: this.breadcrumbsRoot };
      let breadcrumbs = [{ name: title, path: '/' }];

      if (!this.$route.matched[0]) {
        return [];
      }

      const routePathArr = (this.$route.path).split('/');

      for (let i = 1; i < routePathArr.length; i++) {
        const rawName = routePathArr[i];
        const tempPath = this.links.auctions.path + '?' + this.sourcePagePath + '=' + this.$route.query.sourcePageNumberAuctions;
        const rawNameTransformedToCamelCase = this.transformPathToName(rawName);

        let pathPrecededBySlash = `/${rawName}`
        let name = this.links[rawNameTransformedToCamelCase];
        let alreadyUsedBreadcrumbs = breadcrumbs.some(el => el.path === pathPrecededBySlash);

        // Condition to check if the key really exists (and that the value wasn't already added in the array), otherwise the next statement might throw Object undefined error
        if (name && !alreadyUsedBreadcrumbs) {
          name = this.links[rawNameTransformedToCamelCase].text
        } else {
          name = rawName
        }

        if (routePathArr[i] !== '') {
          // On auction pages we want to preserve the source page number from the auctions list
          if (rawName.trim() === this.auctionsPath) {
            title += ` : ${name}`;
            path += `${tempPath}`;
          } else if (path.trim() === tempPath) {
            title += ` : ${name}`;
          } else {
            title += ` : ${name}`;
            path += `/${rawName}`;
          }

          breadcrumbs.push({ name, path });
        }
      }

      window.document.title = title;

      return breadcrumbs;
    }
  },
  methods: {
    transformPathToName(pathName) {
      let camelCasedPathName = '';

      if (pathName) {
        camelCasedPathName = Helpers.convertHyphensToCamelCase(pathName);
      }

      return camelCasedPathName;
    }
  }
};
</script>

<style lang="scss" scoped>
.TheBreadcrumbs {
  background-color: $palette-secondary-color-four;
  color: $white;
  text-align: left;

  li {
    display: inline-block;
  }

  a {
    color: $white !important;
    font-weight: bold;
  }

  .o-container {
    margin-bottom: 0;
    padding-top: .4rem;
    padding-bottom: .4rem;
  }

  .step {
    padding-right: .4rem;

    &.last {
      padding-right: 0;
    }
  }
}
</style>
