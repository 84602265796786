<template>
  <div class="TheNavigationHeader">
    <div class="o-container">
      <div class="u-show@desktop">
        <router-link v-for="(item, index) in links"
                     :key="index"
                     :to="`${item.page}`"
                     class="navigation-link green-border-hover">
          {{ item.text }}
        </router-link>
      </div>
    </div> <!-- /.o-container -->
  </div> <!-- /.TheNavigationHeader -->
</template>

<script>
import {
  mapGetters
} from 'vuex';

export default {
  name: 'TheNavigationHeader',
  data() {
    return {
      icons: ['home', 'public', 'gavel', 'search', 'account_circle'],
      links: [
        {
          page: '/',
          text: this.$t('common.home')
        },
        {
          page: '/domain-names',
          text: this.$t('navigationHeader.domainNames')
        },
        {
          page: '/auctions',
          text: this.$t('navigationHeader.domainAuctions')
        },
        // {
        //   page: '/buy-website',
        //   text: this.$t('navigationHeader.buyWebsite')
        // },
        // {
        //   page: '/sell-website',
        //   text: this.$t('navigationHeader.sellWebsite')
        // },
        // {
        //   page: '/whois',
        //   text: this.$t('navigationHeader.whoisLookup')
        // },
        {
          page: '/my-account',
          text: this.$t('navigationHeader.myAccount')
        },
        // {
        //   page: '/about-us',
        //   text: this.$t('common.aboutUs')
        // }
      ]
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
};
</script>

<style lang="scss" scoped>
.TheNavigationHeader {
  @include tablet { padding: 0 2.4rem .2rem; }
  @include desktop { padding: .2rem 3.2rem; }

  .o-container {
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    @include desktop {
      margin-bottom: .8rem;
    }
  }

  .navigation-link {
    @include default-h4;
    padding-right: 3.2rem;
    padding-left: 1rem;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  .green-border-hover {
    display: inline-block;
    line-height: 1.5;
  }

  .green-border-hover:after {
    border-bottom: solid .2rem;
    border-color: $palette-primary-color-two;
    content: '';
    display: block;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .green-border-hover:hover:after {
    transform: scaleX(.9);
  }
}
</style>
