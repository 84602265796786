<template>
  <div class="FooterTop">
    <img src="@/assets/images/inreg_logo_white-200x90.png"
         :alt="this.alt.text.inreg"
         class="footer-logo" />
    <p class="o-default-body-xs-light footer-top-slogan">
      {{ $t('footer.footerTop.slogan') }}
    </p>
  </div> <!-- /.FooterTop -->
</template>

<script>
export default {
  name: 'FooterTop',
  data() {
    return {
      alt: {
        text: {
          inreg: this.$t('footer.footerTop.inregLogoAlt')
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.FooterTop {
  padding-top: 0 !important;

  .o-default-body-xs-light {
    color: $palette-secondary-color-three !important;
    font-size: 1.4rem;
    font-family: $font-family-body-light;
    line-height: 2.4rem;
  }

  .footer-logo {
    margin-bottom: 1.4rem;
    max-width: 6rem;
  }

  .footer-top-slogan {
    width: 20rem;
  }
}
</style>
