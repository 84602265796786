import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { UserAuctionsService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  FETCH_USER_AUCTIONS,
  USER_AUCTION_RESET_STATE
} from './actions.type';
import {
  RESET_STATE,
  SET_USER_AUCTIONS,
  SET_USER_AUCTIONS_ERROR,
  SET_USER_AUCTIONS_LOADER
} from './mutations.type';

const initialState = {
  userAuction: {
    active: false,
    closeDate: '',
    closingSoon: false,
    domain: '',
    id: null,
    offer: null,
    secondsLeft: '',
    tld: '',
    winner: false
  },
  userAuctions: [],
  userAuctionsCount: 0,
  userAuctionsErrors: null,
  isUserAuctionsLoading: true,
};

export const state = cloneDeep(initialState);

const getters = {
  userAuctions(state) {
    return state.userAuctions;
  },
  userAuctionsCount(state) {
    return state.userAuctionsCount;
  },
  isUserAuctionsLoading(state) {
    return state.isUserAuctionsLoading;
  },
  userAuctionsErrors(state) {
    return state.userAuctionsErrors;
  },
};

const actions = {
  [FETCH_USER_AUCTIONS]({ commit }) {
    if (JwtService.getToken()) {
      commit(SET_USER_AUCTIONS_LOADER, true);
      ApiService.setHeader();

      return UserAuctionsService.getUserAuctions()
        .then(({ data }) => {
          commit(SET_USER_AUCTIONS, data);
        })
        .catch(error => {
          console.log('Error: ', error);
          commit(SET_USER_AUCTIONS_ERROR, error);
          throw new Error(error);
        });
    }
  },
  [USER_AUCTION_RESET_STATE]({ commit }) {
    commit(RESET_STATE);
  }
};

const mutations = {
  [SET_USER_AUCTIONS_LOADER](state, loadingFlag) {
    state.isUserAuctionsLoading = loadingFlag;
  },
  [SET_USER_AUCTIONS](state, { auctions, total }) {
    state.userAuctions = auctions;
    state.userAuctionsCount = total;
    state.isUserAuctionsLoading = false;
  },
  [SET_USER_AUCTIONS_ERROR](state, error) {
    state.userAuctionsErrors = error;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
    state.isUserAuctionsLoading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
