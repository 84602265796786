<template>
  <div class="SecuredWith">
    <div class="o-default-h5-light">{{ $t('securedWith.securedWith') }}</div>
    <div class="md-layout">
      <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-33 md-xsmall-size-33">
        <img src="@/assets/images/vendor-logos/lets_encrypt_logo.png"
             :alt="this.alt.text.letsEncrypt"
             class="secured-with-logo" />
      </div>
      <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-33 md-xsmall-size-33">
        <img src="@/assets/images/vendor-logos/cloudflare_logo.png"
             :alt="this.alt.text.cloudflare"
             class="secured-with-logo" />
      </div>
    </div>
  </div> <!-- /.SecuredWith -->
</template>

<script>
export default {
  name: 'SecuredWith',
  data() {
    return {
      alt: {
        text: {
          letsEncryptAlt: this.$t('securedWith.letsEncryptAlt'),
          cloudflareAlt: this.$t('securedWith.cloudflareAlt')
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.SecuredWith {

  .md-layout-item {
    @include flex-center-y;
  }

  .o-default-h5-light {
    font-family: $font-family-heading-regular;
    font-size: 1.6rem;
    padding-bottom: 1.6rem;
  }

  .secured-with-logo {
    max-height: 4rem;
    max-width: 6rem;
  }
}
</style>
