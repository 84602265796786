<template>
  <div class="FooterNavigation">
    <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-50">
      <div class="o-default-h5-light">{{ $t('footer.general') }}</div>
      <router-link v-for="routes in links.general"
                   :key="routes.id"
                   :to="`${routes.page}`"
                   class="footer-navigation-link">
        {{ routes.text }}
      </router-link>
    </div> <!-- /.md-layout-item -->
    <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-50">
      <div class="o-default-h5-light">{{ $t('footer.services') }}</div>
      <router-link v-for="routes in links.services"
                   :key="routes.id"
                   :to="`${routes.page}`"
                   class="footer-navigation-link">
        {{ routes.text }}
      </router-link>
    </div> <!-- /.md-layout-item -->
    <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-50">
      <div class="o-default-h5-light">{{ $t('footer.support') }}</div>
      <a :href="this.links.external.email"
          class="footer-navigation-link">
        {{ $t('contactEmailInreg') }}
      </a>
      <router-link v-for="routes in links.support"
                   :key="routes.id"
                   :to="`${routes.page}`"
                   class="footer-navigation-link">
        {{ routes.text }}
      </router-link>
      <a :href="'//' + links.external.consumerProtectionLinkEn"
          class="footer-navigation-link"
          target="_blank">
        {{ $t('footer.consumerProtectionEn') }}
      </a>
      <a :href="'//' + links.external.consumerProtectionLinkRo"
          class="footer-navigation-link"
          target="_blank">
        {{ $t('footer.consumerProtectionRo') }}
      </a>
    </div> <!-- /.md-layout-item -->
    <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-50">
      <div class="o-default-h5-light">{{ $t('siteUrl') }}</div>
      <router-link v-for="routes in links.inreg"
                   :key="routes.id"
                   :to="`${routes.page}`"
                   class="footer-navigation-link">
        {{ routes.text }}
      </router-link>
    </div> <!-- /.md-layout-item -->
    <div class="md-layout">
      <div class="md-layout-item md-xlarge-size-75 md-large-size-75 md-medium-size-75 md-small-size-100 md-xsmall-size-100 with-divider--from-medium"></div>
      <ChooseLanguage class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-100 md-xsmall-size-100 with-divider"/>
    </div>
  </div> <!-- /.FooterNavigation -->
</template>

<script>
import ChooseLanguage from '@/components/footer/ChooseLanguage';

export default {
  name: 'FooterNavigation',
  components: {
    ChooseLanguage
  },
  data() {
    return {
      links: {
        general: [
          {
            id: 0,
            text: this.$t('footer.home'),
            page: '/'
          },
          {
            id: 1,
            text: this.$t('footer.accountPage'),
            page: "/my-account",
          },
        ],
        services: [
          {
            id: 10,
            text: this.$t('footer.domainNames'),
            page: '/domain-names'
          },
          {
            id: 11,
            text: this.$t('footer.domainAuctions'),
            page: '/auctions'
          },
          {
            id: 12,
            text: this.$t('footer.domainTransfer'),
            page: '/domain-transfer'
          },
          {
            id: 13,
            text: this.$t('footer.whoisLookup'),
            page: '/whois'
          },
        ],
        support: [
          {
            id: 20,
            text: this.$t('common.help'),
            page: '/help'
          },
        ],
        inreg: [
          {
            id: 30,
            text: this.$t('footer.aboutUs'),
            page: '/about-us'
          },
          {
            id: 31,
            text: this.$t('footer.termsAndConditions'),
            page: '/terms'
          },
          {
            id: 32,
            text: this.$t('footer.privacyPolicy'),
            page: '/privacy-policy'
          },
          {
            id: 33,
            text: this.$t('footer.cookies'),
            page: '/cookies'
          },
        ],
        external: {
          consumerProtectionLinkEn: this.$t('footer.consumerProtectionLinkEn'),
          consumerProtectionLinkRo: this.$t('footer.consumerProtectionLinkRo'),
          email: `mailto:${this.$t('contactEmailInreg')}`
        },
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.FooterNavigation {
  padding-top: 0 !important;

  .o-default-h5-light {
    font-family: $font-family-heading-regular;
    font-size: 1.6rem;
    padding: 3.2rem 0 .8rem;
    @media (min-width: 961px) {
      padding: 0 0 .8rem;
    }
  }

  .secured-with-logo {
    max-width: 8rem;
  }

  .footer-navigation-link {
    color: $palette-secondary-color-three !important;
    display: block;
    font-size: 1.4rem;
    font-family: $font-family-body-thin;
    padding-bottom: .8rem;

    &:hover, &:active {
      color: $white !important;
      text-decoration: none;
    }

    &:visited {
      text-decoration: none;
    }
  }

  .with-divider {
    border-bottom: .1rem solid $global-color-one;
    padding: 2.4rem 0;

    &--from-medium {
      @media (min-width: 961px) {
        border-bottom: .1rem solid $global-color-one;
        padding: 2.4rem 0;
      }
    }
  }
}
</style>
