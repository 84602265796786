<template>
  <div class="ChooseLanguage">
    <div class="o-default-h5-light">{{ $t('footer.chooseLanguage') }}</div>
    <LocaleSelector class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 with-divider"/>
  </div> <!-- /.ChooseLanguage -->
</template>

<script>
import LocaleSelector from '@/components/footer/LocaleSelector';

export default {
  name: 'ChooseLanguage',
  components: {
    LocaleSelector,
  },
  data() {
    return {
      alt: {
        text: {
          rotld: 'RoTLD logo',
          payu: 'PayU logo'
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.ChooseLanguage {

  .o-default-h5-light {
    display: block;
    font-family: $font-family-heading-regular;
    font-size: 1.6rem;
    padding-bottom: .8rem;
  }
}
</style>
