<template>
  <div class="ScrollTopArrow" :class="{ 'show': visible }">
    <transition name="scroll">
      <a @click="scrollTop" v-show="visible" class="btn">
        <span class="material-icons md-30">keyboard_arrow_up</span>
      </a>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ScrollTopArrow',
  data () {
    return {
      visible: false
    }
  },
  mounted: function() {
    window.addEventListener('scroll', this.throttle(this.scrollListener, 100))
  },
  beforeDestroy: function() {
    window.removeEventListener('scroll', this.throttle(this.scrollListener, 100))
  },
  methods: {
    throttle(callbackFunction, wait) {
      var time = Date.now();
      return function() {
        if ((time + wait - Date.now()) < 0) {
          callbackFunction();
          time = Date.now();
        }
      }
    },
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 200);
      }, 10);
    },
    scrollListener: function() {
      this.visible = window.scrollY > 480
    }
  },
};
</script>

<style lang="scss" scoped>
.ScrollTopArrow {
  bottom: 6rem;
  cursor: pointer;
  position: fixed;
  right: 3rem;
  z-index: 9;

  .scroll-enter,
  .scroll-leave-to {
    opacity: 0;
  }

  .scroll-enter-active,
  .scroll-leave-active {
    transition: all .25s;
  }

  .btn {
    background-color: $global-color-transparent-one;
    border-radius: 2.5rem;
    padding: 2.6rem 1.0rem .8rem 1.0rem;
  }

  .material-icons {
    color: $white;
  }
}
</style>
