<template>
  <div class="SideNavigation">
    <div class="burger-button" @click="openMenu" :class="{ 'u-hide': !burgerIcon }">
      <i class="material-icons md-30">menu</i>
    </div>
    <div class="bm-overlay"
         @click="hideSideNavFunction"
         :class="{ 'overlay-transition': isSideBarOpen }">
    </div>
    <div ref="sideNav"
         class="side-nav"
         @click="closeMenu">
      <div class="account-container">
        <span class="cross-button"
              @click="closeMenu"
              :class="{ 'u-hide': !crossIcon }">
          <i class="material-icons md-30">close</i>
        </span>
        <span v-if="!isAuthenticated">
          <router-link class="o-default-body-xs side-nav__account-link" :to="links.signUp.page">
            <i class="material-icons">person_add</i>
            <span class="side-nav__account-link-label">{{ links.signUp.text }}</span>
          </router-link>
          <router-link class="o-default-body-xs side-nav__account-link" :to="links.signIn.page">
            <i class="material-icons">person_outline</i>
            <span class="side-nav__account-link-label">{{ links.signIn.text }}</span>
          </router-link>
        </span>
        <span v-else>
          <router-link class="o-default-body-xs side-nav__account-link" :to="links.myAccount.page">
            <i class="material-icons">person_outline</i>
            <span class="side-nav__account-link-label">{{ currentUserEmail }}</span>
          </router-link>
        </span>
      </div>
      <nav class="item-list">
        <slot></slot>
      </nav>
      <span class="side-nav__bottom-menu">
        <md-dialog :md-active.sync="showDialog">
          <md-dialog-title>{{ $t('shortcutMenu.contactUs') }}</md-dialog-title>
            <span class="o-dialog-content--strong u-cursor-pointer"
                  @click="Helpers.copyToClipboard(messages.contactEmail);
                  showCopyConfirmation(snackbar.text.email)">
              <span>{{ messages.contactEmail }}</span>
              <md-tooltip md-direction="bottom">{{ messages.clickToCopy }}</md-tooltip>
            </span>
            <p class="o-default-body o-dialog-content">{{ $t('shortcutMenu.contactUsMain') }}</p>
          <md-dialog-actions>
            <md-button class="md-primary" @click="showDialog = false">{{ $t('common.actions.close') }}</md-button>
          </md-dialog-actions>
        </md-dialog>

        <div class="o-default-body-xs side-nav__account-link" @click="showDialog = true">
          <i class="material-icons">chat_bubble_outline</i>
          <span class="side-nav__account-link-label">{{ $t('shortcutMenu.contactUs') }}</span>
        </div>

        <router-link class="o-default-body-xs side-nav__account-link" :to="links.help.page">
          <i class="material-icons">help_outline</i>
          <span class="side-nav__account-link-label">{{ links.help.text }}</span>
        </router-link>

        <div v-if="isAuthenticated"
             class="o-default-body-xs side-nav__account-link"
             @click="signOut">
          <i class="material-icons">highlight_off</i>
          <span class="side-nav__account-link-label">{{ $t('common.auth.signOut') }}</span>
        </div>
      </span>
    </div> <!-- /.side-nav -->

    <md-snackbar :md-position="snackbar.position"
                 :md-duration="snackbar.duration"
                 :md-active.sync="snackbar.showCopiedToClipboardSnackbar"
                 md-persistent>
      <span>{{ $t('snackbar.copied') }} {{ snackbar.copiedContent }} {{ $t('snackbar.toClipboard') }}</span>
      <md-button class="md-primary" @click="snackbar.showCopiedToClipboardSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </div> <!-- /.SideNavigation -->
</template>

<script>
import { mapGetters } from "vuex";
import { SIGNOUT } from '@/store/actions.type';

export default {
  name: 'BurgerButton',
  props: {
    width: {
      type: String,
      required: false,
      default: '100'
    },
    burgerIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    crossIcon: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  data() {
    return {
      isSideBarOpen: false,
      showDialog: false,
      links: {
        help: {
          page: '/help',
          text: this.$t('common.help')
        },
        myAccount: {
          page: '/my-account',
          text: this.$t('shortcutMenu.myAccount')
        },
        signIn: {
          page: '/sign-in',
          text: this.$t('common.auth.signIn')
        },
        signUp: {
          page: '/sign-up',
          text: this.$t('common.auth.signUp')
        }
      },
      snackbar: {
        showCopiedToClipboardSnackbar: false,
        copiedContent: '',
        duration: 4000,
        position: 'center',
        text: {
          email: this.$t('snackbar.emailAddress'),
        }
      },
      messages: {
        clickToCopy: this.$t('common.clickToCopy'),
        contactEmail: this.$t('contactEmailInreg'),
      },
    };
  },
  computed: {
    ...mapGetters([
      'currentUserEmail',
      'isAuthenticated'
    ])
  },
  methods: {
    hideSideNavFunction() {
      if (this.isSideBarOpen) {
        this.closeMenu();
      }
    },
    openMenu() {
      this.isSideBarOpen = true;
      this.$refs.sideNav.style.width = this.width ? this.width + '%' : '100%';
      this.toggleBodyClass('addClass', 'u-no-scroll');
    },
    closeMenu() {
      this.isSideBarOpen = false;
      this.$refs.sideNav.style.width = '0';
      this.toggleBodyClass('removeClass', 'u-no-scroll');
    },
    signOut() {
      this.$store.dispatch(SIGNOUT);
    },
    showCopyConfirmation(content) {
      this.snackbar.copiedContent = content;
      this.snackbar.showCopiedToClipboardSnackbar = true;
    },
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === 'addClass') {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.SideNavigation {
  display: inline-block;

  .account-container {
    border-bottom: solid .1rem $black;
    color: $black;
    display: block;
    margin: .8rem 1.6rem;
    min-width: 28rem;
    padding: .8rem 0;
  }

  .bm-overlay {
    background: $black;
    opacity: 0;
    position: fixed;
    transition: opacity .5s ease-in-out;
    visibility: hidden;
    z-index: 900;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .burger-button {
    cursor: pointer;
  }

  .cross-button {
    color: $palette-secondary-color-four;
    cursor: pointer;
    height: 3rem;
    position: absolute;
    width: 3rem;
    top: .8rem;
    right: 1.6rem;
  }

  .item-list {
    color: $black;
    font-size: $type-scale-size-6;
    margin-left: 1.6rem;
    min-width: 28rem;

    > a {
      color: $black;
      display: block;
      font-weight: 400;
      padding: .8rem;
      text-decoration: none;
    }
  }

  .material-icons {
    transform: translate(0, .2rem);
  }

  .overlay-transition {
    opacity: .5;
    visibility: visible;
  }

  .side-nav {
    background-color: $white;
    height: 100%; /* 100% Full-height */
    max-width: 32rem;
    overflow-x: hidden; /* Disable horizontal scroll */
    position: fixed; /* Stay in place */
    transition: .3s; /* 0.3 second transition effect to slide in the sidenav */
    width: 0;
    z-index: 1000; /* Stay on top */
    top: 0;
    left: 0;
  }

  .side-nav__account-link {
    color: $black;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    line-height: 3rem;
    padding: .2rem .4rem;
    padding-right: .8rem;
  }

  .side-nav__account-link-label {
    max-width: 18rem;
    overflow: hidden;
    padding: .2rem .4rem;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
  }

  .side-nav__bottom-menu {
    border-top: .1rem solid $black;
    bottom: 0;
    display: block;
    font-size: 1.6rem;
    min-width: 28rem;
    position: absolute;
    padding: 0 .8rem .4rem;
    width: 100%;

    .side-nav__account-link {
      display: block;
      line-height: $type-scale-line-height-8;
      padding: .4rem 1.6rem 0;
    }

    .side-nav__account-link-label {
      line-height: 2.8rem;
      margin-left: .8rem;
    }
  }
}
</style>
