import { cloneDeep } from 'lodash';

const initialState = {
  countries: [
    { value: 'RO', name: 'ROMANIA' },
    { value: 'AF', name: 'AFGHANISTAN' },
    { value: 'AX', name: 'ÅLAND ISLANDS' },
    { value: 'AL', name: 'ALBANIA' },
    { value: 'DZ', name: 'ALGERIA' },
    { value: 'AS', name: 'AMERICAN SAMOA' },
    { value: 'AD', name: 'ANDORRA' },
    { value: 'AO', name: 'ANGOLA' },
    { value: 'AI', name: 'ANGUILLA' },
    { value: 'AQ', name: 'ANTARCTICA' },
    { value: 'AG', name: 'ANTIGUA AND BARBUDA' },
    { value: 'AR', name: 'ARGENTINA' },
    { value: 'AM', name: 'ARMENIA' },
    { value: 'AW', name: 'ARUBA' },
    { value: 'AU', name: 'AUSTRALIA' },
    { value: 'AT', name: 'AUSTRIA' },
    { value: 'AZ', name: 'AZERBAIJAN' },
    { value: 'BS', name: 'BAHAMAS' },
    { value: 'BH', name: 'BAHRAIN' },
    { value: 'BD', name: 'BANGLADESH' },
    { value: 'BB', name: 'BARBADOS' },
    { value: 'BY', name: 'BELARUS' },
    { value: 'BE', name: 'BELGIUM' },
    { value: 'BZ', name: 'BELIZE' },
    { value: 'BJ', name: 'BENIN' },
    { value: 'BM', name: 'BERMUDA' },
    { value: 'BT', name: 'BHUTAN' },
    { value: 'BO', name: 'BOLIVIA, PLURINATIONAL STATE OF' },
    { value: 'BQ', name: 'BONAIRE, SAINT EUSTATIUS AND SABA' },
    { value: 'BA', name: 'BOSNIA AND HERZEGOVINA' },
    { value: 'BW', name: 'BOTSWANA' },
    { value: 'BV', name: 'BOUVET ISLAND' },
    { value: 'BR', name: 'BRAZIL' },
    { value: 'IO', name: 'BRITISH INDIAN OCEAN TERRITORY' },
    { value: 'BN', name: 'BRUNEI DARUSSALAM' },
    { value: 'BG', name: 'BULGARIA' },
    { value: 'BF', name: 'BURKINA FASO' },
    { value: 'BI', name: 'BURUNDI' },
    { value: 'KH', name: 'CAMBODIA' },
    { value: 'CM', name: 'CAMEROON' },
    { value: 'CA', name: 'CANADA' },
    { value: 'CV', name: 'CAPE VERDE' },
    { value: 'KY', name: 'CAYMAN ISLANDS' },
    { value: 'CF', name: 'CENTRAL AFRICAN REPUBLIC' },
    { value: 'TD', name: 'CHAD' },
    { value: 'CL', name: 'CHILE' },
    { value: 'CN', name: 'CHINA' },
    { value: 'CX', name: 'CHRISTMAS ISLAND' },
    { value: 'CC', name: 'COCOS (KEELING) ISLANDS' },
    { value: 'CO', name: 'COLOMBIA' },
    { value: 'CG', name: 'CONGO' },
    { value: 'CD', name: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE' },
    { value: 'CK', name: 'COOK ISLANDS' },
    { value: 'CR', name: 'COSTA RICA' },
    { value: 'CI', name: 'CÔTE D\'IVOIRE' },
    { value: 'KM', name: 'COMOROS' },
    { value: 'HR', name: 'CROATIA' },
    { value: 'CU', name: 'CUBA' },
    { value: 'CW', name: 'CURAÇAO' },
    { value: 'CY', name: 'CYPRUS' },
    { value: 'CZ', name: 'CZECH REPUBLIC' },
    { value: 'DK', name: 'DENMARK' },
    { value: 'DJ', name: 'DJIBOUTI' },
    { value: 'DM', name: 'DOMINICA' },
    { value: 'DO', name: 'DOMINICAN REPUBLIC' },
    { value: 'EC', name: 'ECUADOR' },
    { value: 'EG', name: 'EGYPT' },
    { value: 'SV', name: 'EL SALVADOR' },
    { value: 'GQ', name: 'EQUATORIAL GUINEA' },
    { value: 'EE', name: 'ESTONIA' },
    { value: 'ER', name: 'ERITREA' },
    { value: 'ET', name: 'ETHIOPIA' },
    { value: 'FK', name: 'FALKLAND ISLANDS (MALVINAS)' },
    { value: 'FO', name: 'FAROE ISLANDS' },
    { value: 'FJ', name: 'FIJI' },
    { value: 'FI', name: 'FINLAND' },
    { value: 'FR', name: 'FRANCE' },
    { value: 'GF', name: 'FRENCH GUIANA' },
    { value: 'PF', name: 'FRENCH POLYNESIA' },
    { value: 'TF', name: 'FRENCH SOUTHERN TERRITORIES' },
    { value: 'GA', name: 'GABON' },
    { value: 'GM', name: 'GAMBIA' },
    { value: 'GE', name: 'GEORGIA' },
    { value: 'DE', name: 'GERMANY' },
    { value: 'GH', name: 'GHANA' },
    { value: 'GI', name: 'GIBRALTAR' },
    { value: 'GR', name: 'GREECE' },
    { value: 'GL', name: 'GREENLAND' },
    { value: 'GD', name: 'GRENADA' },
    { value: 'GP', name: 'GUADELOUPE' },
    { value: 'GU', name: 'GUAM' },
    { value: 'GT', name: 'GUATEMALA' },
    { value: 'GG', name: 'GUERNSEY' },
    { value: 'GN', name: 'GUINEA' },
    { value: 'GW', name: 'GUINEA-BISSAU' },
    { value: 'GY', name: 'GUYANA' },
    { value: 'HT', name: 'HAITI' },
    { value: 'HM', name: 'HEARD ISLAND AND MCDONALD ISLANDS' },
    { value: 'VA', name: 'HOLY SEE (VATICAN CITY STATE)' },
    { value: 'HN', name: 'HONDURAS' },
    { value: 'HK', name: 'HONG KONG' },
    { value: 'HU', name: 'HUNGARY' },
    { value: 'IS', name: 'ICELAND' },
    { value: 'IN', name: 'INDIA' },
    { value: 'ID', name: 'INDONESIA' },
    { value: 'IR', name: 'IRAN, ISLAMIC REPUBLIC OF' },
    { value: 'IQ', name: 'IRAQ' },
    { value: 'IE', name: 'IRELAND' },
    { value: 'IM', name: 'ISLE OF MAN' },
    { value: 'IL', name: 'ISRAEL' },
    { value: 'IT', name: 'ITALY' },
    { value: 'JM', name: 'JAMAICA' },
    { value: 'JP', name: 'JAPAN' },
    { value: 'JE', name: 'JERSEY' },
    { value: 'JO', name: 'JORDAN' },
    { value: 'KZ', name: 'KAZAKHSTAN' },
    { value: 'KE', name: 'KENYA' },
    { value: 'KI', name: 'KIRIBATI' },
    { value: 'KP', name: 'KOREA, DEMOCRATIC PEOPLE\'S REPUBLIC OF' },
    { value: 'KR', name: 'KOREA, REPUBLIC OF' },
    { value: 'KW', name: 'KUWAIT' },
    { value: 'KG', name: 'KYRGYZSTAN' },
    { value: 'LA', name: 'LAO PEOPLE\'S DEMOCRATIC REPUBLIC' },
    { value: 'LV', name: 'LATVIA' },
    { value: 'LB', name: 'LEBANON' },
    { value: 'LS', name: 'LESOTHO' },
    { value: 'LR', name: 'LIBERIA' },
    { value: 'LY', name: 'LIBYAN ARAB JAMAHIRIYA' },
    { value: 'LI', name: 'LIECHTENSTEIN' },
    { value: 'LT', name: 'LITHUANIA' },
    { value: 'LU', name: 'LUXEMBOURG' },
    { value: 'MO', name: 'MACAO' },
    { value: 'MK', name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF' },
    { value: 'MG', name: 'MADAGASCAR' },
    { value: 'MW', name: 'MALAWI' },
    { value: 'MY', name: 'MALAYSIA' },
    { value: 'MV', name: 'MALDIVES' },
    { value: 'ML', name: 'MALI' },
    { value: 'MT', name: 'MALTA' },
    { value: 'MH', name: 'MARSHALL ISLANDS' },
    { value: 'MQ', name: 'MARTINIQUE' },
    { value: 'MR', name: 'MAURITANIA' },
    { value: 'MU', name: 'MAURITIUS' },
    { value: 'YT', name: 'MAYOTTE' },
    { value: 'MX', name: 'MEXICO' },
    { value: 'MD', name: 'MOLDOVA, REPUBLIC OF' },
    { value: 'MC', name: 'MONACO' },
    { value: 'MN', name: 'MONGOLIA' },
    { value: 'ME', name: 'MONTENEGRO' },
    { value: 'MS', name: 'MONTSERRAT' },
    { value: 'MA', name: 'MOROCCO' },
    { value: 'MM', name: 'MYANMAR' },
    { value: 'FM', name: 'MICRONESIA, FEDERATED STATES OF' },
    { value: 'MZ', name: 'MOZAMBIQUE' },
    { value: 'NA', name: 'NAMIBIA' },
    { value: 'NR', name: 'NAURU' },
    { value: 'NP', name: 'NEPAL' },
    { value: 'NL', name: 'NETHERLANDS' },
    { value: 'AN', name: 'NETHERLANDS ANTILLES' },
    { value: 'NC', name: 'NEW CALEDONIA' },
    { value: 'NZ', name: 'NEW ZEALAND' },
    { value: 'NI', name: 'NICARAGUA' },
    { value: 'NE', name: 'NIGER' },
    { value: 'NG', name: 'NIGERIA' },
    { value: 'NU', name: 'NIUE' },
    { value: 'NF', name: 'NORFOLK ISLAND' },
    { value: 'MP', name: 'NORTHERN MARIANA ISLAND'},
    { value: 'NO', name: 'NORWAY' },
    { value: 'OM', name: 'OMAN' },
    { value: 'PK', name: 'PAKISTAN' },
    { value: 'PW', name: 'PALAU' },
    { value: 'PS', name: 'PALESTINIAN TERRITORY, OCCUPIED' },
    { value: 'PA', name: 'PANAMA' },
    { value: 'PG', name: 'PAPUA NEW GUINEA' },
    { value: 'PY', name: 'PARAGUAY' },
    { value: 'PE', name: 'PERU' },
    { value: 'PH', name: 'PHILIPPINES' },
    { value: 'PN', name: 'PITCAIRN' },
    { value: 'PL', name: 'POLAND' },
    { value: 'PT', name: 'PORTUGAL' },
    { value: 'PR', name: 'PUERTO RICO' },
    { value: 'QA', name: 'QATAR' },
    { value: 'RE', name: 'RÉUNION' },
    { value: 'RU', name: 'RUSSIAN FEDERATION' },
    { value: 'RW', name: 'RWANDA' },
    { value: 'BL', name: 'SAINT BARTHÉLEMY' },
    { value: 'SH', name: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA' },
    { value: 'KN', name: 'SAINT KITTS AND NEVIS' },
    { value: 'LC', name: 'SAINT LUCIA' },
    { value: 'MF', name: 'SAINT MARTIN' },
    { value: 'PM', name: 'SAINT PIERRE AND MIQUELON' },
    { value: 'VC', name: 'SAINT VINCENT AND THE GRENADINES' },
    { value: 'WS', name: 'SAMOA' },
    { value: 'SM', name: 'SAN MARINO' },
    { value: 'ST', name: 'SAO TOME AND PRINCIPE' },
    { value: 'SA', name: 'SAUDI ARABIA' },
    { value: 'SN', name: 'SENEGAL' },
    { value: 'RS', name: 'SERBIA' },
    { value: 'SC', name: 'SEYCHELLES' },
    { value: 'SL', name: 'SIERRA LEONE' },
    { value: 'SG', name: 'SINGAPORE' },
    { value: 'SW', name: 'SINT MAARTEN (DUTCH PART)' },
    { value: 'SK', name: 'SLOVAKIA' },
    { value: 'SI', name: 'SLOVENIA' },
    { value: 'SB', name: 'SOLOMON ISLANDS' },
    { value: 'SO', name: 'SOMALIA' },
    { value: 'ZA', name: 'SOUTH AFRICA' },
    { value: 'GS', name: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS' },
    { value: 'ES', name: 'SPAIN' },
    { value: 'LK', name: 'SRI LANKA' },
    { value: 'SD', name: 'SUDAN' },
    { value: 'SR', name: 'SURINAME' },
    { value: 'SJ', name: 'SVALBARD AND JAN MAYEN' },
    { value: 'SZ', name: 'SWAZILAND' },
    { value: 'SE', name: 'SWEDEN' },
    { value: 'CH', name: 'SWITZERLAND' },
    { value: 'SY', name: 'SYRIAN ARAB REPUBLIC' },
    { value: 'TW', name: 'TAIWAN, PROVINCE OF CHINA' },
    { value: 'TJ', name: 'TAJIKISTAN' },
    { value: 'TZ', name: 'TANZANIA, UNITED REPUBLIC OF' },
    { value: 'TH', name: 'THAILAND' },
    { value: 'TL', name: 'TIMOR-LESTE' },
    { value: 'TG', name: 'TOGO' },
    { value: 'TK', name: 'TOKELAU' },
    { value: 'TO', name: 'TONGA' },
    { value: 'TT', name: 'TRINIDAD AND TOBAGO' },
    { value: 'TN', name: 'TUNISIA' },
    { value: 'TM', name: 'TURKMENISTAN' },
    { value: 'TR', name: 'TURKEY' },
    { value: 'TC', name: 'TURKS AND CAICOS ISLANDS' },
    { value: 'TV', name: 'TUVALU' },
    { value: 'UG', name: 'UGANDA' },
    { value: 'UA', name: 'UKRAINE' },
    { value: 'AE', name: 'UNITED ARAB EMIRATES' },
    { value: 'GB', name: 'UNITED KINGDOM' },
    { value: 'US', name: 'UNITED STATES' },
    { value: 'UM', name: 'UNITED STATES MINOR OUTLYING ISLANDS' },
    { value: 'UY', name: 'URUGUAY' },
    { value: 'UZ', name: 'UZBEKISTAN' },
    { value: 'VU', name: 'VANUATU' },
    { value: 'VE', name: 'VENEZUELA, BOLIVARIAN REPUBLIC OF' },
    { value: 'VN', name: 'VIET NAM' },
    { value: 'VG', name: 'VIRGIN ISLANDS, BRITISH' },
    { value: 'VI', name: 'VIRGIN ISLANDS, U.S.' },
    { value: 'WF', name: 'WALLIS AND FUTUNA' },
    { value: 'EH', name: 'WESTERN SAHARA' },
    { value: 'YE', name: 'YEMEN' },
    { value: 'ZM', name: 'ZAMBIA' },
    { value: 'ZW', name: 'ZIMBABWE' },
  ],
};

export const state = cloneDeep(initialState);

export default {
  state,
};
