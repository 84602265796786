/**
 *  This file contains functions / helpers commonly used throughout the codebase
 */

import { i18n } from '@/plugins/i18n';

const Helpers = {
  // Capitalizes the first letter of each word contained by the paramenter "str"
  capitalizeEachWord(str) {
    if (str) {
      return str
        .toLowerCase()
        .split(' ')
        .map(function(word) {
          return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ');
    }
  },

  // Converts hyphens ("-") to camel case
  convertHyphensToCamelCase(myString) {
    return myString.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });
  },

  // Checks if string ends with substring (suffix)
  strEndsWith(str, suffix) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  },

  /**
   *  Copies a string to the clipboard.
   *  Must be called from within an event handler such as click. May return false if it failed, but this is not always possible.
   *  No Safari support, as of (Nov. 2015). Returns false. IE: The clipboard feature may be disabled by an adminstrator.
   *  By default a prompt is shown the first time the clipboard is used (per session).
   */
  copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData('Text', text);
    } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
      var textarea = document.createElement('textarea');
      textarea.textContent = text;
      textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand('copy'); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn('Copy to clipboard failed.', ex);
        return false;
      } finally {
        document.body.removeChild(textarea);
      }
    }
  },

  // Provide a flexible way to obtain image URLs, handling potential errors by using a default image if the requested one is not available.
  getImgUrl(imageName, fileType) {
    try {
      return require(`@/assets/images/${imageName}.${fileType}`)
    } catch(error) {
      return require(`@/assets/images/image-placeholder.png`)
    }
  },

  // Pushes given path "path" to the router object, navigating away from the current page
  goToRoute(path, routerObj) {
    routerObj.push(`/${path}`);
  },

  //  Navigates to a targeted view by creating a route with parameters (location, tld, domain, router), and optionally includes a sourcePageNumberAuctions query based on the currentPage parameter.
  goToView(location, tld, domain, currentPage = null, router) {
    let selection = window.getSelection();
    if (selection.type !== 'Range') {
      const route = {
        path: `/${location}/${tld}/${domain}`
      };
      if (currentPage !== null) {
        route.query = {
          sourcePageNumberAuctions: `${currentPage}`
        };
      }
      router.push(route);
    }
  },

  // A simple page reload
  reloadPage() {
    location.reload();
  },

  // Scroll browser to the first occurrence of an element containing the class given as a parameter
  scrollIntoView(anchorClass) {
    setTimeout(() => {
      let windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      let elHeight;

      let el = document.getElementsByClassName(`${anchorClass}`)[0];
      if (el) {
        elHeight = el.offsetHeight;
      } else {
        elHeight = windowHeight;
      }

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          block: (windowHeight > elHeight) ? 'center' : 'start'
        });
      }
    }, 0);
  },

  // Maps entity short form to the human-readable long version
  showEntityType(entityType) {
    const entityTypeMap = {
      'p': i18n.t(`common.entityType.p`),
      'ap': i18n.t(`common.entityType.ap`),
      'nc': i18n.t(`common.entityType.nc`),
      'c': i18n.t(`common.entityType.c`),
      'gi': i18n.t(`common.entityType.gi`),
      'pi': i18n.t(`common.entityType.pi`),
      'o': i18n.t(`common.entityType.o`)
    }
    return entityTypeMap[entityType];
  }
}

export default Helpers;
