export const getLocalStorage = (key) => {
  return window.localStorage.getItem(key);
};

export const setLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value);
};

export const destroyLocalStorage = (key) => {
  window.localStorage.removeItem(key);
};

export const getSessionStorage = (key) => {
  return window.sessionStorage.getItem(key);
};

export const setSessionStorage = (key, value) => {
  window.sessionStorage.setItem(key, value);
};

export const destroySessionStorage = (key) => {
  window.sessionStorage.removeItem(key);
};

export default { getLocalStorage, setLocalStorage, destroyLocalStorage, getSessionStorage, setSessionStorage, destroySessionStorage };
