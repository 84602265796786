import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { UserDomainsService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  FETCH_CAPTURED_DOMAINS,
  FETCH_USER_DOMAINS,
  FETCH_USER_DOMAIN,
  POST_USER_DOMAIN_NAMESERVERS,
  USER_DOMAIN_RESET_STATE,
  TRANSFER_DOMAIN,
  FETCH_POPULAR_TRANSFER_BENEFITS,
  LIKE_POPULAR_TRANSFER_BENEFITS,
  REMOVE_LIKE_POPULAR_TRANSFER_BENEFITS,
} from './actions.type';
import {
  RESET_STATE,
  SET_CAPTURED_DOMAINS,
  SET_USER_DOMAINS_LOADER_START,
  SET_USER_DOMAINS_LOADER_STOP,
  SET_USER_DOMAIN,
  SET_USER_DOMAINS,
  SET_USER_DOMAIN_ERROR,
  SET_USER_DOMAINS_ERROR,
  SET_USER_DOMAIN_NAMESERVERS_ERROR,
  SET_DOMAIN_CHECKED,
  SET_DOMAIN_CHECKED_ERROR,
  SET_TRANSFERRED_DOMAIN,
  SET_TRANSFER_DOMAIN_ERROR,
  SET_POPULAR_TRANSFER_BENEFITS,
  SET_LIKE_POPULAR_TRANSFER_BENEFITS_ERROR
} from './mutations.type';

const initialState = {
  userCapturedDomains: null,
  userDomain: {
    domain: '',
    domainStatus: '',
    expireDate: null,
    id: null,
    name: '',
    nameservers: {
      ns1: '',
      ns2: '',
      ns3: '',
      ns4: '',
      ns5: '',
      ns6: ''
    },
    privateNameservers: [],
    tld: '',
  },
  userDomains: [],
  userDomainsCount: 0,
  userDomainsError: null,
  userDomainError: null,
  userDomainNameserverError: null,
  isUserDomainsLoading: true,
  domainChecked: null,
  domainCheckedError: null,
  transferDomainError: null,
  popularTransferBenefits: null,
  likePopularTransferBenefitsError: null
};

export const state = cloneDeep(initialState);

const getters = {
  userCapturedDomains(state) {
    return state.userCapturedDomains;
  },
  userDomain(state) {
    return state.userDomain;
  },
  userDomains(state) {
    return state.userDomains;
  },
  userDomainsCount(state) {
    return state.userDomainsCount;
  },
  isUserDomainsLoading(state) {
    return state.isUserDomainsLoading;
  },
  userDomainError(state) {
    return state.userDomainError;
  },
  userDomainsError(state) {
    return state.userDomainsError;
  },
  userDomainNameserverError(state) {
    return state.userDomainNameserverError;
  },
  domainChecked(state) {
    return state.domainChecked;
  },
  domainCheckedError(state) {
    return state.domainCheckedError;
  },
  transferDomainError(state) {
    return state.transferDomainError;
  },
  popularTransferBenefits(state) {
    return state.popularTransferBenefits;
  },
  likePopularTransferBenefitsError(state) {
    return state.likePopularTransferBenefitsError;
  }
};

const actions = {
  [FETCH_USER_DOMAINS]({ commit }, params) {
    if (JwtService.getToken()) {
      commit(SET_USER_DOMAINS_LOADER_START);
      ApiService.setHeader();

      return UserDomainsService.getUserDomains(params.filters)
        .then(({ data }) => {
          commit(SET_USER_DOMAINS, data);
          return data;
        })
        .catch(error => {
          console.log('Error: ', error);
          commit(SET_USER_DOMAINS_ERROR, error);
          return error;
        })
        .finally(() => {
          commit(SET_USER_DOMAINS_LOADER_STOP);
        });
    }
  },
  [FETCH_USER_DOMAIN]({ commit }, userDomainSlug) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDomainsService.getUserDomain(`${userDomainSlug.tld}/${userDomainSlug.domain}`)
        .then(({ data }) => {
          commit(SET_USER_DOMAIN, data);
        })
        .catch(error => {
          console.log('Error: ', error);
          commit(SET_USER_DOMAIN_ERROR, error);
          throw new Error(error);
        });
    }
  },
  [POST_USER_DOMAIN_NAMESERVERS]({ commit }, payload) {
    if (JwtService.getToken()) {
      commit(SET_USER_DOMAIN_NAMESERVERS_ERROR, {})
      ApiService.setHeader();

      return UserDomainsService.postNameservers(payload.domainId, payload.nameservers)
        .then(() => {
          commit(SET_USER_DOMAIN_NAMESERVERS_ERROR, null);
        })
        .catch(({ response }) => {
          console.log('Error: ', response.data);
          commit(SET_USER_DOMAIN_NAMESERVERS_ERROR, response.data);
          throw new Error(response.data);
        });
    }
  },
  [TRANSFER_DOMAIN]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return new Promise((resolve, reject) => {
        UserDomainsService.transferDomain(payload.domain, payload.key)
          .then(({ data }) => {
            commit(SET_TRANSFERRED_DOMAIN, data);
            resolve();
          })
          .catch(({ response }) => {
            console.log('Error: ', response.data);
            commit(SET_TRANSFER_DOMAIN_ERROR, response.data);
            reject();
          });
      });
    }
  },
  [USER_DOMAIN_RESET_STATE]({ commit }) {
    commit(RESET_STATE);
  },
  [FETCH_POPULAR_TRANSFER_BENEFITS]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDomainsService.fetchPopularTransferBenefits()
        .then(({ data }) => {
          commit(SET_POPULAR_TRANSFER_BENEFITS, data);
        })
        .catch(error => {
          console.log('Error: ', error);
          // @todo - to be removed after BE implements
          // const data = [
          //   {
          //     'key': 'price',
          //     'liked': false,
          //     'likes': 197
          //   },
          //   {
          //     'key': 'support',
          //     'liked': true,
          //     'likes': 178
          //   },
          //   {
          //     'key': 'domainManagement',
          //     'liked': false,
          //     'likes': 144
          //   },
          //   {
          //     'key': 'noRisk',
          //     'liked': false,
          //     'likes': 170
          //   },
          //   {
          //     'key': 'freeTransfer',
          //     'liked': true,
          //     'likes': 184
          //   },
          //   {
          //     'key': 'instant',
          //     'liked': false,
          //     'likes': 156
          //   },
          //   {
          //     'key': 'noCommissions',
          //     'liked': false,
          //     'likes': 167
          //   },
          //   {
          //     'key': 'noCommitment',
          //     'liked': false,
          //     'likes': 176
          //   },
          //   {
          //     'key': 'serviceAvailability',
          //     'liked': false,
          //     'likes': 181
          //   },
          // ];
          throw new Error(error);
        });
    }
  },
  [FETCH_CAPTURED_DOMAINS]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDomainsService.fetchCapturedDomains()
        .then(({ data }) => {
          commit(SET_CAPTURED_DOMAINS, data);
        })
        .catch(error => {
          console.log('Error: ', error);
          commit(SET_USER_DOMAINS_ERROR, error);
          throw new Error(error);
        });
    }
  },
  [LIKE_POPULAR_TRANSFER_BENEFITS]({ commit }, key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDomainsService.likePopularTransferBenefits(key)
        .catch(error => {
          commit(SET_LIKE_POPULAR_TRANSFER_BENEFITS_ERROR, error);
          console.log('Error: ', error);
          throw new Error(error);
        });
    }
  },
  [REMOVE_LIKE_POPULAR_TRANSFER_BENEFITS]({ commit }, key) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDomainsService.removeLikePopularTransferBenefits(key)
        .catch(error => {
          commit(SET_LIKE_POPULAR_TRANSFER_BENEFITS_ERROR, error);
          console.log('Error: ', error);
          throw new Error(error);
        });
    }
  }
};

const mutations = {
  [SET_CAPTURED_DOMAINS](state, data) {
    state.userCapturedDomains = data;
  },
  [SET_USER_DOMAINS_LOADER_START](state) {
    state.isUserDomainsLoading = true;
  },
  [SET_USER_DOMAINS_LOADER_STOP](state) {
    state.isUserDomainsLoading = false;
  },
  [SET_USER_DOMAIN](state, userDomain) {
    state.userDomain = userDomain;
  },
  [SET_USER_DOMAINS](state, { userDomains, total }) {
    state.userDomains = userDomains;
    state.userDomainsCount = total;
    state.isUserDomainsLoading = false;
  },
  [SET_USER_DOMAIN_ERROR](state, error) {
    state.userDomainError = error;
  },
  [SET_USER_DOMAINS_ERROR](state, error) {
    state.userDomainsError = error;
  },
  [SET_USER_DOMAIN_NAMESERVERS_ERROR](state, error) {
    state.userDomainNameserverError = error;
  },
  [SET_DOMAIN_CHECKED](state, data) {
    state.domainChecked = data;
  },
  [SET_DOMAIN_CHECKED_ERROR](state, data) {
    state.domainCheckedError = data;
  },
  [SET_TRANSFERRED_DOMAIN](state, data) {
    state.transferredDomain = data;
  },
  [SET_TRANSFER_DOMAIN_ERROR](state, error) {
    state.transferDomainError = error;
  },
  [SET_POPULAR_TRANSFER_BENEFITS](state, data) {
    state.popularTransferBenefits = data;
  },
  [SET_LIKE_POPULAR_TRANSFER_BENEFITS_ERROR](state, error) {
    state.likePopularTransferBenefitsError = error;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};
