<template>
  <div class="MyAccountDropdown u-display-ib"
       @mouseenter="clearCloseMyAccountDropdownTimeout()"
       @mouseover="isMobileOrTablet ? '' : openMyAccountDropdown()"
       @mouseleave="isMobileOrTablet ? '' : startCloseMyAccountDropdownTimeout()"
       v-click-outside="closeMyAccountDropdown">
    <router-link class="o-default-body-xs nav-button my-account"
                 :to="links.myAccount.page"
                 :event="!isMobileOrTablet ? 'click' : ''"
                 @click.native.prevent="isMobileOrTablet ? handleMouseEvent() : ''">
      <i class="material-icons">person_outline</i>
      <span class="nav-button-label">{{ $t('shortcutMenu.myAccount') }}</span>
      <i class="material-icons">arrow_drop_down</i>
    </router-link>
    <div class="o-dropdown my-account-dropdown-content" :class="{ active: myAccountDropdownActive }">
      <router-link class="my-account-dropdown-btn-redirect"
                   :to="links.myAccount.page">
        <span class="o-caret--up"></span>
        <p class="o-default-body-small dropdown-email-container">
          <span class="dropdown-email">{{ currentUserEmail }}</span>
        </p>
      </router-link>
      <md-divider></md-divider>
      <ul class="my-account-menu">
        <li class="o-default-body-xs nav-button my-account-menu__li" @click="signOut">
          <i class="material-icons">highlight_off</i>
          <span class="dropdown-button-label">{{ $t('common.auth.signOut') }}</span>
        </li>
      </ul>
    </div> <!-- /.my-account-dropdown-content -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
  SIGNOUT,
  MY_ACCOUNT_DROPDOWN_OPEN,
  MY_ACCOUNT_DROPDOWN_CLOSE,
  MINICART_CLOSE
} from '@/store/actions.type';

export default {
  name: 'MyAccountDropdown',
  data() {
    return {
      links: {
        myAccount: {
          page: '/my-account',
          text: this.$t('shortcutMenu.myAccount')
        }
      },
    };
  },
  computed: {
    ...mapState({
      currentUserEmail: state => state.auth.currentUserEmail
    }),
    ...mapGetters([
      'isMobileOrTablet',
      'myAccountDropdownActive',
      'currentUserEmail',
      'minicartActive',
    ]),
    isMinicartOpen() {
      return this.minicartActive;
    },
  },
  watch: {
    '$route'() {
      this.$store.dispatch(MY_ACCOUNT_DROPDOWN_CLOSE);
    }
  },
  methods: {
    startCloseMyAccountDropdownTimeout() {
      // Clear any existing timeout
      if (this.closeMyAccountDropdownTimeout) {
        clearTimeout(this.closeMyAccountDropdownTimeout);
      }
      // Set a new timeout for closing the dropdown
      this.closeMyAccountDropdownTimeout = setTimeout(() => {
        this.closeMyAccountDropdown();
      }, 500);
    },
    clearCloseMyAccountDropdownTimeout() {
      // Clear the close timeout when re-entering the dropdown
      if (this.closeMyAccountDropdownTimeout) {
        clearTimeout(this.closeMyAccountDropdownTimeout);
      }
    },
    openMyAccountDropdown() {
      if (this.isMinicartOpen) {
        this.$store.dispatch(MINICART_CLOSE);
      }
      this.$store.dispatch(MY_ACCOUNT_DROPDOWN_OPEN);
    },
    closeMyAccountDropdown() {
      this.$store.dispatch(MY_ACCOUNT_DROPDOWN_CLOSE);
    },
    toggleMyAccountDropdown() {
      this.myAccountDropdownActive ? this.closeMyAccountDropdown() : this.openMyAccountDropdown();
    },
    // Method that use setTimeout to schedule the DOM modifications caused by mouseenter/mouseleave as a separate task
    // https://github.com/vuejs/vue/issues/9859
    handleMouseEvent() {
      setTimeout(() => {
        this.toggleMyAccountDropdown();
      }, 0);
    },
    signOut() {
      this.$store.dispatch(SIGNOUT);
    }
  }
};
</script>

<style lang="scss" scoped>
.MyAccountDropdown {

  .my-account-dropdown-btn-redirect {
    text-decoration: none;
  }

  .my-account-dropdown-content {
    left: -17.7rem;
    opacity: 0;
    transition: max-height .3s ease-in-out, opacity .3s ease-in-out;
    @include round-corners(0.5rem);

    @include desktop {
      left: -12rem;
    }

    &.active {
      max-height: none;
      opacity: 1;
    }

    .o-caret--up:after {
      left: 20.9rem;

      @include desktop {
        left: 23.9rem;
      }
    }
  }

  .nav-button {
    color: $black !important;
    display: inline-block;
    font-weight: 700;
    line-height: 3rem;
    padding: .2rem .4rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .nav-button:last-child {
    padding-right: 0
  }

  .nav-button-label {
    display: none;
    font-family: $font-family-heading-light;
    font-size: 1.6rem;
    letter-spacing: 0;
    max-width: 18rem;
    overflow: hidden;
    padding: .2rem .4rem;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;

    @include desktop {
      display: inline-block;
    }
  }

  .my-account {
    padding-left: .8rem;
    padding-right: .2rem;
  }

  .my-account-menu {
    padding: .8rem 0 .2rem;
  }

  .my-account-menu__li {
    width: 100%;
  }

  .dropdown-email-container {
    font-weight: 700;
    line-height: 3rem;
    min-height: 100%;
    padding: .2rem .4rem;
    width: 100%;
  }

  .dropdown-email {
    display: block;
    font-family: $font-family-body-regular;
    padding: .2rem;
    @include truncate();
  }

  .dropdown-button-label {
    display: inline-block;
    overflow: hidden;
    padding: .2rem .4rem;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
  }
}
</style>
