import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import store from '@/store';
import JwtService from '@/common/jwt.service';
import LocalStorageService from '@/common/localstorage.service';
import { API_URL } from '@/common/config';
import { SIGNOUT } from '@/store/actions.type';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;

    Vue.axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (error.response.status === 401 && location.pathname !== '/sign-in') {
        store.dispatch(SIGNOUT);
      }
      return Promise.reject(error)
    })
  },

  setHeader() {
    let languageCode = (LocalStorageService.getLocalStorage('language_code') !== null) ? LocalStorageService.getLocalStorage('language_code') : LocalStorageService.setLocalStorage('language_code', 'gb');

    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`;
    Vue.axios.defaults.headers.common['Content-Language'] = languageCode;
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
  },

  get(resource, slug = '') {
    return Vue.axios.get(`${resource}/${slug}`);
  },

  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`[Inreg] ApiService ${error}`);
    });
  }
};

export default ApiService;

export const AccountService = {
  getPhone() {
    return ApiService.get('api/my-account/phone/view');
  },
  addPhone(phoneNumber) {
    return ApiService.post('api/my-account/phone/add', {
      phoneNumber: phoneNumber
    })
  },
  retryCode() {
    return ApiService.post('api/my-account/phone/verify/retry-code');
  },
  changePhone() {
    return ApiService.post('api/my-account/phone/verify/change-phone');
  },
  verifyPhone(authCode) {
    return ApiService.post('api/my-account/phone/verify', {
      code: authCode
    });
  },
  postNewsletterRegisteredUsers(params) {
    return ApiService.post('api/my-account/subscribe/edit', params);
  },
  postNewsletterUnregisteredUsers(params) {
    return ApiService.post('public/newsletter', params);
  },
  getSubscribeDetails() {
    return ApiService.get('api/my-account/subscribe/get');
  },
  postSendMessage(params) {
    console.log(params)
    return ApiService.post('/contact-us', params);
  },
  getBillingCurrency() {
    return ApiService.get('/api/my-account/billing-currency/get');
  },
  postBillingCurrency(params) {
    return ApiService.post('api/my-account/billing-currency/update', params);
  },
};

export const UserDetailsService = {
  getUserDetailsAll() {
    return ApiService.get('api/register-address/all');
  },
  getUserDetailsById(addressId) {
    return ApiService.get(`api/register-address/${addressId}`);
  },
  postUserDetailsAdd(params) {
    return ApiService.post('api/register-address/add', params);
  },
  postUserDetailsRemove(addressId) {
    return ApiService.post(`api/register-address/delete?addressId=${addressId}`);
  },
  postUserDetailsEdit(params) {
    return ApiService.post('api/register-address/edit', params);
  },
  postUserDetailsSetDefault(addressId) {
    return ApiService.post(`api/dropcatch-address/change/${addressId}`);
  }
};

export const BillingDetailsService = {
  getBillingDetailsAll() {
    return ApiService.get('api/billing-address/all');
  },
  getBillingDetailsById(addressId) {
    return ApiService.get(`api/billing-address/${addressId}`);
  },
  postBillingDetailsAdd(params) {
    return ApiService.post('api/billing-address/add', params);
  },
  postBillingDetailsRemove(addressId) {
    return ApiService.post(`api/billing-address/delete?addressId=${addressId}`);
  },
  postBillingDetailsEdit(params) {
    return ApiService.post('api/billing-address/edit', params);
  },
  postBillingDetailsSetDefault(addressId) {
    return ApiService.post(`api/billing-address/set-default/${addressId}`);
  }
};

export const AuctionsService = {
  // "type" parameter is intentionally included here as an example, demonstrating that the request type can be specified when calling AuctionService.query(<type>, <params>)
  query(type, params) {
    return ApiService.query('public/openauctions', { params: params });
  },
  get(slug) {
    return ApiService.get(`auction/${slug}`);
  },
  placeBid(auctionId, bidAmount) {
    return ApiService.post('auctions/placebid', {
      auctionId: auctionId,
      amount: bidAmount
    });
  },
  deleteBid(params) {
    return ApiService.post('deletebid', { auctionId: params.auctionId });
  },
};

export const CartService = {
  getCart() {
    return ApiService.get('api/cart/get');
  },
  addToCart(params) {
    return ApiService.post('api/cart/add', params);
  },
  deleteEntry(slug) {
    return ApiService.post(`api/cart/delete${slug}`);
  },
  checkAvailability(params) {
    return ApiService.post('api/check-domain', { domainName: params });
  },
  updateProductQuantity(params) {
    return ApiService.post('api/cart/update', params);
  }
};

export const CheckoutService = {
  postCheckoutOrder(params) {
    return ApiService.post('api/cart/checkout', params);
  },
  postValidatePayU(params) {
    return ApiService.post('/api/validate-order-confirmation', params);
  }
}

export const UserAuctionsService = {
  getUserAuctions() {
    return ApiService.get('api/auctions/user-auctions');
  }
};

export const UserDomainsService = {
  getUserDomains(params) {
    return ApiService.query('api/userdomains', { params: params });
  },
  getUserDomain(slug) {
    return ApiService.get(`api/userdomain/${slug}`);
  },
  postNameservers(domainId, nameservers) {
    return ApiService.post('api/change-ns', {
      id: domainId,
      ns1: nameservers.ns1,
      ns2: nameservers.ns2,
      ns3: nameservers.ns3,
      ns4: nameservers.ns4,
      ns5: nameservers.ns5,
      ns6: nameservers.ns6
    })
  },
  transferDomain(domain, key) {
    return ApiService.post('/api/domain/transfer', {
      domain: domain,
      key: key
    })
  },
  fetchCapturedDomains() {
    return ApiService.get('/api/captured-domains');
  },
  fetchPopularTransferBenefits() {
    return ApiService.get('/api/domain/transfer/benefits');
  },
  likePopularTransferBenefits(key) {
    return ApiService.post('/api/domain/transfer/benefits/like', { key: key });
  },
  removeLikePopularTransferBenefits(key) {
    return ApiService.post('/api/domain/transfer/benefits/like/remove', { key: key });
  }
};
