
/* ==========================================================================
   # Common
   ========================================================================== */
import Vue from 'vue';
import store from './store';
import { i18n } from '@/plugins/i18n';
import './registerServiceWorker';
import 'es6-promise/auto';

import './styles/common.scss';

// Vue configs
Vue.config.productionTip = false;
// Vue.config.devtools = true;

/* ==========================================================================
   # Import: VueMaterial
   ========================================================================== */
import VueMaterial from 'vue-material';

import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default.css';

Vue.use(VueMaterial);

/* ==========================================================================
   # Import: VueCookie
   ========================================================================== */
import VueCookie from 'vue-cookie';

Vue.use(VueCookie);

/* ==========================================================================
   # Import: VueMoment
   ========================================================================== */
import VueMoment from 'vue-moment';

Vue.use(VueMoment);

/* ==========================================================================
   # Import: vac (vue-awesome-countdown)
   ========================================================================== */
import vac from '@/plugins/vac/vue-awesome-countdown';

Vue.use(vac);

/* ==========================================================================
   # Import: VueTelInput
   ========================================================================== */
import VueTelInput from 'vue-tel-input';

import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);

/* ==========================================================================
   # Import: FlagIcon
   ========================================================================== */
import FlagIcon from 'vue-flag-icon';

Vue.use(FlagIcon);

/* ==========================================================================
   # Import: ApiService
   ========================================================================== */
import ApiService from './common/api.service';

ApiService.init();

/* ==========================================================================
   # Import: Helpers
   ========================================================================== */
import Helpers from './common/helpers';

/**
 * Make Helpers available in templates
 *
 *   <span @click="Helpers.copyToClipboard(contactPhone)">
 *     {{ contactPhone }}
 *   </span>
 */
Vue.prototype.Helpers = Helpers;

/* ==========================================================================
   # Import: router, JwtService, { CHECK_AUTH }
   ========================================================================== */
import router from './router';
// import JwtService from './common/jwt.service';
import { CHECK_AUTH } from './store/actions.type';

// Check auth before each route change.
router.beforeEach((to, from, next) => {
  Promise.all([store.dispatch(CHECK_AUTH, to)])
    .then(() => {
      next();
    });
});

/* ==========================================================================
   # Directives
   ========================================================================== */
import autofocus from 'vue-autofocus-directive';

// autofocus: when the page loads, the input will be automatically focused.
Vue.directive('autofocus', autofocus);

// click-outside: when a click happens outside an HTML object with this directive, the function sent as a parameter will be invoked
Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // Check if click was outside the el and its children
      if (!(el == event.target || el.contains(event.target))) {
        // If it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

/* ==========================================================================
   # Filters
   ========================================================================== */
// import DateFilter from './common/date.filter';
// import ErrorFilter from './common/error.filter';
// Vue.filter('date', DateFilter);
// Vue.filter('error', ErrorFilter);

/* ==========================================================================
   # App
   ========================================================================== */
import App from './App.vue';

new Vue({
  router,
  i18n,
  vac,
  store,
  render: h => h(App)
}).$mount('#app')
