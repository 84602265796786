<template>
  <div class="MinicartEmptyState">
    <i class="material-icons empty-state-icon">shopping_cart</i>
    <strong class="empty-state-label o-default-h3">{{ $t('minicartEmptyState.label') }}</strong>
    <p class="empty-state-description o-default-body">{{ $t('minicartEmptyState.description') }}</p>
    <div class="cta-container">
      <md-button class="md-primary md-raised md-dense"
                 @click="goToDomainSearch">
        {{ $t('minicartEmptyState.domainSearch') }}
      </md-button>
      <md-button class="md-primary md-raised md-dense"
                 @click="goToDomainAuctions">
        {{ $t('minicartEmptyState.domainAuctions') }}
      </md-button>
    </div>
    <p class="empty-state-view-cart o-default-body-small">
      {{ $t('common.or') }} <router-link :to="links.cart.page">{{ links.cart.text }}</router-link>
    </p>
  </div>
</template>

<script>
import {
  MINICART_CLOSE
} from '@/store/actions.type';

export default {
  name: 'MinicartEmptyState',
  data() {
    return {
      links: {
        cart: {
          text: 'View empty cart',
          page: '/cart'
        },
      }
    }
  },
  methods: {
    goToDomainSearch: function() {
      this.$router.push({
        name: 'domain-names'
      });
      this.$store.dispatch(MINICART_CLOSE);
    },
    goToDomainAuctions: function() {
      this.$router.push({
        name: 'auctions'
      });
      this.$store.dispatch(MINICART_CLOSE);
    }
  }
};
</script>

<style lang="scss" scoped>
.MinicartEmptyState {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity .4s cubic-bezier(.4, 0, .2, 1);
  will-change: opacity;

  .empty-state-icon {
    color: $global-color-transparent-three;
    font-size: 6.4rem;
  }

  .empty-state-label {
    font-family: $font-family-heading-regular;
    font-size: 2rem;
    line-height: $type-scale-line-height-6-fallback;
    line-height: $type-scale-line-height-6;
    margin: 1.6rem 0 3.2rem;
  }

  .empty-state-description {
    font-size: $type-scale-size-8-fallback;
    font-size: $type-scale-size-8;
    line-height: 2rem;
    margin: 0 0 1.2rem;
  }

  .cta-container {
    display: flex;

    .md-button {
      transition: none;
    }
  }

  .empty-state-view-cart {
    margin: .4rem auto 1.2rem;
  }
}
</style>
