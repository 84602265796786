import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { AuctionsService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  FETCH_AUCTION,
  FETCH_AUCTIONS,
  PLACE_BID,
  DELETE_BID,
  AUCTION_VIEW_RESET_ERROR,
  AUCTION_RESET_ERROR,
  AUCTION_RESET_STATE
} from './actions.type';
import {
  RESET_STATE,
  SET_LOADER_START,
  SET_DOMAIN_AUCTIONS,
  SET_AUCTION,
  SET_OFFER_VALUE,
  SET_WINNER_FLAG,
  SET_AUCTION_VIEW_ERROR,
  SET_AUCTION_ERROR,
  RESET_AUCTION_VIEW_ERROR,
  RESET_AUCTION_ERROR
} from './mutations.type';

const initialState = {
  auctionViewErrors: null,
  auctionErrors: null,
  auction: {
    active: false,
    closeDate: '',
    closingSoon: false,
    domain: '',
    id: null,
    offer: null,
    secondsLeft: '',
    tld: '',
    winner: false
  },
  auctions: [],
  auctionsCount: 0,
  isDomainListLoading: true,
};

export const state = cloneDeep(initialState);

const getters = {
  auction(state) {
    return state.auction;
  },
  auctions(state) {
    return state.auctions;
  },
  auctionsCount(state) {
    return state.auctionsCount;
  },
  isDomainListLoading(state) {
    return state.isDomainListLoading;
  },
  auctionErrors(state) {
    return state.auctionErrors;
  },
  auctionViewErrors(state) {
    return state.auctionViewErrors;
  },
};

const actions = {
  [FETCH_AUCTIONS]({ commit }, params) {
    commit(SET_LOADER_START, true);

    return AuctionsService.query(params.type, params.filters)
      .then(({ data }) => {
        commit(SET_DOMAIN_AUCTIONS, data);
      })
      .catch(error => {
        console.log('Error: ', error)
        throw new Error(error);
      });
  },
  [FETCH_AUCTION]({ commit }, auctionSlug) {
    if (JwtService.getToken()) {
      commit(SET_LOADER_START, true);
      ApiService.setHeader();
      return AuctionsService.get(`${auctionSlug.tld}/${auctionSlug.domain}`)
      .then(({ data }) => {
        commit(SET_AUCTION, data);
        commit(SET_LOADER_START, false);
      })
      .catch((response) => {
        commit(SET_AUCTION_VIEW_ERROR);
        commit(SET_LOADER_START, false);
        console.log('Error: ', response);
      });
    }
  },
  [PLACE_BID]({ commit }, payload) {
    if (JwtService.getToken()) {
      commit(SET_LOADER_START, true);
      ApiService.setHeader();

      return AuctionsService.placeBid(payload.auctionId, payload.bidAmount)
        .then(({ data }) => {
          commit(SET_OFFER_VALUE, data.offer);
          commit(SET_WINNER_FLAG, data.winner);
          commit(SET_LOADER_START, false);

        })
        .catch(({ response }) => {
          console.log('Error: ', response.data.message, typeof(response.data.message));
          // throw new Error(response);
          commit(SET_AUCTION_ERROR, response.data.message);
          commit(SET_LOADER_START, false);
        });
    }
  },
  [DELETE_BID]({ commit }, payload) {
    commit(SET_LOADER_START, true);

    return AuctionsService.deleteBid(payload.auctionId)
      .then(({ data }) => {
        console.log(data)
        // commit(SET_OFFER_VALUE, data.offer);
        // commit(SET_WINNER_FLAG, data.winner);
      })
      .catch(error => {
        throw new Error(error);
      });
  },
  [AUCTION_VIEW_RESET_ERROR]({ commit }) {
    commit(RESET_AUCTION_VIEW_ERROR);
  },
  [AUCTION_RESET_ERROR]({ commit }) {
    commit(RESET_AUCTION_ERROR);
  },
  [AUCTION_RESET_STATE]({ commit }) {
    commit(RESET_STATE);
  }
};

const mutations = {
  [SET_LOADER_START](state, flag) {
    state.isDomainListLoading = flag;
  },
  [SET_DOMAIN_AUCTIONS](state, { auctions, total }) {
    state.auctions = auctions;
    state.auctionsCount = total;
    state.isDomainListLoading = false;
  },
  [SET_AUCTION](state, auction) {
    state.auction = auction;
  },
  [SET_AUCTION_VIEW_ERROR](state) {
    state.auctionViewErrors = true;
  },
  [SET_AUCTION_ERROR](state, error) {
    state.auctionErrors = error;
  },
  [SET_OFFER_VALUE](state, offer) {
    state.auction.offer = offer;
  },
  [SET_WINNER_FLAG](state, winner) {
    state.auction.winner = winner;
  },
  [RESET_AUCTION_VIEW_ERROR]() {
    state.auctionViewErrors = null;
  },
  [RESET_AUCTION_ERROR]() {
    state.auctionErrors = null;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
    state.isDomainListLoading = false;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
