<template>
  <div class="ShortcutMenu">
    <span>
      <router-link class="o-default-body-xs nav-button"
                   :to="links.contact.page">
        <i class="material-icons">chat_bubble_outline</i>
        <span class="nav-button-label">{{ links.contact.text }}</span>
      </router-link>

      <router-link class="o-default-body-xs nav-button"
                   :to="links.help.page">
        <i class="material-icons">help_outline</i>
        <span class="nav-button-label">{{ links.help.text }}</span>
      </router-link>

      <!-- @todo - pagina blog - <router-link :to="links.blog.page">{{ links.blog.text }}</router-link> -->

      <span v-if="!isAuthenticated" class="o-container-left-border">
        <router-link class="o-default-body-xs nav-button"
                     :to="links.signUp.page">
          <i class="material-icons">person_add</i>
          <span class="nav-button-label">{{ links.signUp.text }}</span>
        </router-link>
        <router-link class="o-default-body-xs nav-button"
                     :to="links.signIn.page">
          <i class="material-icons">person_outline</i>
          <span class="nav-button-label">{{ links.signIn.text }}</span>
        </router-link>
      </span>
      <span v-else class="o-container-left-border shortcut-menu-section--right">
        <MyAccountDropdown />
        <Minicart />
      </span>
    </span>
    <md-snackbar :md-position="snackbar.position"
                 :md-duration="snackbar.duration"
                 :md-active.sync="snackbar.showCopiedToClipboardSnackbar"
                 md-persistent>
      <span>{{ $t('snackbar.copied') }} {{ snackbar.copiedContent }} {{ $t('snackbar.toClipboard') }}</span>
      <md-button class="md-primary" @click="snackbar.showCopiedToClipboardSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </div> <!-- /.ShortcutMenu -->
</template>

<script>
import { mapGetters } from 'vuex';

import MyAccountDropdown from '@/components/header/MyAccountDropdown';
import Minicart from '@/components/minicart/Minicart';

export default {
  name: 'ShortcutMenu',
  components: {
    MyAccountDropdown,
    Minicart
  },
  data() {
    return {
      links: {
        contact: {
          text: this.$t('shortcutMenu.contactUs'),
          page: '/contact'
        },
        help: {
          page: '/help',
          text: this.$t('common.help')
        },
        blog: {
          page: '/blog',
          text: this.$t('shortcutMenu.blog')
        },
        signIn: {
          page: '/sign-in',
          text: this.$t('common.auth.signIn')
        },
        signUp: {
          page: '/sign-up',
          text: this.$t('common.auth.signUp')
        },
        // status: {
        //   page: '/status',
        //   text: this.$t('shortcutMenu.serviceStatus')
        // }
      },
      snackbar: {
        showCopiedToClipboardSnackbar: false,
        copiedContent: '',
        duration: 4000,
        position: 'center',
        text: {
          email: this.$t('snackbar.emailAddress'),
        }
      }
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated'
    ])
  },
  methods: {
    showCopyConfirmation(content) {
      this.snackbar.copiedContent = content;
      this.snackbar.showCopiedToClipboardSnackbar = true;
    }
  }
};
</script>

<style lang="scss">
.ShortcutMenu {
  @include disable-select;
  cursor: pointer;

  .o-container-left-border {
    border-left: .1rem solid $palette-secondary-color-four;
    padding-left: .8rem;
  }

  .md-badge-content .md-position-top {
    top: -.2rem;
  }

  .nav-button {
    color: $black !important;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 3rem;
    padding: .2rem .4rem;
    padding-right: .8rem;

    &:hover {
      text-decoration: underline;
    }
  }

  .nav-button:last-child  {
    padding-right: 0
  }

  .nav-button-label {
    display: none;
    font-family: $font-family-heading-light;
    font-size: 1.6rem;
    letter-spacing: 0;
    max-width: 18rem;
    overflow: hidden;
    padding: .2rem .4rem;
    text-overflow: ellipsis;
    vertical-align: top;
    white-space: nowrap;
    @include desktop {
      display: inline-block;
    }
  }

  .shortcut-menu-section--right {
    border-left: .1rem $palette-secondary-color-four solid;
    margin-left: .4rem;
    padding: .4rem 0 .4rem .4rem;
    position: relative;

    @include desktop {
      padding-left: .8rem;
    }
  }
}
</style>
