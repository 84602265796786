
import { cloneDeep } from 'lodash';
import {
  MY_ACCOUNT_DROPDOWN_OPEN,
  MY_ACCOUNT_DROPDOWN_CLOSE
} from './actions.type';
import {
  SET_MY_ACCOUNT_DROPDOWN_OPEN,
  SET_MY_ACCOUNT_DROPDOWN_CLOSE,
} from './mutations.type';

const initialState = {
  myAccountDropdownActive: false
};

export const state = cloneDeep(initialState);

const getters = {
  myAccountDropdownActive(state) {
    return state.myAccountDropdownActive;
  }
};

const actions = {
  [MY_ACCOUNT_DROPDOWN_OPEN]({ commit }) {
    commit(SET_MY_ACCOUNT_DROPDOWN_OPEN);
  },
  [MY_ACCOUNT_DROPDOWN_CLOSE]({ commit }) {
    commit(SET_MY_ACCOUNT_DROPDOWN_CLOSE);
  }
}

const mutations = {
  [SET_MY_ACCOUNT_DROPDOWN_OPEN](state) {
    state.myAccountDropdownActive = true;
  },
  [SET_MY_ACCOUNT_DROPDOWN_CLOSE](state) {
    state.myAccountDropdownActive = false;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
