/**
 *  This file contains the app's Router object.
 *
 *  Important:
 *  Make sure that the you update the 'breadcrumbs' object in src\plugins\i18n.js
 *  and "TheBreadcrumbs" component in src\components\TheBreadcrumbs.vue
 *  when you add / remove or modify the paths in this file.
 */

import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      // route level code-splitting
      // this generates a separate chunk (about-us.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import( /* webpackChunkName: "home" */ '@/views/HomeView')
    },
    {
      path: '/about-us',
      name: 'about-us',
      component: () => import( /* webpackChunkName: "about-us" */ '@/views/AboutUsView')
    },
    {
      path: '/activate',
      name: 'activate-user',
      component: () => import( /* webpackChunkName: "activate-user" */ '@/views/ActivateUserView')
    },
    {
      path: '/auctions',
      component: () => import( /* webpackChunkName: "auctions-parent" */ '@/views/AuctionsParentView'),
      children: [
        {
          path: '',
          name: 'auctions',
          component: () => import( /* webpackChunkName: "auctions" */ '@/views/AuctionsView')
        },
        {
          path: ':tld',
          component: () => import( /* webpackChunkName: "tld-parent" */ '@/views/TldParentView'),
          children: [
            {
              path: '',
              name: 'tld-auctions',
              component: () => import( /* webpackChunkName: "tld" */ '@/views/AuctionsView')
            },
            {
              path: ':domain',
              name: 'auction',
              component: () => import( /* webpackChunkName: "auction" */ '@/views/AuctionView'),
              meta: { requiresAuth: true }
            }
          ]
        },
      ]
    },
    {
      path: '/user-domains',
      component: () => import( /* webpackChunkName: "user-domains-parent" */ '@/views/UserDomainsParentView'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'user-domains',
          component: () => import( /* webpackChunkName: "user-domains" */ '@/views/UserDomainsView'),
          meta: { requiresAuth: true }
        },
        {
          path: ':tld',
          component: () => import( /* webpackChunkName: "tld-parent" */ '@/views/TldParentView'),
          children: [
            {
              path: '',
              name: 'tld-user-domains',
              component: () => import( /* webpackChunkName: "user-domains" */ '@/views/UserDomainsView'),
              meta: { requiresAuth: true }
            },
            {
              path: ':domain',
              name: 'user-domain',
              component: () => import( /* webpackChunkName: "user-domain" */ '@/views/UserDomainView'),
              meta: { requiresAuth: true }
            }
          ]
        },
      ]
    },
    {
      path: '/buy-website',
      name: 'buy-website',
      component: () => import( /* webpackChunkName: "buy-website" */ '@/views/BuyWebsiteView')
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import( /* webpackChunkName: "contact" */ '@/views/ContactView')
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: () => import( /* webpackChunkName: "cookies" */ '@/views/CookiesView')
    },
    {
      path: '/domain-names',
      name: 'domain-names',
      component: () => import( /* webpackChunkName: "domain-names" */ '@/views/DomainNamesView'),
    },
    {
      path: '/domain-transfer',
      name: 'domain-transfer',
      component: () => import( /* webpackChunkName: "domain-transfer" */ '@/views/DomainTransferView'),
      meta: { requiresAuth: true }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: () => import( /* webpackChunkName: "cookies" */ '@/views/ForgotPasswordView')
    },
    /* Help pages
       ========================================================================== */
    {
      path: '/help',
      component: () => import( /* webpackChunkName: "help-parent" */ '@/views/help/HelpParentView'),
      children: [
        {
          path: '',
          name: 'help',
          component: () => import( /* webpackChunkName: "help" */ '@/views/help/HelpView'),
        },
        {
          path: 'faq',
          name: 'help-faq',
          component: () => import( /* webpackChunkName: "help-faq" */ '@/views/help/HelpFaqView'),
        },
        {
          path: 'payments',
          name: 'help-payments',
          component: () => import( /* webpackChunkName: "help-dns" */ '@/views/help/HelpPaymentsView'),
        },
        {
          path: 'auctions',
          name: 'help-auctions',
          component: () => import( /* webpackChunkName: "help-auctions" */ '@/views/help/HelpAuctionsView'),
        },
        {
          path: 'dns',
          name: 'help-dns',
          component: () => import( /* webpackChunkName: "help-dns" */ '@/views/help/HelpDnsView'),
        }
      ]
    },
    /* ==========================================================================
       End of help pages */
    {
      path: '/my-account',
      component: () => import( /* webpackChunkName: "my-account-parent" */ '@/views/my-account/MyAccountView'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'my-account',
          component: () => import( /* webpackChunkName: "my-account" */ '@/views/my-account/MyAccountOverviewView'),
          meta: { requiresAuth: true }
        },
        {
          path: 'user-auctions',
          name: 'user-auctions',
          component: () => import( /* webpackChunkName: "user-auctions" */ '@/views/UserAuctionsView'),
          meta: { requiresAuth: true }
        },
        {
          path: 'user-domains',
          name: 'user-domains-my-account',
          component: () => import( /* webpackChunkName: "user-domains-parent" */ '@/views/UserDomainsView'),
          meta: { requiresAuth: true },
        },
        {
          path: 'user-details',
          name: 'user-details',
          component: () => import( /* webpackChunkName: "user-details" */ '@/views/UserDetailsView'),
          meta: { requiresAuth: true }
        },
        {
          path: 'billing-details',
          name: 'billing-details',
          component: () => import( /* webpackChunkName: "billing-details" */ '@/views/BillingDetailsView'),
          meta: { requiresAuth: true }
        },
        {
          path: 'account-security',
          name: 'account-security',
          component: () => import( /* webpackChunkName: "account-security" */ '@/views/AccountSecurityView'),
          meta: { requiresAuth: true },
        },
        {
          path: 'account-settings',
          name: 'account-settings',
          component: () => import( /* webpackChunkName: "account-settings" */ '@/views/AccountSettingsView'),
          meta: { requiresAuth: true },
        },
      ]
    },
    {
      path: '/cart',
      component: () => import( /* webpackChunkName: "cart-parent" */ '@/views/cart/CartView'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          name: 'cart-items',
          component: () => import( /* webpackChunkName: "cart-items" */ '@/views/cart/CartItemsView'),
          meta: { requiresAuth: true }
        },
        {
          path: 'billing',
          name: 'cart-billing',
          component: () => import( /* webpackChunkName: "cart-billing" */ '@/views/cart/CartBillingView'),
          meta: { requiresAuth: true }
        },
        {
          path: 'summary',
          name: 'cart-summary',
          component: () => import( /* webpackChunkName: "cart-summary" */ '@/views/cart/CartSummaryView'),
          meta: { requiresAuth: true }
        },
      ]
    },
    {
      path: '/news',
      name: 'news',
      component: () => import( /* webpackChunkName: "news" */ '@/views/NewsView')
    },
    {
      path: '/my-account/account-settings/newsletter-subscribe',
      name: 'newsletter-subscribe',
      component: () => import(/* webpackChunkName: "newsletter-subscribe" */ '@/views/NewsletterSubscribeView')
    },
    {
      path: '/my-account/account-settings/newsletter-unsubscribe',
      name: 'newsletter-unsubscribe',
      component: () => import(/* webpackChunkName: "newsletter-unsubscribe" */ '@/views/NewsletterUnsubscribeView')
    },
    {
      path: '/payment-response',
      name: 'payment-response',
      component: () => import( /* webpackChunkName: "payment-response" */ '@/components/PaymentResponse')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import( /* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicyView')
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import( /* webpackChunkName: "reset-password" */ '@/views/ResetPasswordView')
    },
    {
      path: '/my-account/account-security/change-password',
      name: 'change-password',
      component: () => import( /* webpackChunkName: "change-password" */ '@/views/ChangePasswordView'),
      meta: { requiresAuth: true }
    },
    {
      path: '/sell-website',
      name: 'sell-website',
      component: () => import( /* webpackChunkName: "sell-website" */ '@/views/SellWebsiteView')
    },
    {
      path: '/sign-in',
      name: 'sign-in',
      component: () => import( /* webpackChunkName: "sign-in" */ '@/views/SignInView')
    },
    {
      path: '/sign-up',
      name: 'sign-up',
      component: () => import( /* webpackChunkName: "sign-up" */ '@/views/SignUpView')
    },
    {
      path: '/status',
      name: 'status',
      component: () => import( /* webpackChunkName: "status" */ '@/views/StatusView')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import( /* webpackChunkName: "terms" */ '@/views/TermsView')
    },
    {
      path: '/user-domains-renew',
      name: 'user-domains-renew',
      component: () => import(/* webpackChunkName: "user-domain" */ '@/views/UserDomainsRenewView'),
    },
    {
      path: '/user-domains-reserve',
      name: 'user-domains-reserve',
      component: () => import(/* webpackChunkName: "user-domain" */ '@/views/UserDomainsReserveView'),
    },
    {
      path: '/whois',
      name: 'whois',
      component: () => import( /* webpackChunkName: "whois" */ '@/views/WhoisView')
    },
    {
      path: '*',
      name: 'not-found',
      component: () => import( /* webpackChunkName: "not-found" */ '@/views/error/NotFound404')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})