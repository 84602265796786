<template>
  <div class="TheCookieBanner" v-if="!isCookieConsent">
    <p class="o-default-body-small-light u-display-inline cookie-info-message">{{ messages.cookieInfo }}</p>
    <router-link :to="links.cookies.page">{{ links.cookies.text }}</router-link>
    <md-button class="md-dense md-raised md-primary" @click="cookieStoreConsent">{{ messages.button }}</md-button>
  </div>
</template>

<script>
export default {
  name: 'TheCookieBanner',
  data() {
    return {
      isCookieConsent: false,
      links: {
        cookies: {
          text: this.$t('cookieBanner.settingsAndMore'),
          page: '/cookies'
        }
      },
      messages: {
        cookieInfo: this.$t('cookieBanner.cookieInfo'),
        button: this.$t('cookieBanner.agree')
      }
    };
  },
  created() {
    let cookieConsentValue = this.$cookie.get('cookie_consent');

    if (cookieConsentValue === 'yes') {
      this.isCookieConsent = true;
    } else {
      this.isCookieConsent = false;
    }
  },
  methods: {
    cookieStoreConsent() {
      const value = 'yes';

      this.isCookieConsent = true;
      this.$cookie.set('cookie_consent', value, { expires: '1Y' });
    }
  }
};
</script>

<style lang="scss" scoped>
.TheCookieBanner {
  background-color: $global-color-transparent-two;
  bottom: 0;
  color: $white;
  padding: 1.6rem;
  position: fixed;
  text-align: left;
  width: 100%;
  z-index: 999;

  .cookie-info-message {
    margin-right: .4rem;
  }

  button {
    display: block;
    margin-top: 1.6rem;
    width: 100%
  }
}
</style>
