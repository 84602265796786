<template>
  <div class="LocaleSelector">
    <div class="md-layout-item">
      <flag :iso="this.activeLocale" v-bind:squared="false" />
      <md-field :class="{ myAccountField : isMyAccountView }">
        <md-select v-model="activeLocale"
                   @md-selected="updateLocale()"
                   md-dense
                   name="activeLocale"
                   class="activeLocale"
                   :class="{ activeLocaleBlack : isMyAccountView }">
          <md-option value="ro">{{ this.languages[0].country }} - {{ this.languages[0].language }}</md-option>
          <md-option value="gb">{{ this.languages[1].country }} - {{ this.languages[1].language }}</md-option>
          <md-option value="us">{{ this.languages[2].country }} - {{ this.languages[2].language }}</md-option>
        </md-select>
      </md-field>
    </div>
  </div> <!-- /.LocaleSelector -->
</template>

<script>
import LocalStorageService from '@/common/localstorage.service';

export default {
  name: 'LocaleSelector',
  props: {
    isMyAccountView: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      languages: [
        {
          country: 'România',
          countryCode: 'ro',
          language: 'Română',
          languageCode: 'ro'
        },
        {
          country: 'United Kingdom',
          countryCode: 'gb',
          language: 'English',
          languageCode: 'en'
        },
        {
          country: 'Worldwide',
          countryCode: 'us',
          language: 'English',
          languageCode: 'en'
        }
      ],
      activeLocale: LocalStorageService.getLocalStorage('language_code')
    };
  },
  beforeCreate() {
    // @todo - de automatizat setarea limbii
    if (LocalStorageService.getLocalStorage('language_code') !== null) {
      LocalStorageService.getLocalStorage('language_code')
    } else {
      LocalStorageService.setLocalStorage('language_code', 'ro');
    }
  },
  methods: {
    updateLocale() {
      if (this.activeLocale !== LocalStorageService.getLocalStorage('language_code')) {
        LocalStorageService.setLocalStorage('language_code', this.activeLocale);
        this.$router.go(0);
      }
    },
  }
};
</script>

<style lang="scss">
.LocaleSelector {

  .md-field {
    margin: 0;
    max-width: 22rem;
    min-height: 3.2rem;
    padding: 0;
    padding-left: 2.8rem;
  }

  .flag-icon {
    line-height: 3.2rem;
    position: absolute;
  }

  .activeLocale {

    input {
      -webkit-text-fill-color: $white !important;
      color: $white !important;
      font-family: $font-family-body-thin !important;
      font-size: 1.5rem !important;
    }

    svg {
      fill: $white !important;
    }
  }

  .activeLocaleBlack {

    input {
      -webkit-text-fill-color: $black !important;
      color: $black !important;
      font-family: $font-family-body-light !important;
      font-size: 1.6rem !important;
    }

    svg {
      fill: $black !important;
    }
  }

  .myAccountField {
    max-width: 24rem;
  }

  .md-field.md-theme-default:after {
    background-color: transparent !important;
  }
}
</style>
