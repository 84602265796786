<template>
  <div class="Payment">
    <div class="o-default-h5-light">{{ $t('footer.payment') }}</div>
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-25 md-small-size-25 md-xsmall-size-25">
        <img src="@/assets/images/vendor-logos/payment/visa_logo.png"
             :alt="this.alt.text.payu"
             class="payment-logo u-flex-center-xy" />
      </div>
      <div class="md-layout-item md-medium-size-25 md-small-size-25 md-xsmall-size-25">
        <img src="@/assets/images/vendor-logos/payment/mastercard_logo.png"
             :alt="this.alt.text.rotld"
             class="payment-logo" />
      </div>
      <div class="md-layout-item md-medium-size-25 md-small-size-25 md-xsmall-size-25">
        <img src="@/assets/images/vendor-logos/payment/maestro_logo.png"
             :alt="this.alt.text.rotld"
             class="payment-logo" />
      </div>
      <div class="md-layout-item md-medium-size-25 md-small-size-25 md-xsmall-size-25">
        <img src="@/assets/images/vendor-logos/payment/credit-cards.png"
             :alt="this.alt.text.rotld"
             class="payment-logo--credit-cards" />
      </div>
    </div>
  </div> <!-- /.Payments -->
</template>

<script>
export default {
  name: 'Payment',
  data() {
    return {
      alt: {
        text: {
          rotld: 'RoTLD logo',
          payu: 'PayU logo'
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.Payment {

  .md-layout-item {
    @include up-to-tablet {
      @include flex-center-xy;
    }
    @include tablet {
      @include flex-center-y;
    }
  }

  .o-default-h5-light {
    font-family: $font-family-heading-regular;
    font-size: 1.6rem;
    padding-bottom: 1.6rem;
  }

  .payment-logo {
    max-height: 4rem;
    max-width: 4rem;

    &--credit-cards {
      max-width: 3.2rem;
    }
  }
}
</style>
