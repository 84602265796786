<template>
  <div class="Minicart u-display-ib">
    <div class="minicart-dropdown" @mouseenter="clearCloseMinicartTimeout()"
      @mouseover="isMobileOrTablet ? '' : openMinicart()"
      @mouseleave="isMobileOrTablet ? '' : startCloseMinicartTimeout()" v-click-outside="closeMinicart">

      <MinicartButton :itemsInCart="itemsInCart" />

      <div class="o-dropdown minicart-dropdown-content" :class="{ active: minicartActive }">
        <span class="o-caret--up"></span>

        <MinicartEmptyState v-if="!this.entries || !this.isAuthenticated" />
        <div v-else>
          <md-content class="minicart-items md-scrollbar" :class="{ 'show-scrollbar': showScrollbar }">
            <section class="minicart-content" v-for="item in this.items" :key="item.id">
              <div class="minicart-product-header">
                <div class="md-title minicart-product-name">{{ item.productName }}</div>
                <i class="material-icons md-18 minicart-delete-icon"
                  @click="deleteDomainCart(item); showCopyConfirmation()">delete</i>
              </div>
              <div class="md-subhead">
                {{ $t('minicart.text' + Helpers.capitalizeEachWord(item.productInfo)) }}
              </div>
              <span class="md-caption u-strong">{{ item.productQuantity }} {{ $tc('cartItems.years',
                item.productQuantity) }} x {{ $t('cartItems.euroSign') }}{{ item.priceEuroNoVat.toFixed(2) }}</span>
              <span class="md-caption u-strong u-float-right price-span">{{ $t('cartItems.euroSign') }}{{
                item.totalEntryPriceNoVatEuro.toFixed(2) }}</span><br>
              <md-divider></md-divider>
            </section>
          </md-content>

          <section class="minicart-content-subtotal">
            <span class="md-caption u-strong">{{ $t('minicart.subTotal') }}</span>
            <span class="md-caption u-strong u-float-right price-span">{{ $t('cartItems.euroSign') }}{{
              this.totalPriceNoVat.toFixed(2) }}</span>
          </section>

          <section class="minicart-content-taxes">
            <span class="md-caption u-strong">{{ $t('cartItems.taxes') }}</span>
            <span class="md-caption u-strong u-float-right price-span">{{ $t('cartItems.euroSign') }}{{
              this.totalTaxEuro.toFixed(2) }}</span>
          </section>

          <md-divider></md-divider>

          <section class="minicart-content-total">
            <span class="md-caption u-strong">{{ $t('minicart.total') }}</span>
            <span class="md-caption u-strong u-float-right price-span">{{ $t('cartItems.euroSign') }}{{
              this.totalPrice.toFixed(2) }}</span>
          </section>

          <section class="minicart-buttons-container">
            <div class="minicart-buttons">
              <router-link :to="links.cart.page">
                <md-button class="md-raised md-primary">
                  {{ this.$t('minicart.cartDetails') }}
                </md-button>
              </router-link>
              <router-link :to="links.cart.checkout">
                <md-button class="md-raised md-primary">
                  {{ this.$t('minicart.checkout') }}
                </md-button>
              </router-link>
            </div>
          </section>
        </div>
      </div>
    </div> <!-- /.minicart-dropdown -->

    <md-snackbar :md-position="snackbar.position" :md-duration="snackbar.duration"
      :md-active.sync="snackbar.showSnackbar" md-persistent>
      <span>{{ $t('minicart.delete') }}</span>
      <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  FETCH_CART,
  DELETE_ENTRY,
  MINICART_OPEN,
  MINICART_CLOSE,
  MY_ACCOUNT_DROPDOWN_CLOSE,
  UPDATE_ITEMS_IN_CART
} from '@/store/actions.type';

import MinicartButton from './MinicartButton'
import MinicartEmptyState from './MinicartEmptyState'

export default {
  name: 'Minicart',
  components: {
    MinicartButton,
    MinicartEmptyState
  },
  data() {
    return {
      items: [],
      totalPrice: 0,
      totalPriceNoVat: 0,
      vat: 0,
      snackbar: {
        showSnackbar: false,
        duration: 4000,
        position: 'center',
      },
      links: {
        cart: {
          page: '/cart',
          checkout: '/cart/summary',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'entries',
      'isMobileOrTablet',
      'itemsInCart',
      'minicartActive',
      'totalAmountEuro',
      'totalAmountEuroNoVat',
      'totalTaxEuro',
      'myAccountDropdownActive'
    ]),
    showScrollbar() {
      return this.itemsInCart >= 3;
    },
  },
  updated() {
    this.fetchCart();
  },
  created() {
    this.fetchCart();
  },
  watch: {
    entries: function (val) {
      this.items = val;
      if (this.items) {
        this.$store.dispatch(UPDATE_ITEMS_IN_CART, this.items.length);
      } else {
        this.$store.dispatch(UPDATE_ITEMS_IN_CART, 0);
      }
    },
    totalAmountEuro: function (val) {
      this.totalPrice = val;
    },
    totalAmountEuroNoVat: function (val) {
      this.totalPriceNoVat = val;
    },
    totalTaxEuro: function (val) {
      this.vat = val;
    }
  },
  methods: {
    showCopyConfirmation() {
      this.snackbar.showSnackbar = true;
    },
    fetchCart() {
      this.$store.dispatch(FETCH_CART);
      this.totalPrice = this.totalAmountEuro;
    },
    deleteDomainCart(item) {
      let slug = "?entryId=" + item.id;
      this.$store.dispatch(DELETE_ENTRY, slug);
    },
    openMinicart() {
      if (this.myAccountDropdownActive) {
        this.$store.dispatch(MY_ACCOUNT_DROPDOWN_CLOSE);
      }
      this.$store.dispatch(MINICART_OPEN);
    },
    closeMinicart() {
      this.$store.dispatch(MINICART_CLOSE);
    },
    startCloseMinicartTimeout() {
      // Clear any existing timeout
      if (this.closeMinicartTimeout) {
        clearTimeout(this.closeMinicartTimeout);
      }
      // Set a new timeout for closing the minicart
      this.closeMinicartTimeout = setTimeout(() => {
        this.closeMinicart();
      }, 500);
    },
    clearCloseMinicartTimeout() {
      // Clear the close timeout when re-entering the Minicart
      if (this.closeMinicartTimeout) {
        clearTimeout(this.closeMinicartTimeout);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.Minicart {
  background-color: $white;
  max-height: 80vh;
  padding-left: .4rem;

  .minicart-dropdown {
    line-height: 3rem;
    position: relative;
  }

  .minicart-items {
    max-height: 25rem;
    overflow: hidden;
    overscroll-behavior: none;
    padding: .3rem 0;

    &.show-scrollbar {
      overflow-y: auto;
      scroll-behavior: smooth;


      &:before {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, .12) 100%);
        background-repeat: repeat-x;
        content: "";
        display: block;
        height: 2rem;
        left: 0;
        position: absolute;
        right: 0;
        top: 24rem;
        z-index: 1;
      }
    }
  }

  .minicart-product-name {
    @include truncate();
  }

  .minicart-product-header {
    display: flex;
    justify-content: space-between;
  }

  .minicart-content {
    padding: .6rem;
  }

  .minicart-content-taxes {
    padding: 0 .6rem .6rem 0.6rem;
  }

  .minicart-content-subtotal {
    padding: .6rem .6rem 0 .6rem;
    margin-top: 1rem 0;
  }

  .minicart-content-total {
    padding: 1rem .6rem;
    margin-top: 0;
  }

  .price-span {
    padding-top: 1rem;
    padding-right: .8rem;
  }

  /* Dropdown Content (hidden by default) */
  .minicart-dropdown-content {
    left: -25.4rem;
    opacity: 0;
    transition: max-height .3s ease-in-out, opacity .3s ease-in-out;
    width: 32rem;
    @include round-corners(0.5rem);

    @include desktop {
      left: -16rem;
    }

    &.active {
      max-height: none;
      opacity: 1;
    }

    .o-caret--up:after {
      left: 28.4rem;

      @include desktop {
        left: 24.5rem;
      }
    }

    .md-button {
      color: $black;
      text-decoration: none;
      transition: none;
    }

    .minicart-buttons {
      display: inline-block;
      text-align: center;
    }

    .minicart-buttons-container {
      padding: 0 1.2rem;
      text-align: center;
    }

    .minicart-delete-icon {
      cursor: pointer;
      padding: 0 .6rem 1.2rem 0;
    }

    .minicart-delete-icon:hover {
      cursor: pointer;
      opacity: .7;
    }
  }
}
</style>