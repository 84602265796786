<template>
  <div class="CopyrightInfo">
    <p class="o-default-body-xs-light copyright-text u-text-center">
      {{ $t('footer.copyrightInfo') }}
    </p>
    <p class="o-default-body-xs-light copyright-text u-text-center">
      {{ $t('footer.tosInfoPre') }}
      <router-link class="o-default-body-xs nav-button" :to="links.tos.page">
        <span class="nav-button-label">{{ links.tos.text }}</span>
      </router-link>
      {{ $t('footer.tosInfoPost') }}
    </p>
  </div> <!-- /.CopyrightInfo -->
</template>

<script>
export default {
  name: 'CopyrightInfo',
  data() {
    return {
      links: {
        tos: {
          text: this.$t('footer.tosInfoText'),
          page: '/terms'
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
.CopyrightInfo {
  margin-top: 1.6rem;

  .o-default-body-xs-light {
    font-family: $font-family-heading-light;
    padding-top: .8rem;
  }

  .copyright-text {
    color: $palette-secondary-color-three;
    line-height: 1.5;
    margin: auto;
    max-width: 1000px;
    padding-top: 1.6rem;
  }
}
</style>
