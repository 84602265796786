import Util from '@/common/util.service';
import {
  SHOW_CONFIRMATION_DIALOG,
  HIDE_CONFIRMATION_DIALOG
} from './actions.type';
import {
  SET_SHOW_CONFIRMATION_DIALOG,
} from './mutations.type';

const state = {
  isMobileOrTablet: Util.isMobileOrTablet(),
  showConfirmationDialog: false
};

const getters = {
  isMobileOrTablet(state) {
    return state.isMobileOrTablet;
  },
  showConfirmationDialog(state) {
    return state.showConfirmationDialog;
  }
};

const actions = {
  [SHOW_CONFIRMATION_DIALOG]({ commit }) {
    commit(SET_SHOW_CONFIRMATION_DIALOG, true);
  },
  [HIDE_CONFIRMATION_DIALOG]({ commit }) {
    commit(SET_SHOW_CONFIRMATION_DIALOG, false);
  }
}

const mutations = {
  [SET_SHOW_CONFIRMATION_DIALOG](state, flag) {
    state.showConfirmationDialog = flag;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
