import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import router from '@/router';
import LocalStorageService from '@/common/localstorage.service';
import {
  ACTIVATE_USER,
  CHECK_AUTH,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  SIGNIN,
  SIGNOUT,
  SIGNUP,
  START_AUTH_LOADER,
  STOP_AUTH_LOADER,
  UPDATE_USER_EMAIL,
  RESET_AUTH_ERROR
} from './actions.type';
import {
  SET_ACTIVATE_END,
  PURGE_AUTH,
  SET_RESET_PASSWORD_LOADING,
  SET_AUTH,
  SET_AUTH_LOADING,
  SET_ERROR,
  RESET_ERROR,
  SET_USER_EMAIL
} from './mutations.type';

const state = {
  errors: null,
  user: null,
  userEmail: LocalStorageService.getLocalStorage('user_email'),
  userToken: '',
  isAuthenticated: !!JwtService.getToken(),
  isActivationLoading: true,
  isAuthLoading: false,
  isResetPasswordLoading: false
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentUserEmail(state) {
    return state.userEmail;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isActivationLoading(state) {
    return state.isActivationLoading;
  },
  isResetPasswordLoading(state) {
    return state.isResetPasswordLoading;
  }
};

const actions = {
  [ACTIVATE_USER](context, payload) {
    return new Promise((resolve, reject) => {
      const { activateToken } = payload;

      ApiService.post('activate', {
        token: activateToken
      })
      .then(({ data }) => {
        context.commit(SET_ACTIVATE_END);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ACTIVATE_END);
        context.commit(SET_ERROR, response.data.message);
        reject(response);
      });
    });
  },
  [FORGOT_PASSWORD](context, payload) {
    context.commit(SET_AUTH_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post('api/password/forgotten-password', {
        email: payload.email,
        captchaToken: payload.captchaToken
      })
      .then(({ data }) => {
        context.commit(SET_AUTH_LOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        console.log('Error: ', response);
        context.commit(SET_ERROR, response.data.message);
        reject(response);
      })
    });
  },
  [RESET_PASSWORD](context, payload) {
    context.commit(SET_RESET_PASSWORD_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post('api/password/reset-password', {
        password: payload.password,
        resetPasswordToken: payload.resetPasswordToken,
        captchaToken: payload.captchaToken
      })
      .then(({ data }) => {
        context.commit(SET_RESET_PASSWORD_LOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message);
        context.commit(SET_RESET_PASSWORD_LOADING, false);
        reject(response);
      });
    });
  },
  [CHANGE_PASSWORD](context, payload) {
    context.commit(SET_RESET_PASSWORD_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.post('api/my-account/change-password', {
        password: payload.password,
        oldPassword: payload.oldPassword,
      })
      .then(({ data }) => {
        context.commit(SET_RESET_PASSWORD_LOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message);
        context.commit(SET_RESET_PASSWORD_LOADING, false);
        reject(response);
      });
    });
  },
  [SIGNUP](context, credentials) {
    context.commit(SET_AUTH_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('register', {
        email: credentials.email,
        password: credentials.password,
        newsletter: credentials.newsletter,
        captchaToken: credentials.captchaToken
      })
      .then(({ data }) => {
        context.commit(SET_AUTH_LOADING, false);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message);
        reject(response);
      });
    });
  },
  [SIGNIN](context, credentials) {
    context.commit(SET_AUTH_LOADING, true);

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('auth', {
        username: credentials.email,
        password: credentials.password,
        captchaToken: credentials.captchaToken
      })
      .then(({ data }) => {
        context.commit(SET_AUTH_LOADING, false);

        context.commit(SET_AUTH, data.token);
        resolve(data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.message);
        reject(response);
      });
    });
  },
  [SIGNOUT](context) {
    context.commit(PURGE_AUTH, router.history.current);
  },
  [CHECK_AUTH](context, to) {
    if (to.meta.requiresAuth) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get('api/my-account/user/view')
          .then(({ data }) => {
            context.commit(SET_USER_EMAIL, data.user.email);
          })
          .catch(({ response }) => {
            console.log('Error: ', response)
            // context.commit(SET_ERROR, response.data.errors);
            context.commit(PURGE_AUTH, to);
          });
      } else {
        context.commit(PURGE_AUTH, to);
      }
    }
  },
  [UPDATE_USER_EMAIL](context, userEmail) {
    context.commit(SET_USER_EMAIL, userEmail);
  },
  [START_AUTH_LOADER](context, loadingFlag) {
    context.commit(SET_AUTH_LOADING, loadingFlag);
  },
  [STOP_AUTH_LOADER](context, loadingFlag) {
    context.commit(SET_AUTH_LOADING, loadingFlag);
  },
  [RESET_AUTH_ERROR]({ commit }) {
    commit(RESET_ERROR);
  },
};

const mutations = {
  [SET_ACTIVATE_END](state) {
    state.isActivationLoading = false;
  },
  [SET_RESET_PASSWORD_LOADING](state, loadingFlag) {
    state.isResetPasswordLoading = loadingFlag;
  },
  [SET_AUTH_LOADING](state, loadingFlag) {
    state.isAuthLoading = loadingFlag;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
    state.isAuthLoading = false;
  },
  [RESET_ERROR]() {
    state.errors = null;
  },
  [SET_AUTH](state, token) {
    state.isAuthenticated = true;
    state.userToken = token;
    state.errors = null;
    JwtService.saveToken(state.userToken);
  },
  [SET_USER_EMAIL](state, email) {
    state.userEmail = email;
    LocalStorageService.setLocalStorage('user_email', email);
  },
  [PURGE_AUTH](state, to) {
    state.isAuthenticated = false;
    state.user = null;
    state.errors = null;
    JwtService.destroyToken();
    LocalStorageService.destroyLocalStorage('user_email');

    if (to.meta.requiresAuth) {
      // console.log('router.to is: ', to)
      router.push({ name: 'sign-in' });
    } else {
      location.reload();
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
