<template>
  <div class="TheTopHeader">
    <div class="o-container">
      <!-- Side navigation / mobile menu -->
      <SideNavigation class="u-hide@desktop">
        <router-link v-for="(item, index) in links"
                     :key="item.id"
                     :to="`${item.page}`">
          <i class="material-icons">{{ icons[index] }}</i>
          <span class="navigation-link green-border-hover">{{ item.text }}</span>
        </router-link>
      </SideNavigation>
      <router-link :to="links[0].page">
        <img src="@/assets/inreg-logo.png"
             alt="Inreg logo"
             class="o-logo-medium" />
        <!-- Notification pill next to the logo in header -->
        <!-- <div class="beta_notification">BETA</div> -->
      </router-link>
      <ShortcutMenu class="u-float-right"/>
    </div> <!-- /.o-container -->
  </div> <!-- /.TheTopHeader -->
</template>

<script>
import {
  mapGetters
} from 'vuex';
import ShortcutMenu from '@/components/header/ShortcutMenu';
import SideNavigation from '@/components/header/SideNavigation';


export default {
  name: 'TheTopHeader',
  components: {
    SideNavigation,
    ShortcutMenu
  },
  data() {
    return {
      icons: ['home', 'public', 'gavel', 'search', 'account_circle'],
      links: [
        {
          id: 0,
          page: '/',
          text: this.$t('common.home')
        },
        {
          id: 1,
          page: '/domain-names',
          text: this.$t('topHeader.domainNames')
        },
        {
          id: 2,
          page: '/auctions',
          text: this.$t('topHeader.domainAuctions')
        },
        // {
        //   id: 3,
        //   page: '/buy-website',
        //   text: this.$t('topHeader.buyWebsite')
        // },
        // {
        //   id: 4,
        //   page: '/sell-website',
        //   text: this.$t('topHeader.sellWebsite')
        // },
        {
          id: 5,
          page: '/whois',
          text: this.$t('topHeader.whoisLookup')
        },
        {
          id: 6,
          page: '/my-account',
          text: this.$t('topHeader.myAccount')
        },
        // {
        //   id: 7,
        //   page: '/about-us',
        //   text: this.$t('topHeader.aboutUs')
        // }
      ]
    };
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
};
</script>

<style lang="scss">
.TheTopHeader {
  padding: .4rem;
  @include disable-select;
  @include tablet { padding: .4rem 2.4rem; }
  @include desktop { padding: .4rem 3.2rem 0; }

  .o-container {
    margin-bottom: 0;
    padding: 0;
    text-align: left;
  }

  .material-icons {
    color: $palette-secondary-color-four;
    transform: translate(0, .5rem);
  }

  .navigation-link {
    @include default-h4;
    padding-right: 3.2rem;
    padding-left: 1rem;

    &:hover {
      color: $black;
      text-decoration: none;
    }
  }

  .green-border-hover {
    display: inline-block;
    line-height: 1.5;
  }

  .green-border-hover:after {
    border-bottom: solid .2rem;
    border-color: $palette-primary-color-two;
    content: '';
    display: block;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .green-border-hover:hover:after {
    transform: scaleX(.9);
  }

  /*
  // Notification pill next to the logo in header
  .beta_notification {
    background: #0a94d0;
    color: #fff;
    font-size: 1rem;
    padding: .4rem;
    line-height: 1;
    transform: translate(0, -50%);
    margin-left: .8rem;
    border-radius: .5rem;
    display: inline-block;
  }
  */
}
</style>
