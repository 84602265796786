<template>
  <md-dialog :md-active.sync="showConfirmationDialog"
             v-click-outside="hideConfirmationDialog">
    <md-dialog-title>{{ $t('common.success') }}</md-dialog-title>

    <div class="o-dialog-content">
      <div class="o-default-body">
        {{ $t('common.successfullySaved') }}
      </div>
    </div>

    <md-dialog-actions>
      <md-button class="md-primary" @click="hideConfirmationDialog()">{{ $t('common.actions.close') }}</md-button>
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import LocalStorageService from '@/common/localstorage.service';
import {
  SHOW_CONFIRMATION_DIALOG,
  HIDE_CONFIRMATION_DIALOG
} from '@/store/actions.type';


export default {
  name: 'ConfirmationDialog',
  computed: {
    ...mapGetters({
      _showConfirmationDialog: 'showConfirmationDialog'
    }),
    showConfirmationDialog: {
      get() {
        return this._showConfirmationDialog;
      },
      set(updatedShowConfirmationDialog) {
        return updatedShowConfirmationDialog;
      }
    }
  },
  mounted() {
    this.checkUserDetailsUpdated();
  },
  methods: {
    checkUserDetailsUpdated() {
      if (LocalStorageService.getSessionStorage('isUpdateSuccess') !== null) {
        this.$store.dispatch(SHOW_CONFIRMATION_DIALOG);
        LocalStorageService.destroySessionStorage('isUpdateSuccess');
      }
    },
    hideConfirmationDialog() {
      this.$store.dispatch(HIDE_CONFIRMATION_DIALOG);
    }
  },
}
</script>