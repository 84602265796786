<template>
  <div class="TheFooter">
    <div class="md-layout footer-content">
      <FooterTop class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-100 md-xsmall-size-100 with-divider"/>
      <FooterNavigation class="md-layout md-layout-item md-xlarge-size-66 md-large-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100"/>
      <Partners class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-33 md-xsmall-size-100 with-divider"/>
      <SecuredWith class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-33 md-xsmall-size-100 with-divider"/>
      <Payment class="md-layout-item md-xlarge-size-33 md-large-size-33 md-medium-size-33 md-small-size-33 md-xsmall-size-100 with-divider"/>
      <CopyrightInfo class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100"/>
    </div> <!-- /.md-layout -->
  </div> <!-- /.TheFooter -->
</template>

<script>
import FooterTop from '@/components/footer/FooterTop';
import FooterNavigation from '@/components/footer/FooterNavigation';
import Partners from '@/components/footer/Partners';
import SecuredWith from '@/components/footer/SecuredWith';
import Payment from '@/components/footer/Payment';
import CopyrightInfo from '@/components/footer/CopyrightInfo';

export default {
  name: 'TheFooter',
  components: {
    FooterTop,
    FooterNavigation,
    Partners,
    SecuredWith,
    Payment,
    CopyrightInfo
  }
};
</script>

<style lang="scss" scoped>
.TheFooter {
  background-color: $global-color-two;
  color: $white;
  overflow: auto;
  padding: 4rem 2.4rem;
  width: 100%;
  z-index: 1;
  @include tablet { padding: 4rem 3.2rem; }

  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
  }

  .with-divider {
    border-bottom: .1rem solid $global-color-one;
    padding: 2.4rem 0;
  }
}
</style>
