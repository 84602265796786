// General
export const RESET_STATE = 'resetModuleState';
export const SET_ERROR = 'setError';

// Auth
export const SET_ACTIVATE_END = 'setActivationLoading';
export const PURGE_AUTH = 'signOut';
export const SET_RESET_PASSWORD_LOADING = 'setResetPasswordLoading';
export const SET_AUTH = 'setUser';
export const SET_AUTH_LOADING = 'setAuthLoading';
export const SET_AUTH_CODE_SENT = 'setAuthCodeSent';
export const SET_USER_EMAIL = 'setUserEmail';
export const RESET_ERROR = 'resetError';

// Auctions
export const SET_DOMAIN_AUCTIONS = 'setDomainAuctions';
export const SET_AUCTION = 'setAuction';
export const SET_AUCTION_VIEW_ERROR = 'setAuctionViewError';
export const SET_AUCTION_ERROR = 'setAuctionError';
export const SET_OFFER_VALUE = 'setOfferValue';
export const SET_WINNER_FLAG = 'setWinnerFlag';

// Loader
export const SET_LOADER_START = 'setLoaderStart';
export const SET_USER_DOMAINS_LOADER_START = 'setUserDomainsLoaderStart';
export const SET_USER_DOMAINS_LOADER_STOP = 'setUserDomainsLoaderStop';

// My account
export const SET_ACCOUNT_CONNECTION_ERROR = 'setAccountConnectionError';
export const SET_PHONE_NUMBER = 'setPhoneNumber';
export const SET_PHONE_VERIFIED = 'setPhoneVerified';
export const SET_PHONE_VERIFIED_TEMP = 'setPhoneVerifiedTemp';
export const SET_PHONE_ERROR = 'setPhoneError';
export const RESET_PHONE_ERROR = 'resetPhoneError';
export const SET_NEWSLETTER = 'setNewsletter';
export const SET_MY_ACCOUNT_LOADING = 'setMyAccountLoading';
export const SET_CURRENCY = 'setCurrency';
export const SET_CURRENCY_ERROR = 'setCurrencyError';
export const SET_RESET_CURRENCY_ERROR = 'setResetCurrencyError';

// Billing details
export const SET_BILLING_DETAILS = 'setBillingDetails';
export const SET_BILLING_DETAILS_ADDRESSES = 'setBillingDetailsAddresses';
export const SET_BILLING_DETAILS_DEFAULT = 'setBillingDetailsDefault';
export const SET_BILLING_DETAILS_NON_DEFAULT = 'setBillingDetailsNonDefault';
export const SET_BILLING_DETAILS_ERROR = 'setBillingDetailsError';
export const SET_BILLING_DETAILS_LOADING = 'setBillingDetailsLoading';
export const SET_BILLING_DETAILS_FORM_SENT = 'setBillingDetailsFormSent';
export const SET_BILLING_DETAILS_ADD_STATE = 'setBillingDetailsAddState';
export const SET_BILLING_DETAILS_EDIT_STATE = 'setBillingDetailsEditState';
export const SET_BILLING_DETAILS_OTHER_EDIT_STATE = 'setBillingDetailsOtherEditState';
export const SET_SHOW_BILLING_DETAILS_ADD_DIALOG = 'setShowBillingDetailsAddDialog';
export const SET_RESET_BILLING_DETAILS = 'setResetBillingDetails';
export const SET_RESET_BILLING_DETAILS_ERROR = 'setResetBillingDetailsError';
export const SET_RESET_BILLING_DETAILS_MODULE = 'setResetBillingDetailsModule';

// User details
export const SET_USER_DETAILS = 'setUserDetails';
export const SET_USER_DETAILS_ADDRESSES = 'setUserDetailsAddresses';
export const SET_USER_DETAILS_DEFAULT = 'setUserDetailsDefault';
export const SET_USER_DETAILS_NON_DEFAULT = 'setUserDetailsNonDefault';
export const SET_USER_DETAILS_ERROR = 'setUserDetailsError';
export const SET_USER_DETAILS_LOADING = 'setUserDetailsLoading';
export const SET_USER_DETAILS_FORM_SENT = 'setUserDetailsFormSent';
export const SET_USER_DETAILS_ADD_STATE = 'setUserDetailsAddState';
export const SET_USER_DETAILS_EDIT_STATE = 'setUserDetailsEditState';
export const SET_USER_DETAILS_OTHER_EDIT_STATE = 'setUserDetailsOtherEditState';
export const SET_RESET_USER_DETAILS = 'resetUserDetails';
export const SET_RESET_USER_DETAILS_ERROR = 'resetUserDetailsError';
export const SET_RESET_USER_DETAILS_MODULE = 'setResetUserDetailsModule';

// User auctions
export const SET_USER_AUCTIONS = 'setUserAuctions';
export const SET_USER_AUCTIONS_ERROR = 'setUserAuctionsError';
export const SET_USER_AUCTIONS_LOADER = 'setUserAuctionsLoader';
export const RESET_AUCTION_VIEW_ERROR = 'resetAuctionViewError';
export const RESET_AUCTION_ERROR = 'resetAuctionError';

// User domains
export const SET_CAPTURED_DOMAINS = 'setCapturedDomains';
export const SET_USER_DOMAIN = 'setUserDomain';
export const SET_USER_DOMAINS = 'setUserDomains';
export const SET_USER_DOMAIN_ERROR = 'setUserDomainError';
export const SET_USER_DOMAINS_ERROR = 'setUserDomainsError';
export const SET_USER_DOMAIN_NAMESERVERS_ERROR = 'setUserDomainNameserversError';
export const SET_DOMAIN_CHECKED = 'setDomainChecked';
export const SET_DOMAIN_CHECKED_ERROR = 'setDomainCheckedError';
export const SET_TRANSFERRED_DOMAIN = 'setTransferredDomain';
export const SET_TRANSFER_DOMAIN_ERROR = 'setTransferDomainError';
export const SET_POPULAR_TRANSFER_BENEFITS = 'setPopularTransferBenefits';
export const SET_LIKE_POPULAR_TRANSFER_BENEFITS_ERROR = 'setLikePopularTransferBenefitsError';

// Cart
export const SET_CART_DATA_FETCHED = 'setCartDataFetched';
export const SET_UPDATE_CART = 'setUpdateCart'
export const SET_TOTAL_AMOUNT_EURO = 'setTotalAmountEuro';
export const SET_TOTAL_AMOUNT_RON = 'setTotalAmountRon';
export const SET_ID = 'setId';
export const SET_AVAILABILITY = 'setAvailability';
export const SET_SHOW_SEARCHED_DOMAINS = 'setShowSearchedDomains';
export const RESET_CART_DATA_FETCHED = 'resetCartDataFetched';
export const SET_ADD_ERROR = 'setAddError';
export const SET_TOTAL_AMOUNT_EURO_NO_VAT = 'setTotalAmountEuroNoVat';
export const SET_TOTAL_AMOUNT_RON_NO_VAT = 'setTotalAmountRonNoVat';
export const SET_TOTAL_TAX_EURO = 'setTotalTaxEuro';
export const SET_TOTAL_TAX_RON = 'setTotalTaxRon';
export const SET_CHECK_AVAILABILITY_ERROR = 'setCheckAvailabilityError';
export const SET_CHECK_AVAILABILITY_LOADER = 'setCheckAvailabilityLoader';
export const SET_CART_LOADING = 'setCartLoading';
export const SET_ITEMS_IN_CART = 'setItemsInCart';

// Checkout
export const SET_CHECKOUT_PAYMENT_FORM = 'setCheckoutPaymentForm';
export const SET_CHECKOUT_PAYMENT_VALIDATION = 'setCheckoutPaymentValidation';
export const SET_CHECKOUT_ERROR = 'setCheckoutError';
export const SET_RESET_CHECKOUT_ERROR = 'setResetCheckoutError';
export const SET_CHECKOUT_LOADING = 'setCheckoutLoading';
export const SET_SUCCESSFUL_PAYMENT = 'setSuccessfulPayment';

// Minicart
export const SET_MINICART_OPEN = 'setMinicartOpen';
export const SET_MINICART_CLOSE = 'setMinicartClose';

// My account dropdown
export const SET_MY_ACCOUNT_DROPDOWN_OPEN = 'setMyAccountDropdownOpen';
export const SET_MY_ACCOUNT_DROPDOWN_CLOSE = 'setMyAccountDropdownClose';

// Util
export const SET_SHOW_CONFIRMATION_DIALOG = 'setShowConfirmationDialog';
