<template>
  <div id="app">
    <!-- <md-snackbar class="snackbar-mobile"
                :md-position="position"
                :md-duration="Infinity"
                :md-active.sync="showSnackbar"
                md-persistent>
      <span>{{ $t('snackbar') }}</span>
      <md-button class="md-primary" @click="showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar> -->
    <TheCookieBanner />
    <TheInfoHeader />
    <TheTopHeader />
    <TheNavigationHeader />
    <TheBreadcrumbs breadcrumbsRoot="Home" /> <!-- breadcrumbsRoot prop is optional (default is "Home") -->
    <ScrollTopArrow class="u-hide@desktop" />
    <ConfirmationDialog />
    <div id="app-content">
      <router-view />
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheCookieBanner from '@/components/header/TheCookieBanner';
import TheInfoHeader from '@/components/header/TheInfoHeader';
import TheTopHeader from '@/components/header/TheTopHeader';
import TheNavigationHeader from '@/components/header/TheNavigationHeader';
import TheBreadcrumbs from '@/components/TheBreadcrumbs';
import TheFooter from '@/components/footer/TheFooter';
import ScrollTopArrow from '@/components/util/ScrollTopArrow';
import ConfirmationDialog from '@/components/util/ConfirmationDialog';

export default {
  name: 'App',
  components: {
    TheCookieBanner,
    TheInfoHeader,
    TheTopHeader,
    TheNavigationHeader,
    TheBreadcrumbs,
    TheFooter,
    ScrollTopArrow,
    ConfirmationDialog
  }
};
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  #app-content {
    background: $palette-secondary-color-two;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
</style>
