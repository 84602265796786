<template>
  <div class="TheInfoHeader">
    <div class="o-container">
      <span class="info-container" @click="Helpers.copyToClipboard(messages.contactEmail); showCopyConfirmation(snackbar.text.email)">
        <i class="material-icons md-12">mail</i>
        <span class="o-default-body-xs-light">{{ messages.contactEmail }}</span>
        <md-tooltip md-direction="bottom">{{ messages.clickToCopy }}</md-tooltip>
      </span>
      <span class="info-container"
            @click="Helpers.copyToClipboard(messages.contactPhone);
            showCopyConfirmation(snackbar.text.phone)">
        <i class="material-icons md-12">phone</i>
        <span class="o-default-body-xs-light">{{ messages.contactPhone }}</span>
        <md-tooltip md-direction="bottom">{{ messages.clickToCopy }}</md-tooltip>
      </span>
      <!-- <i class="material-icons">check_circle</i>
      <router-link :to="links.status.page">{{ links.status.text }}</router-link> -->
    </div>
    <md-snackbar :md-position="snackbar.position"
                 :md-duration="snackbar.duration"
                 :md-active.sync="snackbar.showCopiedToClipboardSnackbar"
                 md-persistent>
      <span>{{ $t('snackbar.copied') }} {{ snackbar.copiedContent }} {{ $t('snackbar.toClipboard') }}</span>
      <md-button class="md-primary" @click="snackbar.showCopiedToClipboardSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </div>
</template>

<script>
export default {
  name: 'TheInfoHeader',
  data() {
    return {
      snackbar: {
        showCopiedToClipboardSnackbar: false,
        copiedContent: '',
        duration: 4000,
        position: 'center',
        text: {
          email: this.$t('snackbar.emailAddress'),
          phone: this.$t('snackbar.phoneNumber')
        }
      },
      links: {
        status: {
          text: this.$t('infoHeader.serviceStatus'),
          page: '/status'
        }
      },
      messages: {
        clickToCopy: this.$t('common.clickToCopy'),
        contactEmail: this.$t('contactEmailInreg'),
        contactPhone: this.$t('contactPhoneInreg'),
      },
    };
  },
  methods: {
    showCopyConfirmation(content) {
      this.snackbar.copiedContent = content;
      this.snackbar.showCopiedToClipboardSnackbar = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.TheInfoHeader {
  background-color: $palette-secondary-color-four;
  color: $white;
  padding: .2rem .8rem;
  @include tablet { padding: .2rem 2.4rem; }
  @include desktop { padding: .2rem 3.2rem; }

  .o-container {
    margin-bottom: 0;
    padding: 0;
    text-align: left;
  }

  .info-container {
    cursor: pointer;
    margin-right: 1.6rem;
  }

  .material-icons {
    @include transform(translate(0, .4rem));
    margin-right: .4rem;
    vertical-align: top;
  }
}
</style>
