import Vue from 'vue';
import VueI18n from 'vue-i18n';
import LocalStorageService from '@/common/localstorage.service';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  // Active locale
  locale: (LocalStorageService.getLocalStorage('language_code') === 'ro' || LocalStorageService.getLocalStorage('language_code') === null ? 'ro' : 'en'),
  // Fallback locale
  fallbackLocale: 'en',
  messages: {
    'en': {
      'siteUrl': 'inreg.co.uk',
      'contactEmailInreg': 'contact@inreg.co.uk',
      'officeEmailInreg': 'office@inreg.ro',
      'contactPhoneInreg': '(+40) 740 457 486',
      'addressInreg': '47 Fabricii, building H, suite 3, sector 6, Bucharest, Romania',
      'common': {
        'aboutUs': 'About us',
        'actions': {
          'addRegistrationDetails': 'Add registration details',
          'addBillingDetails': 'Add billing details',
          'addNewBillingDetails': 'Add new billing details',
          'addNewEntry': 'Add a new entry',
          'addToCart': 'Add to cart',
          'cancel': 'Cancel',
          'close': 'Close',
          'confirm': 'Confirm',
          'delete': 'Delete',
          'deleteData': 'Delete data',
          'deleteDomainFromCart': 'Remove from cart',
          'edit': 'Edit',
          'editData': 'Edit data',
          'goBack': 'Go back',
          'retry': 'Retry',
          'save': 'Save',
          'search': 'Search',
          'select': 'Select',
          'pay': 'Pay',
          'undo': 'Undo',
          'wait': 'Please wait'
        },
        'addressLabels': {
          'fullName': 'Full name',
          'type': 'Type',
          'identificationNumber': 'Identification no. (NINO / CRN)',
          'referenceNumber': 'Unique taxpayer reference (VAT ID / UTR)',
          'email': 'Email',
          'phone': 'Phone',
          'address': 'Address',
        },
        'auth': {
          'enterYourPassword': 'Please enter your password',
          'incorrectEmail': 'Wrong email address',
          'pleaseEnterEmail': 'Please enter your email',
          'signIn': 'Sign In',
          'signin': 'Sign in',
          'signOut': 'Sign Out',
          'signUp': 'Create Account',
        },
        'clickToCopy': 'Click to copy',
        'confirmation': {
          'pleaseConfirm': 'Please confirm',
          'setAsDefault': 'Please confirm that you want to change the default details',
          'delete': 'Please confirm that you want to delete the selected entry',
        },
        'contact': 'Contact',
        'continue': 'Continue',
        'currencies': {
          'eur': 'EUR',
          'ron': 'RON',
        },
        'currency': 'Currency *',
        'date': {
          'years': 'years',
          'year': 'year',
          'months': 'months',
          'month': 'month',
          'weeks': 'weeks',
          'week': 'week',
          'days': 'days',
          'day': 'day',
          'hours': 'hours',
          'hour': 'hour',
          'minutes': 'minutes',
          'minute': 'minute',
          'min': 'min',
          'seconds': 'seconds',
          'second': 'second',
          'sec': 'sec'
        },
        'details': 'Details',
        'domainAuctions': {
          'title': 'Domain auctions',
          'description': 'A lot of premium domain names become available for auction every day. View the list of active auctions right now.'
        },
        'domainSearch': {
          'title': 'Domain names',
          'description': 'Choose the perfect domain name in seconds.',
          'searchPlaceholder': 'Search domains...',
          'searchedDomainsPlaceholder': '{count} domain selected | {count} domains selected',
        },
        'domainTransfer': {
          'text': 'Domain transfer',
          'title': 'Transfer a domain:'
        },
        'email': 'Email',
        'entityType': {
          'p': 'Individual',
          'ap': 'Natural person (authorized)',
          'nc': 'Non-commercial organization',
          'c': 'Company',
          'gi': 'Government institution',
          'pi': 'Public institution',
          'o': 'Other type of organisation'
        },
        'error': {
          'furtherIssuesContact': 'If you have any further issues, please contact us:',
          'paymentFailed': 'Something went wrong with your payment. Please try again later.',
          'noCountriesMatchingContent': 'No countries matching "{term}" were found.',
          'noReservedDomainsMatchingContent': 'Unfortunately, we couldn`t find any domain matching "{searchReservedDomains}"',
          'noRegisteredDomainsMatchingContent': 'Unfortunately, we couldn`t find any domain matching "{searchRegisteredDomains}"',
          'noDomainsFound': 'No domain found',
        },
        'home': 'Home',
        'help': 'Help',
        'name': 'Name',
        'or': 'Or',
        'phone': {
          'phoneNumber': 'Phone number...',
          'error': {
            'invalid': 'Please enter a valid phone number'
          }
        },
        'price': 'Price',
        'quantity': 'Quantity',
        'reloadPage': 'Reload page',
        'securedBy': 'Payment secured by',
        'steps': {
          'step1': 'Step 1',
          'step2': 'Step 2',
          'step3': 'Step 3',
        },
        'success': 'Success',
        'successfullySaved': 'Changes have been successfully saved.',
        'transfer': 'Transfer',
        'userDomains': {
          'title': 'My domains',
          'description': 'Change nameservers or review your domains.',
          'domainsSelection1': `Domains with status 'Reserved' will be added to your cart for registration. The default registration period is set to 1 year.`,
          'domainsSelection2': `Domains with status 'Registered' will be added to your cart for renewal. The default renewal period is set to 1 year after the current expiration date.`,
          'domainsSelection3': `To modify the registration or renewal period beyond 1 year, please adjust the periods/quantities directly in your cart after adding the domains.`
        },
      },
      'errorViews': {
        'notFound404': {
          'notFound': 'This page doesn’t exist. 404 error.'
        }
      },
      'aboutUsView': 'Sorry, we’re doing some work on this section. We will be back with some exciting new content! Coming soon! Let us know if you have any questions:',
      'account': {
        'currency': {
          'title': 'Account currency',
          'error': {
            'currencyRequired': 'Please select your currency',
          }
        },
        'security': {
          'changePassword': 'Change password',
          'changePasswordDescription': 'Change your password for increased account security.'
        },
        'settings': {
          'subscribe': 'Subscribe',
          'subscribeDescription': 'Subscribe to our newsletter',
          'unsubscribe': 'Unsubscribe',
          'unsubscribeDescription': 'Unsubscribe from our newsletter',
        },
      },
      'activateUserView': {
        'activationInProgress': 'Account activation in progess...',
        'accountActivated': 'Account successfully activated!',
        'goToSignIn': 'Go to the sign in page:',
      },
      'auctionView': {
        'bidFor': 'Bid for',
        'timeLeft': 'Time left',
        'auctionClosedTimeElapsed': 'Auction closed - time\u00a0elapsed',
        'auctionClosesOn': 'Auction closes on',
        'atHour': 'at',
        'bidIncrement': 'Bid increment',
        'dialogContent1': 'Your bid should be multiple of 5 (e.g. €15, €20, €25, €75).',
        'until': 'Until',
        'minimumAmountPerBid': 'Minimum amount / bid',
        'dialogContent2': 'For every extension round, the minimum bid is raised by ',
        'dialogContent3': '.',
        'startingWith': 'Starting with',
        'minimumBid': 'Minimum raise / bid',
        'dialogExample': 'E.g. You cannot bid €17 because 17 is not multiple of 5 but €15 and €20 are valid offers.',
        'extendingTheAuction': 'Extending the auction',
        'extAuctionContent1': 'The auction is extended if a user is placing a better offer in the last 5 minutes. The auction can be extended up to a maximum of 45 minutes.',
        'extAuctionContent2': 'If the auction is extended until 23:45, the maximum extension limit is reached and you will not be allowed to bid anymore.',
        'extAuctionContent3': 'The user can bid in the extended period only if it placed a bid before 23:00.',
        'extAuctionContent4': 'For every extension round, the minimum bid is raised by ',
        'extAuctionContent5': '.',
        'extAuctionExample': 'E.g. If you placed a €30 offer before 23:00 and the auction is closing at 23:15, you need to bid at least €50',
        'extAuctionPreview': 'Auction is extended with 5 minutes after last bet is placed - can be extended until max 23:45.',
        'extraAuctionInfo1': 'Your bid should be multiple of 5 (e.g. €15, €20, €25, €75).',
        'extraAuctionInfo2': 'The minimum offer is €15, and above this amount, the bid step is €5. The bid step is changing for extended auctions.',
        'extraAuctionInfo3': 'We cannot guarantee the reservation / registration of the domain name but if we sucessfully capture it payment becomes compulsory.',
        'extraAuctionInfo4': 'In extraordinary situations (e.g. fraud, error occurs) the biggest offer might be removed, and your offer can qualify as the highest one (even if you were not notified as having the highest bid).',
        'extraAuctionInfo5': 'If you win the auction and we successfully reserve / register the domain name for you, the total price will be calculated by adding together the amount you bid + registration fee charged by Inreg for the corresponding extension / TLD.',
        'extraAuctionInfo6': 'All prices listed on our site exclude any applicable VAT / GST.',
        'extraAuctionInfo7': 'If two or more equal bids are placed only the oldest one will be considered.',
        'auctionStatus': 'Auction status:',
        'currentOffer': 'Your current offer:',
        'congratulationsHighestOffer': 'Congratulations! You have the highest offer.',
        'notTheHighestOffer': 'Your offer is not the highest.',
        'noOffer': 'You did not place a bid for this domain name yet.',
        'placeOffer': 'Place an offer for',
        'yourOfferInputLabel': 'Your offer (€)',
        'inputOfferRequired': 'This field is required',
        'inputMinimumOffer': 'The minimum offer is €15.',
        'inputMaximumOffer': 'The maximum offer is €10000. For an offer exceeding this value please contact us.',
        'inputFiveMultiple': 'Your bid should be multiple of 5 (e.g. €15, €20, €25, €75).',
        'sendOffer': 'Send offer (€)',
        'cannotAddOfferTimeExpired': 'No bids can be placed anymore. The auction has closed.',
        'error': {
          'auctionViewError': 'We couldn’t find this auction. Please check the active auctions list.',
          'auctionViewErrorAuctionsLink': 'Take me to the active auctions list'
        }
      },
      'billingDetailsAdd': {
        'title': 'Add billing details',
        'description': 'Billing information is required to make payments and generate invoices',
        'useExistingEntry': 'Or use an existing entry:'
      },
      'billingDetailsEmpty': {
        'label': 'No default billing information',
        'description': 'Billing information is required to make payments and generate invoices.',
        'useExistingEntry': 'Or use an existing entry:',
        'titleAdd': 'Add billing details',
        'descriptionAdd': 'Billing information is required to make payments and generate invoices'
      },
      'billingDetailsForm': {
        'name': 'Full name *',
        'entityType': 'Legal entity type *',
        'identificationNumber': 'NINO or CRN (example: QQ123456C or 01234567) *',
        'referenceNumber': 'Unique taxpayer reference (UTR) or VAT ID (example: 9012345678) *',
        'address': 'Address *',
        'address2': 'Address 2 (optional)',
        'city': 'City *',
        'state': 'State *',
        'postalCode': 'Postal code *',
        'country': 'Country *',
        'email': 'Email address *',
        'error': {
          'nameRequired': 'Please enter the full name',
          'nameMinLength': 'Invalid name, it should be at least 3 characters long',
          'entityTypeRequired': 'Please select the entity type',
          'identificationNumberRequired': 'Please enter NINO or CRN, as appropriate',
          'identificationNumberMinLength': 'Please enter a valid value',
          'identificationNumberMaxLength': 'Please enter a valid value',
          'referenceNumberRequired': 'Please enter a value',
          'referenceNumberMinLength': 'Please enter a valid value',
          'addressRequired': 'Please enter an address',
          'addressMinLength': 'Please enter a valid address',
          'cityRequired': 'Please enter a city',
          'stateRequired': 'Please enter a state',
          'postalCodeRequired': 'Postal code (or ZIP code) is required',
          'postalCodeMinLength': 'Please enter a valid postal code (or ZIP code)',
          'countryRequired': 'Please select country',
          'emailRequired': 'Please enter an email address',
          'emailValid': 'Please enter a valid email address'
        },
      },
      'billingDetailsItem': {
        'setAsDefault': 'Make default',
        'setAsDefaultTooltip': 'Set as default billing information'
      },
      'billingDetailsOther': {
        'title': 'Other billing details associated with the account',
        'description': 'You can replace the default billing information by choosing another option from the list',
        'editModalTitle': 'Manage billing information',
        'setAsDefault': 'Make default'
      },
      'billingDetailsSummary': {
        'title': 'Billing details',
        'description': 'These billing details are used to make payments and generate invoices',
      },
      'breadcrumbs': {
        'accountSecurity': 'Account security',
        'accountSettings': 'Account settings',
        'activateUser': 'Activate user',
        'auctions': 'Auctions',
        'billingDetails': 'Billing details',
        'buyWebsite': 'Buy a website',
        'cart': 'My cart',
        'changePassword': 'Change password',
        'cookies': 'Cookies',
        'dns': 'DNS',
        'domainNames': 'Domain names',
        'domainTransfer': 'Domain transfer',
        'faq': 'FAQ',
        'forgotPassword': 'Forgotten password',
        'myAccount': 'My account',
        'news': 'News',
        'payments': 'Payments',
        'privacyPolicy': 'Privacy policy',
        'resetPassword': 'Reset password',
        'sellWebsite': 'Sell a website',
        'status': 'Status',
        'subscribe': 'Subscribe',
        'terms': 'Terms',
        'userAuctions': 'My auctions',
        'userDetails': 'My details',
        'userDomain': 'My domains',
        'userDomainsReserve': 'My domains - reserve',
        'userDomainsRenew': 'My domains - renew',
        'unsubscribe': 'Unsubscribe',
        'whois': 'Whois',
        'paymentResponse': 'Transaction details',
      },
      'carouselCardPromo': {
        'tabOneTitle': 'Great domain names become great brands',
        'tabOneFirstLine': 'Premium domain names become available every day because someone forgot to renew them, or simply couldn’t afford them anymore.',
        'tabOneSecondLine': 'Join the auctions and we’ll put in our best effort to grab them for you.',
        'tabTwoTitle': 'Manage your domains for free! Register. Transfer. Manage DNS.',
        'tabTwoFirstLine': 'Check all your active domain auctions. No payment in advance.',
        'tabTwoSecondLine': 'Free domain management right from your account page.',
        'tabTwoThirdLine': 'Competitive domain registration prices.',
        'tabThreeTitle': 'Simple, fast and secure domain registration',
        'tabThreeFirstLine': 'Hunt, bid for and register domain names like a breeze.',
        'tabThreeSecondLine': 'Let us know if you have any questions or need further information.',
        'tabThreeThirdLine': 'Drop us a line at:'
      },
      'cartItems': {
        'yourItems': 'Your items',
        'taxes': 'Taxes & fees:',
        'delete': 'Delete',
        'voucher': 'Got a voucher?',
        'emptyCart': 'Empty cart',
        'subTotal': 'Subtotal:',
        'total': 'Total',
        'period': 'Period',
        'years': 'year | years',
        'for': 'for',
        'euroSign': '€',
        'textRegister': 'Register new domain',
        'textRenew': 'Renew domain',
        'textReserve': 'Register reserved domain',
        'paymentSummary': 'Payment Summary',
      },
      'cartStepper': {
        'items': 'Cart Items',
        'billing': 'Cart Billing',
        'summary': 'Order Summary',
        'emptyState': {
          'description': 'Once you have added items to your cart, you will be able to see them here.',
          'label': 'Your cart is currently empty',
          'info': 'Select one of the options below to discover your new domain name:',
        }
      },
      'changePasswordView': {
        'title': 'Create a new password for your account',
        'changePasswordInProgress': 'Reset password in progress...',
        'passwordSuccessfullyChanged': 'Account password has been successfully changed!',
        'password': 'Current password',
        'minLengthPassword': 'Please use a password with at least 6 characters',
        'newPassword': 'New password',
        'confirmPassword': 'Confirm new password',
        'enterPasswordAgain': 'Please enter your password again for verification',
        'passwordsMustBeIdentical': 'The specified passwords must be identical',
      },
      'contactView': {
        'title1': 'Contact us',
        'title2': 'Leave us a message',
        'subtitle1': 'How to find us',
        'subtitle2': 'We will answer as soon as possible',
        'name': 'Name',
        'address': 'Address',
        'telephone': 'Telephone',
        'message': 'Your message',
        'submitMessage': 'Submit',
        'confirmation': 'Your message has been successfully sent',
        'enterYourName': 'Please enter your name',
        'invalidName': 'Invalid name, it should be at least 3 characters long',
        'enterEmailAddress': 'Please enter your email address',
        'enterValidEmailAddress': 'Please enter a valid email address',
        'enterText': 'Please enter a message',
        'invalidText': 'Invalid message, it should be at least 2 characters long',
      },
      'cookieBanner': {
        'settingsAndMore': 'Find out more about cookies or change your cookie settings.',
        'cookieInfo': 'This website uses cookies. We use cookies to personalize user experience, content and ads and to do an analysis of our traffic. We also share information about how you use our platform with our social media, advertising and analytics partners who may combine it with other information that you’ve provided to them or that they’ve collected from your use of their services. You consent to our cookies if you continue to use this website.',
        'agree': 'Agree'
      },
      'cookiesView': {
        'title': 'Cookies',
        'description': {
          'subtitle': 'What are cookies?',
          'content1': 'Almost all websites store small amount of information related to your activity on the website. A cookie is a small text file that a website saves on your computer or mobile device when you visit the site.',
          'content2': 'Because we want to offer you the best experience, our website works properly only if you have cookies activated. These let us recognize a user when it returns to our website, and it helps us identify and fix the errors, so that we can provide a better tailored experience for you, improving and optimising the performance as well. Data is stored only with your approval. Example: A cookie can store when you bid and/or use the website marketplace.',
          'content3': 'Cookies can’t break your device and we carefully choose our providers that can set cookies, especially marketing ones.',
        },
        'categories': {
          'title': 'Cookie types on this website: ',
          'content': 'The cookies that are used on this website comply with the guidelines provided by the authorities regarding this type of information. More details below: ',
          'mandatory': {
            'subtitle': 'Category 1: Necessary cookies',
            'content1': 'These cookies are mandatory if you want to navigate the website and use its functionalities - for example the account area. Without cookies, these services that you may want to access / use cannot be reached.',
            'content2': 'This website uses only necessary cookies only which affect pages like the auction page and / or the marketplace.'
          },
          'performance': {
            'subtitle': 'Category 2: Performance cookies',
            'content1': 'These cookies collect user journey related data - e.g. the most visited pages and the errors occurred during the navigation. This information is not stored for identifying a user. All information is aggregated and used to improve website functionality.',
            'content2': 'Cookies from this category: ',
            'listItem1': 'Cookies used by Sentry open-source error tracking software - sentry.io'
          },
          'functional': {
            'subtitle': 'Category 3: Functional cookies',
            'content1': 'These cookies store your preferences for the website (e.g.: user name, region or language) so that we can provide personalised characteristics. For example, a web page could display information related to time zone, as it stores your region from which you access the site.',
            'content2': 'These cookies can be also used to identify changes made by you for a better navigation experience. The text size for example or other technical / UI aspects, which you can personalize. Information collected through these cookies may be anonymous and may not not track your activity on other websites.',
            'content3': 'Particularly, this website uses cookies for some forms in order to retain information entered so that we can transfer the data when you access another form. This type of informations is automatically deteled periodically or at the end of a navigation session.'
          },
          'marketing': {
            'subtitle': 'Category 4: Advertising cookies',
            'content1': 'These cookies are used to ads that are relevant to you. They are also used to impose a limit on the ads frequency but also to measure the effectiveness of the campaign. Ads are usually placed by marketing agencies but only with the permission of the website operator. They remember that you visited a website and this information is shared with other organizations, such as advertising agencies. Too often, targeting or advertising cookies will be linked to the site functionality provided by the other organization.',
            'content2': 'The following cookies from category 4 are used on the website:',
            'listItem1': 'google.com',
            'content3': 'But others as well that you can identify with specialized software - for example, in the latest versions of "Google Chrome" browser you can do the following: Open "Chrome DevTools", navigate to the "Application" tab and analyse the information displayed.',
          }
        },
        'settings': {
          'title': 'Disable cookies',
          'content1': 'You, like any other visitor / user of this website, can decide if you want to have cookies enabled. The website works best with all cookies activated. Your particular cookie settings can be controlled and changed from the browser settings.',
          'content2': 'For further information on changing cookie settings, choose your browser from the following list: ',
          'content3': 'If cookies are disabled on your machine, most of the site’s features will be disabled and we will not be held liable for any adverse effects. Even with all cookies disabled, a small amount of information will continue to be fetched from your browser. This information is required for the basic operation of this website.'
        }
      },
      'domainList': {
        'title': 'Active auctions',
        'loading': 'Auction list is loading...',
        'placeholder': 'The auction list is being updated. Please try again later.',
        'domainName': 'Domain name',
        'auctionStatus': 'Auction status',
        'timeLeft': 'Time left',
        'auctionOnGoing': 'Auction on-going',
        'auctionClosed': 'Auction closed',
        'auctionClosedTimeElapsed': 'Auction closed - time\u00a0elapsed',
        'clickToNavigateToAuction': 'Click to navigate to the auction page',
        'openAuctionInNewTab': 'Open auction in new tab',
        'page': 'Page',
        'of': 'of'
      },
      'domainNamesView': {
        'reserveTitle': 'Reserved Domains',
        'reserveSubtitle': 'Here you can see all of your reserved domains. Click to proceed to registering them.',
        'renewTitle': 'Renew Domains',
        'renewSubtitle': 'Here you can see all of your current domains. Click here to renew them.',
        'seeAll': 'See all domains',
      },
      'domainTransferHowTo': {
        'title': 'How to transfer my domain to Inreg?',
        'step1': {
          'title': 'Verify the domain',
          'content1': 'Enter in the search box above the domain name that you want to transfer.',
          'content2': 'Through this step we make sure that the transfer can be done.'
        },
        'step2': {
          'title': 'Get the transfer key',
          'content1': 'The transfer key (sometimes called authorization key or EPP code) can be obtained from your current registrar.',
          'content2': 'If you encounter any issues, please contact us and we will try to sort out the problem as quickly as possible.'
        },
        'step3': {
          'title': 'Making the transfer',
          'content1': 'Paste or enter the transfer key that you obtained in the previous step in the "Transfer key" input field.',
          'content2': 'The "Transfer key" field is shown after performing the verification from',
          'content3': 'Once the transfer is complete you will be able to directly manage your domain from the "My Account" section.',
          'content4': 'Additionally, you can renew it whenever you want at the reduced price: Only €6.99 per year.'
        }
      },
      'domainTransferPromo': {
        'title': 'Why transfer domains to Inreg?',
        'description1': 'We are committed to keeping in touch with our valued customers so that we can quickly adapt to the most popular requests.',
        'description2': 'In this regard, we allowed users to evaluate at will the advantages considered most important.',
        'description3': 'The benefits listed below are ordered according to the number of votes received.',
        'cards': {
          'price': {
            'title': 'Prices',
            'description': 'Our small prices speak for themselves: It’s only €6.99 / year to renew or register a domain. No hidden taxes or fees.',
            'description2': 'We’ve researched our competition to make sure we’ve priced our services at the lowest price possible.'
          },
          'support': {
            'title': 'First-class support',
            'description': 'Our top-notch support team is a reflection of our customer service oriented company culture.',
            'description2': 'We can say with extreme confidence that this is always high on the list why people move their domains to Inreg.'
          },
          'domainManagement': {
            'title': 'Simple domain management',
            'description': '"My domains" is a tool that provides features like "Self domain management" and "Private nameservers" allowing you manage and configure your domains.',
            'description2': 'Instantly.'
          },
          'noRisk': {
            'title': 'Zero-risk transfer',
            'description': 'You cannot lose your domain name during the transfer.',
            'description2': 'The transfer is secured and the process is closely monitored.'
          },
          'freeTransfer': {
            'title': 'Free transfer',
            'description': 'We don’t charge anything for domain transfers.',
            'description2': 'The process is 100% FREE.'
          },
          'instant': {
            'title': 'Done instantly',
            'description': 'The transfer happens instantly in the case of ".ro" and ".eu" domains.',
            'description2': 'For ".com", ".net", ".org" extensions the transfer takes several days.'
          },
          'noCommissions': {
            'title': 'No hidden fees or charges',
            'description': 'Unlike most domain registrars, we do not apply any hidden fees or charges.',
            'description2': 'You can visit the pricing page to learn more.'
          },
          'noCommitment': {
            'title': 'No long term commitment',
            'description': 'No contractual clause to keep you stuck.',
            'description2': 'If you decide to transfer your domain to another registrar, do so at your own discretion.'
          },
          'serviceAvailability': {
            'title': '100% service availability',
            'description': 'No disruption to your email account.',
            'description2': 'You can safely transfer your domains without no downtime or extra work on your part.'
          }
        },
        'transferBtn': 'Transfer your domain for free'
      },
      'domainTransferView': {
        'title': 'Domain transfer',
        'description': 'Get the best deal on your domain renewal.',
        'info': 'The domain transfer is free',
        'whyTransfer': 'Why transfer:',
        'viewAllAdvantages': 'View all advantages'
      },
      'domainTransferVerification': {
        'transferring': 'Transferring',
        'transferringTo': 'to your Inreg account...',
        'domainTransferred': 'was successfully transferred to your Inreg account.',
        'transferError': 'The domain could not be transferred. Please try again.',
        'transferError2': 'If the problem persists, please contact us at:',
        'viewYourDomains': 'View your domains',
        'transferToInregPre': 'Transfer',
        'transferToInregPost': 'to Inreg',
        'unlock': {
          'title': 'Unlock the domain if necessary',
          'content1': 'How to unlock a domain (might not be required):',
          'content2': 'Log into your account with the current registrar (the company where your domain is currently registered).',
          'content3': 'Locate the domain management dashboard.',
          'content4': 'Find the domain name you want to unlock.',
          'content5': 'If your registrar does not allow you to unlock the domain name yourself, please contact your registrar to have it unlocked.'
        },
        'transfer': {
          'title': 'Enter the transfer key',
          'transferKey': 'Transfer key',
          'transferToMyAccount': 'Transfer to my account',
          'content1': 'How to obtain the transfer key for a „.ro” domain:',
          'content2': '1. Go to',
          'content3': 'rotld.ro',
          'content4': 'and choose the menu option „.ro Domains” > „On-Line Administration”.',
          'content5': 'If you don’t know your domain administration password, look further down on that page for the „Password reset” option.',
          'content6': '2. Login using your domain name and the domain administration password.',
          'content7': '3. Generate the transfer key: After logging in open the „Authorization Keys” dropdown and select „Transfer to another registrar”.',
          'content8': '4. Press the highlighted link - „Click here” - from the paragraph containing the following text: „Click here for obtaining the e-mail containing the Authorization Key required for domain transfer from (...)”.',
          'content9': '5. The transfer key will be sent to you by email shortly.'
        },
        'error': {
          'domainTransferKeyRequired': 'Please enter a authorization code / transfer key',
          'domainTransferKeyMinLength': 'Authorization code / transfer key too short. If you face any issues during this step, please contact us for assistance.'
        }
      },
      'footer': {
        'footerTop': {
          'inregLogoAlt': 'Inreg logo',
          'slogan': 'Helping our customers seize the opportunity since 2018.',
        },
        'chooseLanguage': 'Choose language',
        'general': 'General',
        'services': 'Services',
        'support': 'Support',
        'partners': 'Partners',
        'payment': 'Payment methods',
        'home': 'Home',
        'aboutUs': 'About us',
        'accountPage': 'My account',
        'domainNames': 'Domain names',
        'domainAuctions': 'Domain auctions',
        'domainTransfer': 'Domain transfer',
        'whoisLookup': 'Whois lookup',
        'termsAndConditions': 'Terms and Conditions',
        'privacyPolicy': 'Privacy Policy',
        'cookies': 'Cookies',
        'consumerProtectionEn': 'UK ECC',
        'consumerProtectionLinkEn': 'ukecc.net',
        'consumerProtectionRo': 'ANPC',
        'consumerProtectionLinkRo': 'anpc.gov.ro',
        'copyrightInfo': 'Copyright © 2018 - 2021 Inreg Solutions SRL. All Rights Reserved. The Inreg word mark is a registered trademark of Inreg Solutions SRL in the UK and other countries. The "inreg" and "ir" logo are registered trademarks of Inreg Solutions SRL.',
        'tosInfoPre': 'By accessing, viewing and using this Site, you agree to be legally bound by the',
        'tosInfoText': 'Terms and Conditions',
        'tosInfoPost': 'and all other terms, conditions policies and notices contained or referenced in the Site. This Site is owned and operated by Inreg Solutions SRL.'
      },
      'forgotPasswordView': {
        'title': 'Create a new password',
        'forgotPasswordEmailSent': 'We’ve sent a link to',
        'accessforgotPasswordLink': 'Follow these instructions to reset your password.',
        'navigateToMainPage': 'Navigate to main page:',
        'mainPage': 'Main page',
        'info': 'Please enter your email address and we’ll send you a link to set your new password.',
        'cancel': 'Cancel',
        'ifIssuesEncountered': 'If you have encounter any issues please contact us:',
      },
      'helpView': {
        'title': 'Help and further information',
        'subtitle1': 'Frequently asked questions (FAQ)',
        'subtitle2': 'Bills and payments',
        'subtitle3': 'Domain auctions / domain backorder (drop catch)',
        'subtitle4': 'About DNS',
        'faq': {
          'question1': 'What is a web domain?',
          'answer1': 'Simply stated, a domain name (or just a domain) is basically used to identify one or more IP addresses. It is what comes after "@" in an email address, or after "www.". If someone asks about your website, what you tell them is your domain name. All domain names follow this pattern: name.extension (e.g.: myname.com)',
          'question2': 'Who controls and regulates registration of ".ro" domains?',
          'answer2': 'The ".ro" domains are regulated and overseen by the "National Institute for Research and Development in Informatics - ICI Bucharest", as the "RoTLD Registry.',
          'question3': 'What is a RoTLD accredited partner?',
          'answer3_1': 'RoTLD seeks to develop a competitive market so it partners with private companies for the ".ro" domains registration, management or maintenance services.',
          'answer3_2': 'Inreg Solutions SRL is RoTLD accredited partner since 2018. The full list of authorized partners can be found on the RoTLD’s website here:',
          'question4': 'What does "TLD" mean?',
          'answer4': 'TLD = Top Level Domain.',
          'question5': 'Right of use for a ".ro" domain name',
          'answer5': 'By registering a domain you acquire the right to use the domain name for a defined period of time. Owning the right to use the domain must be in accordance with the RoTLD Terms and Conditions and the domains registered, reserved or auctioned through Inreg.ro platform is subject to the Terms and Conditions document accessible on this website.',
          'question6': 'Domains having "PendingDelete" status',
          'answer6': 'The domains for which the annual fee has not been paid are programmed to be deleted and their status is changed to "PendingDelete". You have 90 days to pay the annual fee after the domain name has been scheduled for deletion.',
          'question7': 'The deletion of a domain for which the annual fee has not been paid',
          'answer7': 'Every day a large number of domains that are not renewed will be available for registration again after 90 days from the expiry date. In this time span of 90 days the latest owner is the only one that can pay the annual fee. If the owner still does not renew the right of use during this period then the domain will be deleted. Once a domain has been deleted it becomes available for reservation or registration according to the "first come, first served" rule.',
          'question8': 'Are any technical limitations or constraints when registering a domain name?',
          'answer8_1': 'The answer is yes. The following technical restrictions apply:',
          'answer8_2': '• The domain must contain at least one character and a maximum of 63 characters',
          'answer8_3': '• The domain name should consists of Latin (Aa-Zz), Arabic numerals (0-9) or both. Dash "-" character can also be used as long as it is not at the beginning, at the end of the domain name or consecutive ("--");',
          'question9': 'How long does it take to reserve or register a domain name?',
          'answer9': 'A domain name is only registered after the payment is confirmed. Duration may vary depending on the payment method you choose (card payment, bank transfer, etc.).',
          'question10': 'Paying the annual registration fee',
          'answer10': 'Owning the right of use for a domain name requires the payment of an annual registration fee. The fee is payable before the expiration date and may be paid in advance for a maximum of 10 years.',
        },
        'payments': {
          'subtitle': 'Payment',
          'content1': 'Payment methods:',
          'listItem1': '• Online payment',
          'listItem2': '• Wire transfer',
          'listItem3': '• Cash deposit',
          'content2': 'For wire transfer and cash deposits, please specify the invoice number for a faster order processing.',
          'content3': 'The payment is due in 3 days after an item (or service) is purchased. We will not be held responsible if a domain that is in grace period or "PendingDelete" status is deleted before the fee is paid even if the invoice has not reached the due limit.',
          'content4': 'The tax invoice will be issued after the payment is confirmed and it is usually sent through email or downloaded from the account page.',
          'content5': 'Prices can be denominated either in Euro or Ron. We are using the official exchange rate listed on "National Bank of Romania"’s website and is updated each day.',
          'content6': 'All prices listed on our site exclude any applicable VAT / GST.',
          'listItem4': 'Inreg Solutions S.R.L.',
          'listItem5': 'Tax Identification Number: 40281673',
          'listItem6': 'IBAN RON: RO54BRDE030SV96209200300',
          'listItem7': 'IBAN EUR: RO52BRDE030SV97120070300',
          'listItem8': 'SWIFT: BRDEROBU',
          'listItem9': 'Bank: BRD - Groupe Société Générale',
        },
        'auctions': {
          'auctions0': {
            'content1': 'A lot of domains expire (are deleted). The domain backordering (or dropcatch) is a service that helps you attempt to acquire a domain name when it becomes available for registration.',
            'content2': 'Anyone can join the auction but only the user that won the auction will be considered for the domain name reserved / registered.',
            'content3': 'Winning an auction does not fully guarantee that we will acquire the domain name for you. Many variables affect the domain backordering process. For example the current registrant might renew the domain name, or the attempt to register it for you might be unsuccessful.',
          },
          'auctions1': {
            'subtitle': 'Introduction (".ro" domains)',
            'content1': 'Starting with 30th of August 2019, the ".ro" domains Registry began deleting domains for which the annual fee has not been paid. The domain names for which the annual fee is not paid before the expiration date will scheduled for automatic deletion (PendingDelete). The deletion is performed after 90 days from the expiration date if during this period the owner does not renew the right of use by paying the annual fee.',
          },
          'auctions2': {
            'subtitle': 'Auctions',
            'content1': 'First step is to check the status of the domain you are interested in. You can do this taking advantage of the WHOIS service of by checking daily the lists of domains that are scheduled for deletion.',
            'content2': 'The domain "capturing" is called Dropcatch (or Backordering). If there is more than one person that is interested in a doain name scheduled for deletion and the auction is not private, then a public auction will be organised for that domain name and the user with the highest bid will benefit from the Backordering services.',
          },
          'auctions3': {
            'subtitle': 'General rules: ',
            'listItem1': '• We can not guarantee the reservation / registration of the domain name, even if you win the auction',
            'listItem2': '• If we successfully reserve / register the domain name you auctioned for, paying the amount that was bid becomes mandatory',
            'listItem3': '• Minimum bid is €15',
            'listItem4': '• The minimum bid (of €15) can be withdrawn maximum 30 minutes before the auction closes. If you place a bid over €15 you will not be able withdraw from the auction',
            'listItem5': '• In case of fraud or due to other security reasons the highest bid is invalidated, then your bid may become the highest even if it was not intially',
            'listItem6': '• If two equal bids are placed, only the oldest of them will be taken into consideration',
            'listItem7': '• You are not allowed to lower the bid, you can only raise it',
            'listItem8': '• Highest bid is not publicly displayed but you can still see your current bid and if it is the biggest one',
            'listItem9': '• Auction closes at 21:00 GMT+1 (also known as UK time) if the highest bid was placed with ar least 5 minutes before the closing time',
            'listItem10': '• If the highest bid was placed during the last 5 minute, the auction is automatically extended',
            'listItem11': '• If you win the auction and we successfully reserve / register the domain name, you will have to pay the amount that you bid + the annual registration fee for that domain',
          },
          'auctions4': {
            'subtitle': 'Extending the auction',
            'content1': 'The auction is extended if a user has placed the highest offer in the last 5 minutes, guaranteeing all participants the possibility of making a counterbid. The auction can be extended up to a maximum of 45 minutes.',
            'content2': 'If extended until 21:45 GMT+1 (also known as UK time), it is considered that the extension limit has been reached and the auction closes.',
            'content3': 'The user is allowed to bid in the extended period only if he submitted and offer before 21:00 GMT+1.',
            'content4': 'Every time the auction is extended, the minimum amount for the bidding step (€5) modifies.',
          },
          'auctions5': {
            'subtitle': 'Backordering (or Dropcatch)',
            'content1': 'We do not guarantee that we can capture the domain name. There are other companies like us and the chances are shared.',
            'content2': 'The resources assigned for the Dropcatch service are often proportional with the amount that was bid. For instance, if you are the winner of an auction where you bid €300, you will have greater chances than a winner of an auction where the highest bid was €100.',
          },
          'auctions6': {
            'subtitle': 'Payment',
            'content1': 'If we successfully capture the domain name for you, an invoice that you have to pay within 72 hours will be automatically sent to your email. The registration and billing details will be the ones you selected. Please make sure that valid details are provided, as they cannot be modified for the most part.',
          },
          'auctions7': {
            'subtitle': 'Overdue payments',
            'content1': 'If the invoice is not paid after 72 hours since it was issued, the domain name reservation may be lost and the right to bid will be suspended until all the invoices are paid. If payment is not made, your account will be permanently blocked and you will be charged penalties.',
          },
          'auctions8': {
            'subtitle': 'Other info',
            'content1': 'We hereby notify you that we reserve the right to unilaterally decline or delete a bid, or assign a domain name.',
          },
        },
        'dns': {
          'dns1': {
            'subtitle': 'What is a nameserver / DNS?',
            'content1': 'A nameserver is a mapping between a device / server address (IP address) and a name assigned to that device / server. Every device connected to the internet has a unique IP address and all of these different addresses are embedded in a system called "Domain Name System" or simply put, DNS.',
            'content2': 'Think of the DNS as the mapping between a postcode and an address or think about a phonebook (phone number -> name of a person). The DNS contains hundreds of millions or even billions of such addresses.',
          },
          'dns2': {
            'subtitle': 'How does DNS work?',
            'content1': 'When a user visits a website, DNS is queried for the IP address corresponding to that particular domain, so the browser can contact the Web server to fetch the content. IP addresses allow machines to communicate with each other. On the contrary, people are more likely memorise names rather than numbers (a web domain name, e.g.: inreg.ro).',
          },
          'dns3': {
            'subtitle': 'How long do DNS changes take?',
            'content1': 'DNS changes for a domain are recorded almost instantly in the database but it is not possible to give an accurate estimation on how long it takes until the DNS propagation is fully completed because at a geographic location it could be finished while in another place might still be processing. On average, full propagation takes between 24-48 hours.',
            'content2': 'DNS propagation time is typically influenced by: the period for which DNS servers store information (TTL or Time To Leave / Hop Limit), the Internet Service Provider (ISP) and the Domain Name Registry.',
            'content3': 'You may be able to visualise all of the old content of a website even after a complete DNS update, as web browsers often retain information in a temporary memory (cache). Emptying (or clearing) the cache should instantly resolve this and bring the new content on a page reload.',
          },
        },
      },
      'homepageCards': {
        'firstCard': {
            'title': 'Great domain names become great brands',
            'description': 'Premium domain names become available every day because someone forgot to renew them, or simply couldn’t afford them anymore. Join the auctions and we’ll put in our best effort to grab them for you.',
        },
        'secondCard': {
            'title': 'Manage your domains for free! Register. Transfer. Manage DNS.',
            'description': 'Check all your active domain auctions. No payment in advance. Free domain management right from your account page.',
        },
        'thirdCard': {
            'title': 'Simple, fast and secure domain registration',
            'description': 'Hunt, bid for and register domain names like a breeze. Let us know if you have any questions or need further information. Drop us a line at: contact@inreg.ro',
        }
      },
      'homeView': {
        'auctionCarouselCardTitle': 'Top active auctions',
        'saleCarouselCardTitle': 'Websites for sale',
        'searchBlockMain': 'Find your perfect domain',
        'searchBlockPlaceholderText': 'Domain name...',
      },
      'infoHeader': {
        'serviceStatus': 'service status',
      },
      'minicart': {
        'cartInfo': 'Your cart is empty',
        'cartDetails': 'Cart details',
        'delete': 'Domain deleted from cart',
        'domain': 'domain',
        'subTotal': 'Subtotal:',
        'total': 'Total:',
        'textRegister': 'Register new domain',
        'textRenew': 'Renew domain',
        'textReserve': 'Register reserved domain',
        'checkout': 'Checkout',
      },
      'minicartEmptyState': {
        'label': 'Your cart is currently empty',
        'description': 'Select one of the options below to discover your new domain name:',
        'domainSearch': 'Domain search',
        'domainAuctions': 'Domain auctions',
      },
      'myAccountView': {
        'title': 'My account',
        'overview': 'Overview',
        'accountDetails': 'Account details',
        'billingDetails': 'Billing details',
        'myAuctions': 'My auctions',
        'myDomains': 'My domains',
        'accountSecurity': 'Account security',
        'accountSettings': 'Account Settings',
      },
      'myAccountOverviewView': {
        'editUserDetails': 'Edit the user details used for domain capture and register. Phone validation.',
        'editBillingDetails': 'Edit your billing details.',
        'activeAuctionsLatestBids': 'Your active auctions and latest bids.',
        'changeDnsReviewDomain': 'Change nameservers or review your domains.',
        'reviewAdjustSecurity': 'Review and adjust your security settings.',
      },
      'myAccountCardNavList': {
        'quickAccess': 'Quick access',
        'frequentlyAccessedSettings': 'Frequently accessed settings:',
        'addEditUserDetails': 'Add or edit user details used for domain capture and registration',
        'chooseDefaultDetails': 'Choose the default domain registration details',
        'addEditBillingDetails': 'Add or edit your billing details',
        'showActiveAuctions': 'Show all active auctions in which you participate',
        'manageNameservers': 'Manage domain nameservers',
        'goToDomains': 'Go to your domains',
        'phoneValidation': 'Phone validation',
        'changeDefaultLanguage': 'Change default language',
      },
      'navigationHeader': {
        'home': 'Home',
        'domainNames': 'Domain Names',
        'domainAuctions': 'Domain Auctions',
        'buyWebsite': 'Buy Websites',
        'sellWebsite': 'Sell Websites',
        'whoisLookup': 'Whois Lookup',
        'myAccount': 'My Account',
      },
      'newsletter': {
        'weekly': 'Weekly',
        'everyOtherWeek': 'Every other week',
        'monthly': 'Monthly',
        'unsubscribe': 'Unsubscribe',
        'subscribe': 'Subscribe',
        'confirmation': 'Your preferences have been successfully updated!',
        'changePreferences': 'Change preferences',
        'pleaseChoose': 'Please choose an option',
        'email': 'Email address *',
      },
      'paymentResponse': {
        'result': {
          'fail': 'Payment Failed!',
          'success': 'Payment Successful!',
        },
        'paymentResponseTitle': 'Thank you for your payment',
        'paymentResponSubtitle': 'A confirmation email has been sent to your registered email address. Please check your inbox to review the details of your transaction.',
        'domainRegisterSuccess': 'Your domain was successfully registered.',
        'paymentFailedRetry': 'The payment has not been successfully processed. Please initiate the payment process again.',
      },
      'phoneVerification': {
        'connectionError': 'Connection error',
        'pageCouldNotLoad': 'Data could not be retrieved, check your internet connection. If the problem persists, please contact us at:',
        'phoneVerified': 'Phone number - verified',
        'phoneVerificationDetails': 'The phone number is used to verify your identity but also to secure the account',
        'confirmPhone': 'Confirm your phone number',
        'retryCode': 'Resend activation code',
        'changePhone': 'Change phone number',
        'retryCodeConfirmation': 'A new activation code was successfully sent to: ',
        'sendActivationCode': 'Send activation code',
        'smsSent': 'The SMS containing the activation code was successfully sent to:',
        'enterActivationCode': 'Enter the activation code',
        'pleaseEnterActivationCode': 'Please enter the activation code',
        'confirmNumber': 'Confirm your number',
        'numberSuccessfullyConfirmed': 'Your phone number has been successfully confirmed!',
        'goToAuctions': 'Go to auctions',
      },
      'privacyPolicyView': {
        'title': 'Privacy Policy',
        'summary': {
          'subtitle': 'Key Summary',
          'content1': 'We process your personal information only as it might be needed for us to provide you with products and services of outstanding quality, websites, mobile apps (collectively, our "Services"). We also collect and use personal data for the following purposes: to respond to general enquiries and requests sent to us, marketing analytics and personalized communications and direct marketing.',
          'content2': 'As part of our processing for these purposes, we share your information with suppliers who act on our behalf for services such as IT support, website support, sales and marketing services.',
          'content3': 'This Policy is intended to explain you what data we process, why and how we use your personal data and your rights.',
          'content4': 'If at any time you have questions about our Services you may reach our Data Protection Officer – “DPO” – and our dedicated team that supports this office by contacting us at contact@inreg.ro / contact@inreg.co.uk. This inbox is monitored and actively managed because your privacy protection is not only important for you but very important to us as well.',
        },
        'about': {
          'subtitle': 'About Inreg and this Privacy Policy',
          'content1': 'This Privacy Policy is provided by Inreg Solutions SRL ("Inreg", "Inreg Solution", "we" or "us") who is a controller in respect of your personal data for the purposes of the General Data Protection Regulation (EU) 2016/679 and the Data Protection Act 2018 (once enacted)  (collectively referred to as the "Data Protection Laws"). Keeping your data safe is important to Inreg and this Privacy Policy explains what personal data we process, why we hold the data, what legal basis we rely on and what your rights are. Please take the time to read it carefully.',
        },
        'what': {
          'subtitle': 'What information do we collect?',
          'content1': 'We aim to provide a better, tailored experience for you, looking to improve and optimize the performance as well. Personal data is collected in this regard directly from you when you:',
          'listItem1': '(i) register and fill in your data in "My Account" section or purchase any of our Services;',
          'listItem2': '(ii) complete contact forms or request updates, newsletters, personalized communications or participate in contest or surveys, apply for an open position in our company, or otherwise participate in activities which may need your personal information;',
          'listItem3': '(iii) request assistance / support.',
          'content2': 'Your personal data includes information such as:',
          'listItem4': 'Contact details – Includes your full name, address or other contact information including telephone and e-mail address details but other data collected that could directly or indirectly identify you.',
          'listItem5': 'Website, electronic communication and app device usage – We will collect information such as if you open or engage with our e-mail communications or if you use any Inreg platforms, online brochures. We will collect usage and online behavior information through cookies, your IP address or other tracking technologies such as pages visited, where you joined our site from, the path you take through our sites and when / where you leave, browser, type of device, how long you visited for including collecting your mobile device location details (if permitted within the App settings), links clicked, items or services purchased, various data including analytics and other types of interaction with our Services. You can read more about the cookie policies on inreg.co.uk/cookies or inreg.ro/cookies.',
          'content3': 'Not all the methods of collection may be obvious for you and they may vary from time to time therefore we will highlight and explain what these might be.',
          'content4': 'General account related data – account identifier, purchases, dates at which product expire or are renewed or similar information sent or requested.',
          'content5': 'Cookies and tracking technologies – as stated below we make use of cookies & analytics, please visit inreg.co.uk/cookies or inreg.ro/cookies for further reading on this matter.',
          'content6': 'Data about Usage of Services – this type of data is usually automatically collected. This includes activity log, metadata, page load time and performance parameters, approximate location to improve our Services (DNS resolution optimization, routing, network and server configuration), language preferences, information on browser configuration and plugins and cookie data. Unique device identifiers and error data might be also collected.',
          'content7': 'Additional Supplemented Data might be requested from other sources and it may include information about you. This includes publicly available databases or third parties from whom we gathered or purchased data. For us to provide a better experience for you, this information may be combined with the already existing data we collected and stored. The main purpose of this practice is improving the accuracy of our records, identify new potential customers and provide tailored products and services. If you provide us personal information about other entities or about you, we will use that information only for the specific purpose you provided that.',
        },
        'how': {
          'subtitle': 'How do we use your information and lawful basis for processing.',
          'content1': 'We are aiming to keep the amount of data we process about you to a minimum and also aiming to limit its use and purpose to only the data we have been given permission; to process only the necessary in order to properly deliver Services; or, as we might be required for legal compliance or other lawful purposes. To do so, we have considered the impact on your interests and rights, and have placed appropriate safeguards to ensure that the intrusion on your privacy is reduced as much as possible.',
          'content2': 'We use your personal data for the following purposes and we are allowed to do so, on certain legal bases which are outlined below:',
          'listItem1': '•	Services usage / customer requests – To operate and improve our Services and fulfil requests you submit to us such as registering a domain name, buying a hosting plan, marketing request or other type of request through e-mail, Inreg web platform or app.',
          'listItem2': '•	Support and problem diagnose – Identify any security risks, errors or enhancements that might be required from the Services.',
          'listItem3': '•	Detect and prevent abuse of our systems.',
          'listItem4': '•	Statistics collection and aggregation of analytics.',
          'listItem5': '•	Understanding the usage of our Services by you and our other customers so that we provide relevant recommendations.',
        },
        'thirdParties': {
          'subtitle': 'Third parties sharing',
          'content1': 'Your personal data may be shared with affiliate companies, partners and trusted third parties service providers as needed for them to provide services on our behalf (e.g. customer relationship management, payment processing – including credit card payments, Ad services, surveys, statistics, contests, customers demographics, communication).',
          'content2': 'In the case of a merger, acquisition or sale of all or substantially all of our assets we may share your information with the new entity or other third parties for the purpose of facilitating this. The acquiring company / entity may continue to process and utilize your personal data in the manner for which it was provided.',
        },
        'communication': {
          'subtitle': 'Communication channels',
          'content1': 'Communication channels may include e-mail, text messages, phone calls (including automated ones).',
          'content2': 'You may update your subscription preferences with respect to receiving communications from us and / or our partners by contacting us at contact@inreg.ro / contact@inreg.co.uk.',
          'content3': 'If you consider that your personal information was provided to us and you would like to remove it from our database, please send a request at contact@inreg.ro / contact@inreg.co.uk.',
          'content4': 'If you make a request to delete your personal data and that data is necessary for the products or services you have purchased, the request will have effect only to the extent the data is no longer necessary for any Services purchased or required for our legitimate business purposes or legal or contractual record keeping requirements.',
        },
        'transfer': {
          'subtitle': 'Transfer of personal data abroad',
          'content1': 'If you utilize our Services from a country other than the country where our systems are located, your communications with us may result in transferring your personal data across international borders. If you call / contact us by text, chat or e-mail, we may provide you with support from one of our global locations outside your country of origin. Your personal data is handled according to this Privacy Policy anyway.',
        },
        'regulatory': {
          'subtitle': 'Compliance with legal, regulatory and law enforcement requests',
          'content1': 'We cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We will disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process, to protect our property and rights or the property and rights of a third party, to protect the safety of the public or any person, or to prevent or stop activity we consider to be illegal or unethical.',
          'content2': 'We  will take reasonable steps to inform you in the event that we receive a request to provide your personal information to third parties as part of legal process to the extent we are legally permitted to do so. We will share your data as necessary to comply with ICANN or any other ccTLD rules, regulations and policies. For reasons critical to maintaining the security, stability and resiliency of the Internet, this includes the transfer of domain name registration information to the underlying domain registry operator and escrow provider, and publication of that information as required by ICANN in the public WHOIS database.',
        },
        'thirdPartyWebsites': {
          'subtitle': 'Third-party websites',
          'content1': 'Our website and our mobile applications contain links to third-party websites. We are not responsible for the privacy practices or the content of third-party sites. Please read the privacy policy of any website you visit.',
        },
        'duration': {
          'subtitle': 'How long do we keep your information for?',
          'content1': 'We enforce generally accepted standards to secure the personal data we collect starting from the moment when the data is collected up to the moment it is processed, received and stored. Data processing is subject to encryption where appropriate.',
          'content2': 'We are storing website data against unique ID’s and this data is retained only for as long as necessary to provide the Services you have requested and thereafter for a variety of legitimate legal or business purposes. These might include retention periods mandated by law, contract or similar obligations applicable to our business, or needed to manage proper and accurate business and financial records, or for preserving, resolving, defending or enforcing our legal/contractual rights and to ensure we are able to deal with any queries you may have on the Services or to support any legal claims process - we may hold this data for a indefinite period, if we are legally required to do so.',
        },
        'age': {
          'subtitle': 'Age restrictions',
          'content1': 'Our Services are designated for purchase only for those over the age of 18. Our Services are not targeted to, intended to be consumed by or designed to entice individuals under the age of 18. If you know of or have reason to believe anyone under the age of 18 has provided us with any personal data, please contact us at contact@inreg.ro / contact@inreg.co.uk.',
        },
        'rights': {
          'subtitle': 'Your rights',
          'content1': 'Under the Data Protection Laws you have the following rights in relation to your personal data:',
          'listItem1': '•	Right to object to processing of your personal data',
          'listItem2': '•	Right to request a copy of your personal data and information on how we process it',
          'listItem3': '•	Right to request to move, copy or transfer your personal data (data portability)',
          'listItem4': '•	Right for your personal data to be erased',
          'listItem5': '•	Right for your personal data to be corrected in a timely manner',
          'listItem6': '•	Right to ask us to stop contacting you with direct marketing',
          'listItem7': '•	Right to restrict your personal data being processed',
          'content2': 'To exercise your rights please contact the Inreg Data Protection Officer using the contact details provided in this document. We will ask you for information to confirm your identity and we will then respond to your request within 30 days from when we have confirmed your identity.',
        },
        'changes': {
          'subtitle': 'Changes to Privacy Policy',
          'content1': 'As Inreg develops new products and services, and new uses of information occur, we review and update our privacy policy to reflect these changes. In addition, from time to time we make organizational, stylistic and grammatical changes to present our practices in a way that makes our policy easy to read. We reserve the right to change this Privacy Policy at any time. If we decide to modify it, we will post those changes to this Privacy Policy and any other places we deem appropriate, so that you are aware of what information we collect, how we use it, and under what circumstances we might disclose it. We will notify you about the changes here, by email, or by means of a notice on our platforms, at least thirty (30) days prior to the implementation of the changes.',
        },
        'contact': {
          'subtitle': 'How to contact us',
          'content1': 'If you would like to get in touch with Inreg, to amend any personal details, amend any marketing preferences, ask privacy-related questions or for any other queries, please contact our Data Protection Officer as below:',
          'listItem1': '•	By e-mail: contact@inreg.ro / contact@inreg.co.uk',
          'listItem2': '•	By mail: Office DPO, 47 Fabricii Street, building H, floor 1, suite 3, sector 6, 060822, Bucharest, Romania',
          'listItem3': '•	By phone: (+40) 740.457.486',
          'content2': 'We will respond to requests, inquiries or concerns within thirty (30) days as stated above.',
        },
        'copyright': {
          'content1': 'Copyright © 2020 Inreg Solutions, SRL. All Rights Reserved.',
        },
      },
      'promoBannerBlock': {
        'title': 'Voucher',
        'dialogContent': 'You can use the voucher Lorem Ipsum. Terms and Conditions may apply.',
        'description': '30% discount voucher. ',
        'codeLabel': 'Use the code: '
      },
      'resetPasswordView': {
        'title': 'Create a new password',
        'resetPasswordInProgress': 'Reset password in progress...',
        'passwordSuccessfullyChanged': 'Account password has been successfully changed!',
        'confirmationEmailSent': 'We’ve sent you a confirmation email.',
        'mainPage': 'Main page',
        'myAccount': 'My account',
        'password': 'Password',
        'minLengthPassword': 'Please use a password that has at least 6 characters',
        'confirmPassword': 'Confirm password',
        'enterPasswordAgain': 'Please enter your password again',
        'passwordsMustBeIdentical': 'The passwords specified must be identical'
      },
      'searchBlockHeader': {
        'findDomains': 'Start your domain search',
        'snackbarError': 'You can not search more than 3 domains at once',
        'snackbarErrorEmptyDomainNames': 'This field is required, please enter a domain name',
        'emptyDomainNamesError': 'Enter a domain in this field',
        'domainTransfer': 'transfer a domain',
        'toInreg': 'to Inreg'
      },
      'searchedDomains': {
        'domainAvailable': 'Domain available',
        'domainUnavailable': 'This domain is not available right now',
        'isAvailable': ' is available',
        'isUnavailable': ' is unavailable',
        'addBtn': 'Add all selected domains to the cart',
        'learnMoreBtn': 'Learn more',
        'makeOfferBtn': 'Make offer',
        'viewBtn': 'View in cart',
        'confirmation': 'Domain added in cart',
        'youSearchedFor': 'You searched for:',
        'similarDomains': 'Similar domains:',
        'year': 'year',
      },
      'secureLock': {
        'text': 'is secure'
      },
      'securedWith': {
        'securedWith': 'Secured with',
        'letsEncryptAlt': 'Let\'s Encrypt logo',
        'cloudflareAlt': 'Cloudflare logo'
      },
      'shortcutMenu': {
        'cartItems': 'My cart',
        'contactUs': 'Contact Us',
        'contactUsMain': 'If you have encountered problems while using the website please let us know, and give as many details as possible so that we can quickly identify and fix the root cause of the problem.',
        'blog': 'Blog',
        'myAccount': 'My Account',
        'serviceStatus': 'service status',
      },
      'signInView': {
        'title': 'Sign In',
        'password': 'Password',
        'goToSignUp': 'Create an Inreg account',
        'forgotPassword': 'Forgot your password?',
        'personalDataPrivacy': 'We treat your personal data with care.',
        'personalDataPrivacyLabelBefore': 'Please find our',
        'privacyPolicyLinkText': 'Privacy Policy',
        'personalDataPrivacyLabelAfter': 'here.'
      },
      'signUpView': {
        'title': 'Create an account',
        'activationEmailSent': 'An activation email has been sent out to',
        'accessActivationLink': 'Please check your email and click on the link to activate your account.',
        'navigateToMainPage': 'Navigate to main page:',
        'mainPage': 'Main page',
        'congratsSuccessfullyRegistered': 'Congratulations! You have successfully registered.',
        'password': 'Password',
        'minLengthPassword': 'Please use a password that has at least 6 characters',
        'confirmPassword': 'Confirm password',
        'enterPasswordAgain': 'Please enter your password again',
        'passwordsMustBeIdentical': 'The passwords specified must be identical',
        'newsletterConsent': 'I want to receive personalized offers, updates, and information about Inreg promotions, products and services.',
        'createAccountBtn': 'Create Account',
        'userSaved': 'The user',
        'userSaved2': 'was successfully registered.',
        'goToSignIn': 'Already have an account? Sign in'
      },
      'similarDomains': {
        'loading': 'Loading domain list...',
        'noUserDomains': 'We couldn’t find any domain associated with this account',
        'addBtn': 'Add to cart',
        'labels': {
          'expireDate': 'Expiration date',
          'name': 'Domain name',
          'nameservers': 'Nameservers',
          'privateNameservers': 'Private nameservers',
          'domainStatus': 'Domain status',
        },
        'error': {
          'connectionError': 'Data could not be retrieved, check your internet connection. If the problem persists, please contact us at:'
        },
      },
      'snackbar': {
        'copied': 'Copied',
        'emailAddress': 'email address',
        'phoneNumber': 'phone number',
        'toClipboard': 'to clipboard!',
        'congratulations': 'Congratulations! Your account was successfully activated.',
      },
      'termsAndConditions': {
        'privacyPolicy': 'Privacy Policy',
        'termsAndConditions': 'Terms and Conditions',
        'usageConsent': 'Using the site as a visitor, registering as a user (organization), signing up for the newsletter, and accessing its services implies that you have read, understood and accepted the',
        'usageConsent2': 'as well as the',
        'usageConsent3': ' in full.'
      },
      'termsView': {
        'title': 'Terms and Conditions - Inreg',
        'content': {
          'content0': 'By performing online orders, making payments and using the services provided by Inreg Solutions SRL you confirm that you have read the Terms and Conditions and you agree to all terms and conditions as well as any rules or policies published by Inreg Solutions SRL, Nominet UK – nominet.uk (applicable for domain names with ".co.uk" and .uk TLDs) and ICI – RoTLD - rotld.ro (applicable for domain names with ".ro" TLD).',
          'content0a': 'These Terms and Conditions will apply to all transactions and services provided by Inreg Solutions SRL and to the partner companies or affiliates of Inreg Solutions SRL, including the future ones.',
          'content0aBIS': 'The details of the company. Name: Inreg Solutions SRL, VAT ID: RO 40281673, Registration No.: J40/17616/2018, Address: 47 Fabricii Street, building H, floor 1, suite 3, sector 6, 060822, Bucharest, Romania.',
          'content0b': 'Orders, order confirmations and cancellations, including any changes will be made in writing.',
          'content0c': 'Inreg Solutions SRL reserves the right to subcontract its services to third parties.',
          'content0d': 'In case of an early termination of the agreement by the Beneficiary, Inreg Solutions SRL will be entitled to a reasonable compensation of its services.',
          'content0e': 'The copyright and ownership rights of all documents provided to the other Party will remain with the sending Party.',
          'subtitle1': 'Choosing the domain name and / or the service package',
          'content1': 'The decision to choose the domain name that is attached to any service or service package belongs entirely to you and the responsibility for the verification is yours exclusively. It is not our responsibility to verify whether any damage is caused by the reservation, registration or renewal of the right to use a domain. It is not our responsibility if the use of the domain / service, directly or indirectly, violates the legal rights of a party and / or third party or if the domain name used violates the legal rights of other parties. Consequently, it is your responsibility to verify that the selected name or use violates the legal rights of other parties. You are informed and you agree that in case of a motivated request from a court and / or other institution we will cancel, modify or transfer the domain and it is your responsibility to write an accurate list of contact information and to communicate with the litigants, possible litigants and government authorities. It is not our responsibility to make you aware of court rulings and / or other communications of this kind.',
          'subtitle2': 'Compensation',
          'content2': 'Inreg Solutions SRL is relieved of liability for any claim for damages, administrative or criminal sanction, civil claims, etc., in respect of your infringement of an intellectual property right or other rights of a third party, or resulting from the violation of any operating rules or policies related to the services provided. You are liable for the immediate and unconditional indemnification of Inreg Solution SRL and all parties involved for any damage suffered as a result of such demands, sanctions or claims, for all complaints, damages, risks, costs and legal fees that are the direct or indirect consequence of the registration or use of your reserved or registered domain if it is badly or  illegaly used or in case of violation of this agreement by you. Inreg Solutions SRL will be entitled to claim and to be fully compensated for the damages suffered in connection with your domain name.',
          'subtitle3': 'Payment',
          'content3': 'You agree to pay the applicable fees for the services provident by Inreg Solutions SRL All fees are non-refundable unless we specify otherwise.',
          'subtitle4': 'Expiration and renewal of services',
          'content4': 'Keeping track of the expiration date of your domain or other services is your responsibility. However, we may send you a notification via email when your domain is due to expire.',
          'subtitle5': 'Domain transfer',
          'content5': 'You acknowledge and agree that the transfer and transfer services of the domains are governed by the Nominet UK’s policies / terms and conditions, for ".co.uk" and ".uk" domains, and by RoTLD’s policies / terms and conditions, for ".ro" domains. You should keep in mind that Nominet’s and RoTLD policies / terms and conditions may change occasionally. Read more about this on the nominet.uk website for ".co.uk" and ".uk" domains and on rotld.ro website for ".ro" domains. Sometimes there may be errors in the registration request, so you must be aware that all the risks for the failure of a transfer fall within your responsibility.',
          'subtitle6': 'Privacy policy',
          'content6': 'You acknowledge and agree that we will send the information you have provided us to Nominet UK and / or RoTLD or any other third parties. You hereby irrevocably give up all claims and causes of action that you may request. You are also aware that Nominet UK, RoTLD or any other third party may modify the requirements or information that you need to provide us but also how they are made available to them or other third parties. More information can be found at nominet.uk, rotld.ro and on our website: inreg.ro/privacy-policy.',
          'subtitle7': 'Confidentiality',
          'content7': 'Both you and us agree to not use the informations provided for other purposes than those agreed upon and not to disclose the confidential informations to another parties. This clause does not apply to informations that we are permitted to disclose under this agreement, informations disclosed to employees, suppliers or others for proper compliance with the convention, informations required by the applicable regulation or authority, informations entering the public domain and informations required to provide the services.',
          'content7a': 'Neither Parties shall disclose or use any commercial or technical information received from the other Party, to the extent that the information is not public.',
          'content7b': 'Both parties will ensure that subcontractors are enforced accordingly.',
          'content7c': 'Any reference to the commercial relationship between the Parties in the advertising or promotional materials require the prior written consent of Inreg Solutions SRL.',
          'content7d': 'The above mentioned obligations will expire 36 months after the end of the commercial relationship between the Parties. In addition, they will not apply to the extent that the disclosure of information or data is required on the basis of a decision by a public authority or a court, needed for the defense in any proceedings instituted against the disclosing Party.',
          'content7e': 'The know-how disclosed by Inreg Solutions SRL during the auction process will be fully protected under this article. The technical concept of Inreg Solutions SRL during the bidding phase will not be used by the Beneficiary / Buyer for its own benefit, nor will it be disclosed to third parties.',
          'subtitleServiceDelivery': 'Service and delivery terms',
          'contentServiceDelivery': 'By utilizing our domain services, you agree to the following: Most bids at auctions are binding and non-retractable. Successful bids will incur a delivery fee equaling the registration fees attached to the domain name. The timeframe for domain activation, transfers and delivery varies, typically between 1 to 10 business days, depending on processing and administrative procedures. While we endeavor to complete all transfers promptly, we do not guarantee specific delivery dates. Payment in full, including any applicable delivery charges, is required before the domain activation or transfer is finalized.',
          'subtitlePaymentMethods': 'Payment methods. Payment processor.',
          'contentPaymentMethods': 'Our payment processor is PayU S.A., a company established and operating under the laws of Poland, registered in the Register of Enterprises kept by the Poznań-Nowe Miasto I Wilda Court in Poznań under registration number 0000274399, with its registered office located at 186 Grunwaldzka, 60-166 Poznań, Poland. PayU S.A. is the integrated payment processor for our site, with which Inreg Solutions S.R.L. has a contractual relationship. In addition to online payments via PayU S.A., we also accept payments via bank transfer. Both methods ensure secure and efficient transactions for our services.',
          'subtitleReturnPolicy': 'Return policy',
          'contentReturnPolicy': 'You are responsible for verifying your order before making the payment. Each order is customized based on your input and it is performed automatically. By making the payment, Inreg Solutions S.R.L. considers that you accepted the services according to your order. Therefore, our services of domain capture, domain registration or domain validity extension cannot be canceled, returned or refunded after the payment.',
          'subtitleComplaintPolicy': 'Complaint policy',
          'contentComplaintPolicy': 'Inreg Solutions S.R.L. is committed to dealing effectively with any complaints you may have about our service. If we got something wrong, we will apologise and where possible we will try to put things right. We also aim to learn from our mistakes and use the information we gain to improve our services.',
          'content2ComplaintPolicy': 'Inreg Solutions S.R.L. expects you to bring your complaint to our attention first and to give us a chance to put things right. Our aim is to answer to any complaint in maximum 3 working days.',
          'content3ComplaintPolicy': 'You can contact us at:',
          'content4ComplaintPolicy': 'Email: contact@inreg.ro',
          'content5ComplaintPolicy': 'Phone: +40 740 457 486',
          'content6ComplaintPolicy': 'Address: 47 Fabricii Street, building H, floor 1, suite 3, sector 6, 060822, Bucharest, Romania',
          'subtitle8': 'Duration of agreement',
          'content8': 'This agreement will remain in force for the duration of the domain registration and other services for which you have paid the fees. In case of renewal or extension of services, the term of the agreement will be extended automatically as required.',
          'subtitle9': 'Changes regarding the terms and conditions of Inreg Solutions SRL',
          'content9': 'The terms and conditions set forth in this Convention may be revised or changed. We will notify you about the change and by accepting these Terms and Conditions you agree to review our web site (inreg.ro) periodically in order to be aware of any changes or revisions we can make. If you do not agree to the changes you may terminate this Agreement at any time by sending an email or a letter us. The cessation note will be effective after we receive and process it. If you choose to terminate this agreement as a result of a change, all fees paid by you are non-refundable. By continuing to use the services subject to these Terms and Conditions, following any revision of this agreement or modification of the services, you agree to comply with such revisions or changes.',
          'subtitle10': 'Suspension',
          'content10': 'You are aware and you agree that the domain registration may be suspended or revoked to correct any errors or mistakes or following the decision of a debate on the domain.',
          'subtitle11': 'Limitation of obligations',
          'content11': 'You are informed and you agree that in the event of any obligation or indemnity on our part, compensation is limited to the amount you paid for those services. Inreg Solutions SRL is not liable for any direct, indirect, incidental, special or consequential damages resulting from the use or inability to use the services or the cost of changing the services. Inreg Solutions SRL will not be liable for damages caused by the use of your domain by you or another person. Inreg Solutions SRL is not liable for any loss or any resulting indictment but is not limited to: (1) losses or accusations resulting from delays or interruptions of access; (2) losses or accusations resulting from not transmitting all or part of the data; (3) losses or accusations resulting from errors, omissions, incorrect statements about any or all of the services provided under this agreement; (4) losses or accusations resulting from the development or interruption of the website or email service.',
          'subtitle12': 'Exoneration of liability',
          'content12': 'You are aware and you agree that such services are provided "as are" and "as are available". Thus, Inreg Solutions SRL is exonerated from any liability of any kind, express or implied. We can not guarantee that the services will meet your requirements or that they will be uninterrupted in time, secure or error free. Nor can we guarantee the results that can be obtained from the use of the services or the accuracy and correctness of the information obtained through the email or that the defects in the software will be corrected. You understand and you agree that any materials and / or any data downloaded or otherwise obtained through the use of the service are at your own risk and you will be solely responsible for any damage caused to your computer’s operating system or any resulting loss of data from downloading such materials and / or data. No written or verbal advice or information obtained through the email will create any warranty unless expressly provided for in this agreemnet. You acknowledge and you agree that the registration or pre-registration of a domain does not give give immunity in the case of objections to the registration, reservation, or use of the domain name. We do not provide any specific guarantee for the pre-registration of the domain as it depends on many factors that we can not foresee.',
          'subtitle13': 'Domain registration',
          'content13': 'For ".co.uk" and ".uk" domains: You agree and consent to comply with the ".co.uk" domain registration agreement and as well as with the registration rules and policies as established and published by Nominet UK on their website: nominet.uk. For ".ro" domains: You agree and consent to comply with the ".ro" Domain Registration Agreement (rotld.ro/registration-agreement) as well as the "Registration Rules" (rotld.ro/reguli-de-inregistrare) as established and published by ICI Bucharest, the Domain Registrar for ".ro" domains (RoTLD).',
          'subtitle14': 'Revisions to terms and conditions',
          'content14': 'You agree and you understand that we (Inreg Solutions SRL), Nominet.uk and ICI - Bucharest (the RoTLD Registry) may review this Agreement and change the services provided therein. Any review or change will be announced by email or as the case may be on the, Nominet.uk website (nominet.uk), for ".co.uk" and ".uk" domains, or RoTLD Registry website (rotld.ro) respectively for ".ro" domains. If you do not agree to any revisions of the agreement, you may terminate this agreement at any time by announcing us 30 days in advance by email. In the event that this agreement is terminated, any fees paid by you are non-refundable, unless we inform you otherwise. You agree that by continuing to use the services following any revisions of this agreement or service change, you agree to the revisions or changes.',
          'subtitle15': 'Right of refusal',
          'content15': 'We notify you that we reserve the right to unilaterally refuse to reserve or register a domain or to register you for other services or to delete your domain name within thirty (30) calendar days of your confirmation of your payment for these services. If we do not agree with the above or the domain name is deleted we agree to refund your fee. We, Nominet UK or the RoTLD Registry will not be liable to you for any loss or damage that may arise as a result of the removal or refusal of the domain.',
          'subtitle16': 'Severability',
          'content16': 'You acknowledge and you agree that in the event that a term or provision is declared invalid or is not in force, that provision or term will be enforced and interpreted in accordance with the applicable laws and in accordance with the parties’ initial intent. The rest of the terms and provisions remain in force.',
          'subtitle17': 'Disassociation',
          'content17': 'This Agreement must not be construed to create a partnership or other form of association between the Parties.',
          'subtitle18': 'Age',
          'content18': 'By accepting the services you confirm that you have the legal age to conclude this agreement.',
          'subtitle19': 'Violation of the agreement',
          'content19': 'You acknowledge and you agree that your failure will comply with the provisions of this agreement, rules or policies of Inreg Solutions SRL It is your failure if the information is incorrect, inaccurate or untrue as part of the application process or you are not updating your current, correct or complete information. By accepting the agreement you are aware that you have read and understood this agreement and agree to all terms and conditions.',
          'subtitle20': 'Applicable law',
          'content20': 'You are aware and you agree that this agreement is subject to the laws of the Romanian State and will be governed and interpreted in accordance with the Romanian law. Any action mentioned in this agreement must be brought to Romania and you irrevocably agree with the jurisdiction of these courts. You agree to the rules of dispute established by RoTLD (rotld.ro/dispute-resolution-policy).',
        }
      },
      'topHeader': {
        'domainNames': 'Domain Names',
        'domainAuctions': 'Domain Auctions',
        'buyWebsite': 'Buy Websites',
        'sellWebsite': 'Sell Websites',
        'whoisLookup': 'Whois Lookup',
        'myAccount': 'My Account',
        'aboutUs': 'About Us'
      },
      'domainTransferInfo': {
        'title': 'Why should I transfer my domain to Inreg?',
        'content': {
          'subtitle1': 'First-class support',
          'content1': 'Our top-notch support team is a reflection of our customer service oriented company culture. We can say with extreme confidence that this is always high on the list why people move their domains to Inreg.',
          'subtitle2': 'Prices',
          'content2': 'Our small prices speak for themselves: It’s only €6.99 / year to renew or register a domain. No hidden taxes or fees. We’ve researched our competition to make sure we’ve priced our services at the lowest price possible.',
          'subtitle3': 'Simple domain management',
          'content3': '"My domains" is a tool that provides features like "Self domain management" and "Private nameservers" allowing you manage and configure your domains instantly.',
          'subtitle4': 'Zero-risk transfer',
          'content4': 'Losing your domain name during the process is technically impossible. The transfer is secured and the process is closely monitored.',
          'subtitle5': 'The transfer is entirely free!',
          'content5': 'We don’t charge anything for domain transfers. The process is 100% FREE.'
        },
      },
      'domainTransferInput': {
        'placeholder': 'Enter the domain name',
        'error': {
          'invalid': 'Please enter a valid domain name',
          'tldNotSupported': 'Domains with this extension cannot be transferred to Inreg yet.'
        }
      },
      'userAuctions': {
        'title': 'My ongoing auctions',
        'info': 'Summary of all the active auctions you have bid on. Completed auctions are automatically deleted from this section.',
        'auctionList': {
          'loading': 'Loading auction list...',
          'noUserAuctions': 'You are not participating in any auction at the moment.',
          'browseActiveAuctions': 'Browse ',
          'browseActiveAuctionsLinkText': 'active domain auctions page',
          'browseActiveAuctionsPost': '.'
        },
        'clickToNavigateToAuction': 'Click to open the auction page',
        'error': {
          'sessionTimeout': 'Session timeout',
          'sessionExpiredError': 'Your session has timed out. Please sign in again.',
        },
        'openAuctionInNewTab': 'Open in new tab',
        'labels': {
          'name': 'Domain name',
          'offer': 'Your current offer',
          'winner': 'Highest offer',
        },
        'highestOffer': 'You have the highest offer',
        'notTheHighestOffer': 'Not the highest offer',
      },
      'userDetailsAdd': {
        'title': 'Add account details',
        'description': 'These account details will be used when you register or reserve a successfully captured domain'
      },
      'userDetailsEmpty': {
        'label': 'Add some registration details and join the action 🚀',
        'description': 'To bid for, register, or renew a domain you will need to add some registration details.',
        'titleAdd': 'Add account details',
        'descriptionAdd': 'These account details will be used when you register or reserve a successfully captured domain'
      },
      'userDetailsForm': {
        'name': 'Full name *',
        'entityType': 'Legal entity type *',
        'identificationNumber': 'NINO or CRN (example: QQ123456C or 01234567) *',
        'referenceNumber': 'Unique taxpayer reference (UTR) or VAT ID (example: 9012345678) *',
        'address': 'Address *',
        'address2': 'Address 2 (optional)',
        'city': 'City *',
        'state': 'State *',
        'postalCode': 'Postal code *',
        'country': 'Country *',
        'email': 'Email address *',
        'error': {
          'nameRequired': 'Please enter the full name',
          'nameMinLength': 'Invalid name, it should be at least 3 characters long',
          'entityTypeRequired': 'Please select the entity type',
          'identificationNumberRequired': 'Please enter NINO or CRN, as appropriate',
          'identificationNumberMinLength': 'Please enter a valid value',
          'identificationNumberMaxLength': 'Please enter a valid value',
          'referenceNumberRequired': 'Please enter a value',
          'referenceNumberMinLength': 'Please enter a valid value',
          'addressRequired': 'Please enter an address',
          'addressMinLength': 'Please enter a valid address',
          'cityRequired': 'Please enter a city',
          'stateRequired': 'Please enter a state',
          'postalCodeRequired': 'Postal code (or ZIP code) is required',
          'postalCodeMinLength': 'Please enter a valid postal code (or ZIP code)',
          'countryRequired': 'Please select country',
          'emailRequired': 'Please enter an email address',
          'emailValid': 'Please enter a valid email address'
        },
      },
      'userDetailsItem': {
        'setAsDefault': 'Make default',
        'setAsDefaultTooltip': 'Set as default account details'
      },
      'userDetailsOther': {
        'title': 'Other registration details associated with the account',
        'description': 'You can replace the default data used to register or reserve a successfully captured domain by choosing another option from the list',
        'editModalTitle': 'Manage account details',
        'setAsDefault': 'Make default'
      },
      'userDetailsSummary': {
        'title': 'Domain registration details',
        'description': 'These account details will be used when you register or reserve a successfully captured domain',
      },
      'userDomains': {
        'title': 'My domains',
        'description': 'Domain names associated with this account. You can manage them by clicking on the list entries below.',
        'labels': {
          'expireDate': 'Expiration date',
          'expireDateReservedDomains': 'Reservation expiration date',
          'name': 'Domain name',
          'nameservers': 'Nameservers',
          'privateNameservers': 'Private nameservers',
          'domainStatus': 'Domain status',
          'priceEuro': 'Euro',
          'priceRon': 'Ron',
          'paid': 'Paid',

        },
        'domainStatus': {
          'reserved': 'Reserved',
          'registered': 'Registered',
          'pendingDelete': 'Pending Delete'
        },
        'error': {
          'connectionError': 'Data could not be retrieved, check your internet connection. If the problem persists, please contact us at:',
          'domainNotFound1': 'Uh-oh! It looks like the domain you provided isn‘t associated to your account. Double-check for any typos, or visit the "',
          'domainNotFound2': '" section in your account to see the list of your domains.',
        },
        'domainList': {
          'loading': 'Loading domain list...',
          'noUserDomains': 'We couldn’t find any domain associated with this account'
        },
        'clickToNavigateToUserDomain': 'Click to manage this domain',
        'openUserDomainInNewTab': 'Open in new tab'
      },
      'userDomainsStatus': {
        'addedToCart': 'Domain added to cart',
        'alreadyAdded': 'Domain already in cart or unavailable'
      },
      'userDomainView': {
        'addToCartDomain': 'Add to cart to extend domain registration',
        'manageYourDomain': 'Manage your domain:',
        'summaryTitle': 'General information',
        'registerDate': 'Registration date',
        'expireDate': 'Expiry date',
        'domainStatus': 'Domain status',
        'nameserversTitle': 'Nameservers',
        'updateNameserver': 'Modify nameservers',
        'transferDomain': 'Transfer domain',
        'inputLabel': 'Key',
        'transferBtn': 'Transfer domain',
        'successfulTransferDomain': 'Your domain was successfully transferred',
        'registrationAvailability': 'Your domain registration is available until: ',
        'error': {
          'invalidNameserver': 'Invalid nameserver syntax. Example: ns1.inreg.ro',
          'invalidNameserverAll': 'Invalid nameserver syntax. Check error above.',
          'nameserverFurtherIssues': 'If you have any further issues, please contact us:',
          'showNameserversUnchanged': 'You haven’t made any changes to the nameservers yet',
          'transferDomain': 'An error has occured, please try again',
        }
      },
      'whoisView': 'Sorry, we’re doing some work on this section. We will be back with some exciting new content! Coming soon! Let us know if you have any questions:',
    },
    'ro': {
      'siteUrl': 'inreg.ro',
      'contactEmailInreg': 'contact@inreg.ro',
      'officeEmailInreg': 'office@inreg.ro',
      'contactPhoneInreg': '(+40) 740 457 486',
      'addressInreg': '47 Fabricii, clădirea H, ap. 3, Sector 6, București',
      'common': {
        'aboutUs': 'Despre noi',
        'actions': {
          'addRegistrationDetails': 'Adaugă date de înregistrare',
          'addBillingDetails': 'Adaugă date de facturare',
          'addNewBillingDetails': 'Adaugă date noi de facturare',
          'addNewEntry': 'Adaugă o intrare nouă',
          'addToCart': 'Adaugă în coș',
          'cancel': 'Anulează',
          'close': 'Închide',
          'confirm': 'Confirmă',
          'delete': 'Șterge',
          'deleteData': 'Șterge date',
          'deleteDomainFromCart': 'Elimină din coș',
          'edit': 'Editează',
          'editData': 'Editează date',
          'goBack': 'Pagina anterioară',
          'retry': 'Reîncearcă',
          'save': 'Salvează',
          'search': 'Căutare',
          'select': 'Selectează',
          'pay': 'Plătește',
          'undo': 'Renunță',
          'wait': 'Vă rugăm așteptați'
        },
        'addressLabels': {
          'fullName': 'Nume complet',
          'type': 'Tip',
          'identificationNumber': 'CNP / CUI',
          'referenceNumber': 'Număr înregistrare',
          'email': 'Email',
          'phone': 'Telefon',
          'address': 'Adresă',
        },
        'auth': {
          'enterYourPassword': 'Te rugăm să introduci parola',
          'incorrectEmail': 'Email incorect',
          'pleaseEnterEmail': 'Te rugăm să introduci adresa de email',
          'signIn': 'Autentificare',
          'signin': 'Intră în cont',
          'signOut': 'Deconectare',
          'signUp': 'Creează cont',
        },
        'clickToCopy': 'Click pentru copiere',
        'confirmation': {
          'pleaseConfirm': 'Confirmare',
          'setAsDefault': 'Vă rugăm să confirmați schimbarea datelor prestabilite (default)',
          'delete': 'Vă rugăm să confirmați ștergerea intrării selectate',
        },
        'contact': 'Contactează-ne',
        'continue': 'Continuă',
        'currencies': {
          'eur': 'EUR',
          'ron': 'RON',
        },
        'currency': 'Monedă *',
        'date': {
          'years': 'ani',
          'year': 'an',
          'months': 'luni',
          'month': 'lună',
          'weeks': 'săptămâni',
          'week': 'săptămână',
          'days': 'zile',
          'day': 'zi',
          'hours': 'ore',
          'hour': 'oră',
          'minutes': 'minute',
          'minute': 'minut',
          'min': 'min',
          'seconds': 'secunde',
          'second': 'secundă',
          'sec': 'sec'
        },
        'details': 'Detalii',
        'domainAuctions': {
          'title': 'Licitații domenii',
          'description': 'În fiecare zi multe domenii expiră și devin disponibile pentru a fi licitate. Vezi chiar acum lista cu licitații active.'
        },
        'domainSearch': {
          'title': 'Căutare și înregistrare domenii',
          'description': 'Utilizează instrumentul de căutare nume de domeniu și găseşte domeniul perfect în câteva secunde.',
          'searchPlaceholder': 'Caută domenii...',
          'searchedDomainsPlaceholder': '{count} domeniu selectat | {count} domenii selectate',
        },
        'domainTransfer': {
          'text': 'Transfer domeniu',
          'title': 'Transferă un domeniu:'
        },
        'email': 'Email',
        'entityType': {
          'p': 'Persoană fizică',
          'ap': 'Persoană fizică autorizată (PFA)',
          'nc': 'Non-commercial organization',
          'c': 'Companie comercială',
          'gi': 'Instituție guvernamentală',
          'pi': 'Instituție publică',
          'o': 'Alt tip de instituție'
        },
        'error': {
          'furtherIssuesContact': 'Dacă întâmpini probleme, te rugăm să ne contactezi:',
          'paymentFailed': 'Ceva nu a funcționat corect cu plata ta. Te rugăm să încerci din nou mai târziu.',
          'noCountriesMatchingContent': 'Nu am putut găsi nicio țară potrivită pentru "{term}"',
          'noReservedDomainsMatchingContent': 'Ne pare rău, nu am găsit niciun domeniu potrivit pentru "{searchReservedDomains}"',
          'noRegisteredDomainsMatchingContent': 'Ne pare rău, nu am găsit niciun domeniu potrivit pentru "{searchRegisteredDomains}"',
          'noDomainsFound': 'Niciun domeniu găsit',
        },
        'home': 'Home',
        'help': 'Ajutor',
        'name': 'Nume',
        'or': 'Sau',
        'phone': {
          'phoneNumber': 'Introduceți numărul de telefon...',
          'error': {
            'invalid': 'Te rugăm să introduci un număr de telefon valid'
          }
        },
        'price': 'Preț',
        'quantity': 'Cantitate',
        'reloadPage': 'Reîncarcă pagina',
        'securedBy': 'Plată securizată prin',
        'steps': {
          'step1': 'Pasul 1',
          'step2': 'Pasul 2',
          'step3': 'Pasul 3',
        },
        'success': 'Succes',
        'successfullySaved': 'Modificările au fost salvate cu succes.',
        'transfer': 'Transferă',
        'userDomains': {
          'title': 'Domeniile mele',
          'description': 'Modifică nameservere sau vezi lista cu domeniile tale.',
          'domainsSelection1': `Domeniile cu status 'Rezervat' vor fi adăugate în coșul dumneavoastră pentru înregistrare. Perioada implicită de înregistrare este de 1 an.`,
          'domainsSelection2': `Domeniile cu status 'Înregistrat' vor fi adăugate în coșul dumneavoastră pentru reînnoire. Perioada implicită de reînnoire este de 1 an (această perioadă adăugându-se la data de expirare actuală a domeniului).`,
          'domainsSelection3': `Dacă doriți să prelungiți perioada de reînnoire cu mai mult de un an, vă rugăm să ajustați perioadele/cantitățile direct în coșul de cumpărături.`
        }
      },
      'errorViews': {
        'notFound404': {
          'notFound': 'Pagina nu există. Eroare 404.'
        }
      },
      'aboutUsView': 'Pagina "Despre noi" este în construcție momentan. În cazul în care aveți întrebări sau întâmpinați probleme în timpul utilizării platformei, vă rugăm să ne contactați pe adresa de email:',
      'account': {
        'currency': {
          'title': 'Moneda contului',
          'error': {
            'currencyRequired': 'Te rugăm să selectezi moneda',
          }
        },
        'security': {
          'changePassword': 'Modifică parola',
          'changePasswordDescription': 'Actualizați parola pentru a asigura o protecție mai mare a contului.'
        },
        'settings': {
          'subscribe': 'Subscribe',
          'subscribeDescription': 'Abonează-te la newsletter',
          'unsubscribe': 'Unsubscribe',
          'unsubscribeDescription': 'Dezabonează-te de la newsletter',
        },
      },
      'activateUserView': {
        'activationInProgress': 'Se activează contul...',
        'accountActivated': 'Email-ul a fost confirmat și contul s-a activat cu succes!',
        'goToSignIn': 'Navighează către pagina de autentificare:',
      },
      'auctionView': {
        'bidFor': 'Licitează pentru domeniul',
        'timeLeft': 'Timp rămas',
        'auctionClosedTimeElapsed': 'Licitație închisă - timp\u00a0epuizat',
        'auctionClosesOn': 'Licitația se închide pe',
        'atHour': 'la ora',
        'bidIncrement': 'Pasul minim',
        'dialogContent1': 'Suma ofertată trebuie să fie multiplu de 5 (ex.: €15, €20, €25, €75). Am luat această decizie pentru a nu fi necesar să revii asupra licitației de fiecare dată când cineva pune un bid foarte mic peste al tău.',
        'until': 'Până la ora',
        'minimumAmountPerBid': 'Suma minimă / pas',
        'dialogContent2': 'Cu fiecare prelungire, se modifică suma minimă (de ',
        'dialogContent3': ') pentru pasul de licitație.',
        'startingWith': 'Începând cu ora',
        'minimumBid': 'Suma minimă / pas',
        'dialogExample': 'Exemplu: Nu se poate face o ofertă de €17 deoarece 17 nu este multiplu de 5, dar €15 și €20 sunt oferte valide.',
        'extendingTheAuction': 'Prelungirea licitației',
        'extAuctionContent1': 'Licitația se prelungește dacă un utilizator plasează o ofertă mai bună în ultimele 5 minute. Licitația se poate prelungi până la un maxim de 45 de minute.',
        'extAuctionContent2': 'Dacă este extinsă până la ora 23:45, se consideră că s-a atins limita de prelungire și nu se mai poate licita după această oră.',
        'extAuctionContent3': 'Utilizatorul poate licita în perioada extinsă doar dacă a depus o ofertă înainte de ora 23:00.',
        'extAuctionContent4': 'Cu fiecare prelungire, se modifică suma minimă (de ',
        'extAuctionContent5': ') pentru pasul de licitație.',
        'extAuctionExample': 'Exemplu: Dacă ai plasat până la ora 23:00 o ofertă de €30 și licitația are ora de închidere la 23:15, trebuie să licitezi cel puțin €50.',
        'extAuctionPreview': 'Timpul rămas se extinde cu 5 minute după ultima ofertă plasată. Maximul este ora 23:45.',
        'extraAuctionInfo1': 'Suma ofertată trebuie să fie multiplu de 5 (ex.: €15, €20, €25, €75).',
        'extraAuctionInfo2': 'Oferta minimă este €15, iar peste această sumă pasul de licitație este €5. Pasul se modifică pentru licitațiile extinse.',
        'extraAuctionInfo3': 'Nu putem garanta rezervarea / înregistrarea numelui de domeniu, dar achitarea sumei ofertate este obligatorie dacă reușim să-l rezervăm.',
        'extraAuctionInfo4': 'În situații speciale (ex.: fraudă, greșeli) se poate șterge cea mai mare ofertă, iar oferta ta poate deveni câștigătoare, chiar dacă în momentul ofertării nu ai fost notificat în legătură cu aceasta.',
        'extraAuctionInfo5': 'Dacă oferta ta este câștigătoare și noi reușim să capturăm domeniul, vei avea de achitat prețul licitat + pachet înregistrare în valoare de 33 lei.',
        'extraAuctionInfo6': 'Toate prețurile afișate pe site exclud TVA-ul.',
        'extraAuctionInfo7': 'Dacă se plasează două sau mai multe oferte egale, se va lua în considerare cea mai veche ofertă dintre ele.',
        'auctionStatus': 'Statusul licitației:',
        'currentOffer': 'Oferta ta actuală:',
        'congratulationsHighestOffer': 'Felicitări! Ai cea mai mare ofertă.',
        'notTheHighestOffer': 'Oferta ta nu este cea mai mare.',
        'noOffer': 'Nu ai licitat încă pentru acest nume de domeniu.',
        'placeOffer': 'Plasează o ofertă pentru',
        'yourOfferInputLabel': 'Introdu oferta (€)',
        'inputOfferRequired': 'Nu ai introdus nici o ofertă.',
        'inputMinimumOffer': 'Oferta minimă este de €15.',
        'inputMaximumOffer': 'Oferta maximă este €10000. Pentru sume mai mari, te rugăm să ne contactezi.',
        'inputFiveMultiple': 'Suma ofertată trebuie să fie multiplu de 5 (ex.: €15, €20, €25, €75).',
        'sendOffer': 'Plasează oferta (€)',
        'cannotAddOfferTimeExpired': 'Nu se mai pot plasa oferte pentru acest domeniu, licitația s-a închis.',
        'error': {
          'auctionViewError': 'A apărut o eroare pe această pagină. Te rugăm să verifici dacă este încă în lista de licitații active.',
          'auctionViewErrorAuctionsLink': 'Navighează către lista de licitații active'
        }
      },
      'billingDetailsAdd': {
        'title': 'Adaugă date de facturare',
        'description': 'Datele de facturare sunt necesare pentru generarea facturilor și efectuarea plăților',
        'useExistingEntry': 'Sau utilizați o intrare existentă:'
      },
      'billingDetailsEmpty': {
        'label': 'Nu ai setat încă date implicite pentru facturare',
        'description': 'Datele de facturare sunt necesare pentru generarea facturilor și efectuarea plăților.',
        'useExistingEntry': 'Sau utilizați o intrare existentă:',
        'titleAdd': 'Adaugă de facturare',
        'descriptionAdd': 'Datele de facturare sunt utilizate pentru generarea facturilor și efectuarea plăților'
      },
      'billingDetailsForm': {
        'name': 'Nume complet *',
        'entityType': 'Tip persoană *',
        'identificationNumber': 'CNP sau CUI (ex.: 1901230123456 sau RO123456) *',
        'referenceNumber': 'Număr înregistrare (ex.: J40/12345/2020) *',
        'address': 'Adresă *',
        'address2': 'Adresă - continuare (opțional)',
        'city': 'Oraș *',
        'state': 'Județ *',
        'postalCode': 'Cod poștal *',
        'country': 'Țară *',
        'email': 'Adresă de email *',
        'error': {
          'nameRequired': 'Te rugăm să introduci numele complet',
          'nameMinLength': 'Numele introdus este invalid, acesta trebuie să conțină cel puțin 3 caractere',
          'entityTypeRequired': 'Te rugăm să selectezi tipul entității',
          'identificationNumberRequired': 'Te rugăm să introduci CNP-ul sau CUI-ul, în funcție de caz',
          'identificationNumberMinLength': 'Te rugăm să introduci un cod valid',
          'identificationNumberMaxLength': 'Te rugăm să introduci un cod valid',
          'referenceNumberRequired': 'Te rugăm să introduci numărul de înregistrare',
          'referenceNumberMinLength': 'Te rugăm să introduci un număr de înregistrare valid',
          'addressRequired': 'Te rugăm să introduci adresa',
          'addressMinLength': 'Te rugăm să introduci o adresă validă',
          'cityRequired': 'Te rugăm să introduci orașul',
          'stateRequired': 'Te rugăm să introduci județul / statul',
          'postalCodeRequired': 'Te rugăm să introduci codul poștal',
          'postalCodeMinLength': 'Te rugăm să introduci un cod poștal valid',
          'countryRequired': 'Te rugăm să selectezi țara',
          'emailRequired': 'Te rugăm să introduci adresa de email',
          'emailValid': 'Te rugăm să introduci o adresă de email validă'
        },
      },
      'billingDetailsItem': {
        'setAsDefault': 'Setează ca prestabilit',
        'setAsDefaultTooltip': 'Setează ca date de facturare principale'
      },
      'billingDetailsOther': {
        'title': 'Alte date de facturare asociate contului',
        'description': 'Puteți înlocui datele de facturare alegând o altă opțiune din listă',
        'editModalTitle': 'Administrează date de facturare',
        'setAsDefault': 'Setează ca prestabilit'
      },
      'billingDetailsSummary': {
        'title': 'Date de facturare',
        'description': 'Aceste date sunt utilizate pentru generarea facturilor și efectuarea plăților'
      },
      'breadcrumbs': {
        'accountSecurity': 'Securitatea contului',
        'accountSettings': 'Setările contului',
        'activateUser': 'Activare cont',
        'auctions': 'Licitații',
        'billingDetails': 'Detalii de facturare',
        'buyWebsite': 'Cumpără un website',
        'cart': 'Coșul meu',
        'changePassword': 'Modifică parola',
        'cookies': 'Cookies',
        'dns': 'DNS',
        'domainNames': 'Înregistrare domenii',
        'domainTransfer': 'Transfer domeniu',
        'faq': 'FAQ',
        'forgotPassword': 'Parolă uitată',
        'myAccount': 'Contul meu',
        'news': 'Știri',
        'payments': 'Plăți',
        'privacyPolicy': 'Politica de confidențialitate',
        'resetPassword': 'Resetare parola',
        'sellWebsite': 'Vinde un website',
        'status': 'Status',
        'subscribe': 'Abonare',
        'terms': 'Termeni',
        'userAuctions': 'Licitațiile mele',
        'userDetails': 'Datele mele',
        'userDomain': 'Domeniile mele',
        'userDomainsReserve': 'Domeniile mele - rezervă',
        'userDomainsRenew': 'Domeniile mele - reînnoiește',
        'unsubscribe': 'Dezabonare',
        'whois': 'Whois',
        'paymentResponse': 'Detalii tranzacție',
      },
      'carouselCardPromo': {
        'tabOneTitle': 'Licitații 24/7 pentru domenii premium',
        'tabOneFirstLine': 'În fiecare zi multe domenii bune devin disponibile, fie pentru că proprietarii nu le-au mai reînnoit, fie pentru că o firmă a dat faliment, sau pur și simplu pentru că nu a mai prezentat interes.',
        'tabOneSecondLine': 'Licitează acum pentru domeniul dorit, iar noi vom încerca să îl capturăm pentru tine.',
        'tabTwoTitle': 'Administrează gratuit domeniile! Înregistrează. Transferă. Schimbă\u00a0DNS.',
        'tabTwoFirstLine': 'Nivelul profesionist al serviciilor este asigurat de experți cu mulți ani de experiență în domeniu.',
        'tabTwoSecondLine': 'Licitații fără nici un fel de plată în avans. Serviciul de înregistrare domeniu pe 1 an - 33 lei.',
        'tabTwoThirdLine': 'Serviciul administrare cont și domeniu - gratuit.',
        'tabThreeTitle': 'Înregistrare nume de domeniu simplă, rapidă și sigură',
        'tabThreeFirstLine': 'Capturezi, licitezi si înregistrezi domenii simplu și rapid.',
        'tabThreeSecondLine': 'Pentru mai multe informații sau întrebări, te rugăm să ne scrii.',
        'tabThreeThirdLine': 'Contactează-ne la:'
      },
      'cartItems': {
        'yourItems': 'Domeniile mele',
        'taxes': 'TVA:',
        'delete': 'Șterge',
        'voucher': 'Ai un voucher?',
        'emptyCart': 'Golește coșul',
        'subTotal': 'Subtotal:',
        'total': 'Total',
        'period': 'Perioada',
        'years': 'an | ani',
        'for': 'pentru',
        'euroSign': '€',
        'textRegister': 'Înregistrare domeniu nou',
        'textRenew': 'Reînnoire domeniu',
        'textReserve': 'Înregistrare domeniu rezervat',
        'paymentSummary': 'Sumar de plată',
      },
      'cartStepper': {
        'items': 'Coșul meu',
        'billing': 'Detalii facturare',
        'summary': 'Sumar comandă',
        'emptyState': {
          'description': 'Odată ce vei adăuga produse, le vei putea vizualiza aici.',
          'label': 'Coșul tău este gol',
          'info': 'Selectează una dintre opțiunile de mai jos pentru a găsi domeniul potrivit:',
        }
      },
      'changePasswordView': {
        'title': 'Creează o nouă parolă pentru cont',
        'changePasswordInProgress': 'Se resetează parola...',
        'passwordSuccessfullyChanged': 'Parola a fost actualizată cu succes!',
        'password': 'Parola curentă',
        'minLengthPassword': 'Te rugăm să folosești o parolă cu cel puțin 6 caractere',
        'newPassword': 'Parola nouă',
        'confirmPassword': 'Confirmă noua parolă',
        'enterPasswordAgain': 'Te rugăm să introduci parola încă o dată pentru verificare',
        'passwordsMustBeIdentical': 'Parolele specificate trebuie să fie identice',
      },
      'contactView': {
        'title1': 'Contactează-ne',
        'title2': 'Lasă-ne un mesaj',
        'subtitle1': 'Unde ne găsești',
        'subtitle2': 'Vom răspunde cât de curând',
        'name': 'Nume',
        'address': 'Adresă',
        'telephone': 'Telefon',
        'message': 'Mesajul dumneavoastră',
        'submitMessage': 'Trimite',
        'confirmation': 'Mesajul dumneavoastră a fost trimis cu succes',
        'enterYourName': 'Te rugăm să introduci numele',
        'invalidName': 'Numele introdus este invalid, acesta trebuie să conțină cel puțin 3 caractere',
        'enterEmailAddress': 'Te rugăm să introduci adresa de email',
        'enterValidEmailAddress': 'Te rugăm să introduci o adresă de email validă',
        'enterText': 'Te rugăm să introduci un mesaj',
        'invalidText': 'Textul introdus este invalid, acesta trebuie să conțină cel puțin 2 caractere',
      },
      'cookieBanner': {
        'settingsAndMore': 'Află mai multe sau modifică setările pentru cookies.',
        'cookieInfo': 'Acest site folosește cookies. Pentru a facilita utilizarea site-ului nostru, folosim cookies. Prin utilizarea acestui site sunteţi de acord în mod implicit cu folosirea de cookies de către Inreg și partenerii săi în vederea îmbunătățirii conținutului și personalizării experienței pe site, dar și pentru interacțiunea cu rețelele sociale și reclame.',
        'agree': 'Accept'
      },
      'cookiesView': {
        'title': 'Cookies',
        'description': {
          'subtitle': 'Ce sunt cookies?',
          'content1': 'Aproape toate website-urile stochează cantităţi mici de informaţii despre activitatea dumneavoastră pe acel website. Aceste informaţii sunt de tip text, cunoscute drept cookies. Acestea se stochează pe computerul dumneavoastră personal.',
          'content2': 'Din dorinţa de a vă oferi cea mai bună experienţă, website-ul nostru funcţionează optim doar având cookies activate. Acestea ne permit să putem recunoaşte un utilizator în momentul în care se reîntoarce pe site, să indentificăm şi să rezolvăm erorile, astfel ca noi să vă putem oferi o experienţă mai personală, crescând și optimizând și performanța. Datele sunt stocate numai cu acordul dumneavoastră, ca de exemplu atunci când licitați și / sau folosiți marketplace-ul de website-uri.',
          'content3': 'Cookies nu au scopul de a dăuna în vreun fel computerului dumneavoastră şi alegem cu atenţie furnizorii care pot seta cookies în orice fel de scopuri mai ales cel de marketing.',
        },
        'categories': {
          'title': 'Tipuri de cookies folosite pe acest website: ',
          'content': 'Cookies utilizate în acest website se conformează cu ghidul Camerei de Comerţ cu privire la acest tip de informații. Mai jos puteţi consulta restul detaliilor: ',
          'mandatory': {
            'subtitle': 'Categoria 1: Cookies absolut necesare',
            'content1': 'Aceste cookies sunt obligatorii pentru a vă permite navigarea pe website şi folosirea funcţiilor acestuia - cum ar fi accesul în zonele securizate. Fără cookies, serviciile pe care le solicitați și / sau utilizați, nu pot fi accesate.',
            'content2': 'Acest website foloseşte cookies absolut necesare care afectează pagini precum cea destinată licitațiilor și / sau marketplace-ului.'
          },
          'performance': {
            'subtitle': 'Categoria 2: Cookies de performanţă',
            'content1': 'Aceste cookies colectează informaţii referitoare la modul în care utilizatorii folosesc o pagină de internet - spre exemplu paginile vizitate mai des şi mesajele de eroare întâmpinate în procesul de navigare / utilizare a website-ului. Aceste cookies nu stochează informaţii cu scopul de a indentifica un utilizator. Toate informaţiile colectate de aceste cookies sunt agregate și sunt utilizate în scopul îmbunătăţirii funcţionalităților website-ului.',
            'content2': 'Cookies de tipul 2 folosite pe website: ',
            'listItem1': 'Cookies folosite de Sentry open-source error tracking software - sentry.io'
          },
          'functional': {
            'subtitle': 'Categoria 3: Cookies funcţionale',
            'content1': 'Cu aceste cookies se pot memora alegerile făcute pe website (de exemplu: numele de utilizator, regiunea sau limba) astfel putand sa oferi caracteristici personalizate. De exemplu, o pagină web vă poate oferi informaţii locale despre fusul orar / oră sau informații meteorologice, stocând prin intermediul cookies regiunea din care accesați website-ul.',
            'content2': 'Aceste cookies pot fi utilizate și cu scopul de a stoca schimbările făcute pentru o navigare mai plăcută sau mai ușoară. Mărimea textului de exemplu, dar şi alte aspecte tehnice / de interfață pe care le puteţi personaliza. Informaţiile colectate prin intermediul acestor cookies pot fi anonime şi nu pot urmări în vreun fel activitatea dumneavoastră pe alte website-uri.',
            'content3': 'Particular, acest website foloseşte cookies pentru formulare (forms) cu scopul de a reţine informaţii introduse ca în momentul în care accesati un alt formular (form) să fie posibil ca detaliile introduse să fie transferate. Aceste informaţii sunt şterse automat ori periodic ori chiar la sfârşitul unei sesiuni.'
          },
          'marketing': {
            'subtitle': 'Categoria 4: Cookies pentru publicitate',
            'content1': 'Aceste cookies sunt folosite pentru a furniza reclame relevante pentru dumneavoastră. Sunt folosite şi pentru a impune o limită pentru frecvenţa cu care vizualizaţi reclama / reclamele, dar totodată şi pentru a cuantifica / măsura eficienţa campaniei. Acestea sunt plasate de cele mai multe ori de către agenţiile de publicitate, dar numai cu permisiunea operatorului website-ului. Acestea memorează faptul că aţi vizitat un website, iar această informaţie este partajată cu alte organizaţii, cum ar fi agenţiile de publicitate. În mod frecvent cookies publicitare vor fi corelate cu funcţionalitatea site-ului, fiind furnizate către / de alte organizaţii.',
            'content2': 'Următoarele cookies de tipul 4 sunt folosite în acest website:',
            'listItem1': 'google.com',
            'content3': 'Dar și altele pe care le puteți verifica cu ajutorul unui software specializat - de exemplu pentru versiunile moderne ale browser-ului "Google Chrome" puteți folosi următoarea procedură: Deschideți panoul Chrome DevTools, navigați către tab-ul Application și studiați informațiile afișate.'
          }
        },
        'settings': {
          'title': 'Dezactivare cookies',
          'content1': 'Dumneavoastră la fel ca orice alt vizitator / utilizator al website-ului puteţi decide dacă doriţi să aveți cookies activate. Site-ul funcţionează optim cu toate cookies activate. Setările personale pentru cookies pot fi controlate şi schimbate din setările browser-ului.',
          'content2': 'Pentru informaţii despre modificarea setărilor de cookies, alegeţi din lista de mai jos browser-ul de internet folosit: ',
          'content3': 'Dacă aveţi cookies dezactivaţi, cel mai probabil unele caracteristici ale website-ului se vor dezactiva și nu ne asumăm răspunderea pentru oricare efect advers. Chiar şi cu toate cookies dezactivate, o cantitate foarte mică de informaţii va continua să fie preluată din browser-ul dumneavoastră de internet. Aceste informaţii sunt necesare pentru funcţionarea de bază a website-ului nostru.'
        }
      },
      'domainList': {
        'title': 'Licitații active',
        'loading': 'Se încarcă lista cu licitații...',
        'placeholder': 'Lista cu licitații este în curs de actualizare. Te rugăm să încerci mai târziu.',
        'domainName': 'Nume domeniu',
        'auctionStatus': 'Status licitație',
        'timeLeft': 'Timp rămas',
        'auctionOnGoing': 'Licitație în curs',
        'auctionClosed': 'Licitație închisă',
        'auctionClosedTimeElapsed': 'Licitație închisă - timp\u00a0epuizat',
        'clickToNavigateToAuction': 'Click pentru a navigha către licitație',
        'openAuctionInNewTab': 'Deschide licitația într-un tab nou',
        'page': 'Pagina',
        'of': 'din'
      },
      'domainNamesView': {
        'reserveTitle': 'Domenii rezervate',
        'reserveSubtitle': 'Aici poți vedea toate domeniile pe care le-ai rezervat. Apasă aici pentru a le înregistra.',
        'renewTitle': 'Domenii ce pot fi prelungite',
        'renewSubtitle': 'Aici îți poți vedea toate domeniile. Apasă aici pentru a le reînnoi.',
        'seeAll': 'Vezi toate domeniile'
      },
      'domainTransferHowTo': {
        'title': 'Cum transfer un domeniu la Inreg?',
        'step1': {
          'title': 'Verifică dacă se poate transfera',
          'content1': 'Scrie în caseta de mai sus numele domeniului pe care dorești să îl transferi.',
          'content2': 'Acest pas este necesar pentru a verifica dacă se poate efectua transferul.'
        },
        'step2': {
          'title': 'Obținerea codului de transfer',
          'content1': 'Codul de transfer (câteodată denumit și cod EPP sau cheie de autorizare) se obține, fie de la firma de unde ai cumpărat domeniul, fie de la registrarul curent.',
          'content2': 'Dacă întâmpini dificultăți la acest pas, te rugăm să ne contactezi, iar noi vom încerca să rezolvăm cât mai rapid problema.'
        },
        'step3': {
          'title': 'Efectuarea transferului',
          'content1': 'Introdu în caseta "Cheie de transfer" codul obținut în pasul anterior.',
          'content2': 'Caseta apare după ce efectuezi verificarea de la',
          'content3': 'După finalizarea transferului, vei putea administra direct domeniul din secțiunea "Contul Meu".',
          'content4': 'În plus, îl vei putea reînnoi oricând dorești la prețul redus: Doar €6.99 pe an.'
        }
      },
      'domainTransferPromo': {
        'title': 'De ce să transferi domeniul la Inreg?',
        'description1': 'Ne-am autoimpus să păstrăm o legătură cât mai apropiată cu clienții noștri astfel încât să ne putem adapta rapid la cele mai populare cerințe.',
        'description2': 'Drept urmare am permis utilizatorilor să evalueze, după bunul plac, avantajele considerate cele mai importante.',
        'description3': 'Avantajele enumerate mai jos sunt ordonate în funcție de numărul de voturi primit de la utilizatori.',
        'cards': {
          'price': {
            'title': 'Prețul',
            'description': 'Prețurile mici vorbesc de la sine: Costă doar €6.99 / an reînnoirea sau înregistrarea unui domeniu. Fără taxe sau comisioane ascunse.',
            'description2': 'Am analizat competiția pentru a ne asigura că prețurile sunt cele mai mici cu putință.'
          },
          'support': {
            'title': 'Excelență în servicii de suport',
            'description': 'Echipa de experți tehnici de primă clasă este oglinda culturii "orientate către client" a companiei noastre.',
            'description2': 'O spunem cu încredere: Calitatea serviciilor noastre de suport se află în vârful listei de motive pentru care utilizatorii își transferă domeniile la Inreg.'
          },
          'domainManagement': {
            'title': 'Administrare simplă a domeniilor',
            'description': 'Instrumentul "Domeniile mele" conține funcționalități precum "Self domain management" și "Nameservere private" facilitând astfel tuturor clienților noștri o gestionare simplă și rapidă a domeniilor pe care le dețin la Inreg.',
            'description2': 'Comenzile de administrare se execută instantaneu.'
          },
          'noRisk': {
            'title': 'Transferul nu implică riscuri',
            'description': 'Numele de domeniu nu se poate pierde în timpul transferului.',
            'description2': 'În plus, transferul este 100% securizat și procesul este monitorizat.'
          },
          'freeTransfer': {
            'title': 'Transfer gratuit',
            'description': 'Nu percem nici o taxă pentru transferul domeniilor.',
            'description2': 'Procesul este 100% GRATUIT.'
          },
          'instant': {
            'title': 'Instantaneu',
            'description': 'Pentru domeniile cu extensia ".ro" și ".eu" transferul se face instantaneu.',
            'description2': 'În cazul domeniilor ".com", ".net" și ".org" transferul se execută în câteva zile.'
          },
          'noCommissions': {
            'title': 'Fără comisioane sau taxe ascunse',
            'description': 'Spre deosebire de majoritatea celorlalte companii registratoare de domenii, Inreg nu percepe comisioane sau taxe ascunse.',
            'description2': 'Puteți accesa pagina de prețuri pentru a afla mai multe.'
          },
          'noCommitment': {
            'title': 'Fără angajament pe termen lung',
            'description': 'Nu există nicio clauză contractuală care să te țină blocat.',
            'description2': 'Poți transfera oricând domeniul tău la un alt registrar.'
          },
          'serviceAvailability': {
            'title': 'Timp de funcționare 100%',
            'description': 'Fără întreruperi la e-mail.',
            'description2': 'Transferul se face în deplină siguranță fără a necesita muncă suplimentară din partea ta și fără a afecta funcționalitatea serviciilor.'
          }
        },
        'transferBtn': 'Transferă gratuit domeniul'
      },
      'domainTransferVerification': {
        'transferring': 'Se transferă',
        'transferringTo': 'în contul tău Inreg...',
        'domainTransferred': 'a fost transferat cu succes în contul tău Inreg.',
        'transferError': 'Nu s-a putut transfera domeniul. Te rugăm încerci din nou.',
        'transferError2': 'Dacă problema persistă, ne poți contacta la:',
        'viewYourDomains': 'Vizualizează domeniile tale',
        'transferToInregPre': 'Transferă domeniul',
        'transferToInregPost': 'la Inreg',
        'unlock': {
          'title': 'Deblochează domeniul dacă este necesar',
          'content1': 'Cum deblochezi un domeniu (puteți sări peste acest pas dacă domeniul nu este blocat):',
          'content2': 'Accesează contul de la registrarul actual (firma la care domeniul tău este înregistrat în prezent).',
          'content3': 'Navighează către pagina de administrare a domeniilor.',
          'content4': 'Identifică și deblochează domeniul pe care dorești să îl transferi.',
          'content5': 'Dacă registrarul actual nu îți permite să deblochezi singur domeniul, poți lua legătura cu serviciul lor de suport pentru a-ți debloca ei domeniul.'
        },
        'transfer': {
          'title': 'Introduceți cheia de transfer',
          'transferKey': 'Cheie de transfer',
          'transferToMyAccount': 'Transferă în contul meu',
          'content1': 'Cum se obține cheia de transfer pentru un domeniu „.ro”:',
          'content2': '1. Accesează',
          'content3': 'rotld.ro',
          'content4': 'și alege din meniu opțiunea „Domenii .ro” > „Administrare On-Line”.',
          'content5': 'Dacă nu cunoști parola de administrare a domeniului, selectează opțiunea „Resetare parolă” din partea de jos a paginii.',
          'content6': '2. Conectează-te folosind numele domeniului și parola de administrare.',
          'content7': '3. Generează cheia de transfer: După conectare, deschide submeniul „Chei de Autorizare” și selectează „Transfer la alt registrar”.',
          'content8': '4. Accesează link-ul evidențiat - „Click aici” - din paragraful cu urmatorul text: „Click aici pentru a obține mailul cu Cheia de Autorizare necesară transferului de la registrarul (...)”.',
          'content9': '5. Cheia de transfer va fi trimisă în scurt timp prin e-mail.'
        },
        'error': {
          'domainTransferKeyRequired': 'Te rugăm să introduci codul de autorizare / cheia de transfer',
          'domainTransferKeyMinLength': 'Cod de autorizare / cheie de transfer prea scurt(ă). În cazul în care întâmpini probleme cu acest pas, te rugăm să ne contactezi.'
        }
      },
      'domainTransferView': {
        'title': 'Transfer domeniu',
        'description': 'Beneficiază de cel mai avantajos preț pentru reînnoirea domeniilor tale.',
        'info': 'Transferul domeniilor este gratuit',
        'whyTransfer': 'De ce să transferi domeniul:',
        'viewAllAdvantages': 'Vezi toate avantajele'
      },
      'footer': {
        'footerTop': {
          'inregLogoAlt': 'Inreg logo',
          'slogan': 'Helping our customers seize the opportunity since 2018.'
        },
        'chooseLanguage': 'Selectează limbă',
        'general': 'General',
        'services': 'Servicii',
        'support': 'Suport clienți',
        'partners': 'Parteneri',
        'payment': 'Metode de plată',
        'home': 'Home',
        'aboutUs': 'Despre noi',
        'accountPage': 'Contul meu',
        'domainNames': 'Înregistrare domenii',
        'domainAuctions': 'Licitații domenii',
        'domainTransfer': 'Transfer domeniu',
        'whoisLookup': 'Whois domenii',
        'termsAndConditions': 'Termeni și Condiții',
        'privacyPolicy': 'Confidențialitate',
        'cookies': 'Cookies',
        'consumerProtectionEn': 'UK ECC',
        'consumerProtectionLinkEn': 'ukecc.net',
        'consumerProtectionRo': 'ANPC',
        'consumerProtectionLinkRo': 'anpc.gov.ro',
        'copyrightInfo': 'Copyright © 2018 - 2021 Inreg Solutions SRL. Toate drepturile rezervate. Marca Inreg este o marcă înregistrată a Inreg Solutions SRL în Marea Britanie și în alte țări. Sigla „inreg” și „ir” sunt mărci comerciale înregistrate ale Inreg Solutions SRL.',
        'tosInfoPre': 'Prin accesarea, vizualizarea, şi utilizarea acestui Site confirmaţi că aţi înţeles şi acceptaţi documentul',
        'tosInfoText': 'Termeni și Condiții',
        'tosInfoPost': 'precum și alți termeni, condiții, politici și notificări din Site, sau la care se face trimitere. Acest site este deținut și operat de Inreg Solutions SRL.'
      },
      'forgotPasswordView': {
        'title': 'Setează o nouă parolă',
        'forgotPasswordEmailSent': 'Am trimis link-ul de resetare către adresa',
        'accessforgotPasswordLink': 'Urmați instrucțiunile din email pentru a reseta parola.',
        'navigateToMainPage': 'Navighează către pagina principală:',
        'mainPage': 'Pagina principală',
        'info': 'Te rugăm să introduceți adresa de email a contului pentru care dorești resetarea parolei.',
        'cancel': 'Cancel',
        'ifIssuesEncountered': 'În cazul în care întâmpini probleme te rugăm să ne scrii la:',
      },
      'helpView': {
        'title': 'Ajutor și informații suplimentare',
        'subtitle1': 'Întrebări frecvente (FAQ)',
        'subtitle2': 'Facturi și plăți',
        'subtitle3': 'Licitații domenii / serviciul de capturare (cunoscut si ca backorder sau drop catch)',
        'subtitle4': 'Despre DNS',
        'faq': {
          'question1': 'Ce este un domeniu web (domeniu .ro)?',
          'answer1': 'Pe scurt, un domeniu web (sau doar domeniu) este folosit la identificarea uneia sau a mai multor adrese IP. Este ce urmează după "@" la o adresă de email sau după "www.". Când cineva întreabă care este site-ul tău, ce le spui este numele de domeniu. Toate domeniile web sunt de forma: nume.extensie (exemplu: numelemeu.com).',
          'question2': 'Cine este autoritatea oficială (registru) care reglementează domeniile ".ro"?',
          'answer2': 'Registrul domeniilor ".ro" - "Institutul Național de Cercetare-Dezvoltare în Informatică – ICI București", numit "Registrul RoTLD".',
          'question3': 'Ce reprezintă statutul de partener autorizat RoTLD?',
          'answer3_1': 'Registrul RoTLD are în vedere dezvoltarea unui mediu competitiv, de aceea poate să încheie contracte de parteneriat pentru serviciile de înregistrare și mentenanță a domeniilor ".ro".',
          'answer3_2': 'Inreg Solutions SRL este partener RoTLD din 2018. Lista completă cu partenerii autorizați se poate consulta pe site-ul RoTLD la următoarea adresă:',
          'question4': 'Ce înseamnă "TLD"?',
          'answer4': 'TLD = Top Level Domain.',
          'question5': 'Ce reprezintă deținerea dreptului de utilizare/folosință a unui nume de domeniu?',
          'answer5': 'O persoană fizică sau juridică prin înregistrarea unui nume de domeniu dobândește un drept de utilizare sau folosință asupra numelui de domeniu pe o perioadă definită de timp. Deținerea dreptului de utilizare / înregistrarea unui domeniu trebuie să fie conformă cu Termenii și Condițiile RoTLD, iar cele înregistrate / rezervate / licitate prin intermediul platformei Inreg trebuie să se supună suplimentar termenilor și condițiilor prezente pe acest website.',
          'question6': 'Domenii cu statusul "PendingDelete"',
          'answer6': 'Domeniile pentru care nu s-a achitat taxa de mentenanță anuală sunt programate la ștergere (intră în starea de PendingDelete). Deținătorul dreptului de utilizare are la dispoziție 90 de zile pentru a achita taxa de mentenanță din momentul când numele de domeniu a fost programat pentru ștergere.',
          'question7': 'Ștergerea unui nume de domeniu pentru care nu s-a achitat taxa de mentenanță',
          'answer7': 'În fiecare zi, un număr mare de nume de domenii nu sunt reînnoite și devin public accesibile. Ștergerea domeniilor se efectuează după o perioadă de 90 de zile de la data intrării în PendingDelete, timp în care deținătorul poate să prelungească / achite taxa de mentenanță. Dacă deținătorul nu își reînnoiește dreptul de utilizare, atunci domeniul va fi "șters". După ce un domeniu a fost "șters", acesta devine disponibil pentru rezervare / înregistrare conform principiului "primul venit, primul servit."',
          'question8': 'Există limitări / condiții tehnice pentru înregistrarea unui nume de domeniu?',
          'answer8_1': 'Da, acestea sunt:',
          'answer8_2': '• Să conțină minim un caracter și cel mult 63 caractere',
          'answer8_3': '• Caracterele utilizate să facă parte din alfabetul latin (Aa-Zz) și numere arabe (0-9). Se poate folosi și caracterul minus "-", dar nu la început sau sfârșit de nume sau consecutiv ("--")',
          'question9': 'Cât durează activarea / înregistrarea unui nume de domeniu?',
          'answer9': 'Un nume de domeniu se activează numai în momentul în care plata comenzii este confirmată. Durata poate să difere în funcție de metoda de plată aleasă (card, transfer bancar, etc).',
          'question10': 'Achitarea taxei de mentenanță',
          'answer10': 'Deținerea dreptului de folosință / utilizare asupra unui nume de domeniu presupune achitarea unei taxe de mentenanță cu caracter anual. Taxa se achită înainte de data de expirare sau poate fi achitată în avans pe o perioadă de maxim 10 ani.',
        },
        'payments': {
          'subtitle': 'Plata serviciilor / înregistrării',
          'content1': 'Plata serviciilor / înregistrării poate fi efectuată prin:',
          'listItem1': '• Procesatorul online de plăți',
          'listItem2': '• Ordin de plată',
          'listItem3': '• Depunere numerar',
          'content2': 'Pentru ordine de plată și depuneri numerar vă rugăm să specificați numărul facturii proforme pentru a ne ajuta să procesăm mai rapid comenzile.',
          'content3': 'Termenul de plată al facturilor proforme este de 3 zile. Nu suntem răspunzători dacă doriți să reînnoiți un nume de domeniu care este în PendingDelete, iar acesta a fost șters înainte ca dumneavoastră să achitați taxa anuală, chiar dacă factura proformă este încă în termen.',
          'content4': 'Factura fiscală va fi emisă după confirmarea plății și va fi trimisă prin email sau poate fi descărcată din contul de client.',
          'content5': 'Prețurile pe platformă pot fi exprimate în Euro sau în Ron. Cursul de schimb va fi cursul BNR din ziua emiterii facturii proforme.',
          'content6': 'Toate prețurile afișate pe site exclud TVA-ul.',
          'listItem4': 'Inreg Solutions S.R.L.',
          'listItem5': 'CUI: 40281673',
          'listItem6': 'IBAN RON: RO54BRDE030SV96209200300',
          'listItem7': 'IBAN EUR: RO52BRDE030SV97120070300',
          'listItem8': 'SWIFT: BRDEROBU',
          'listItem9': 'Banca: BRD',
        },
        'auctions': {
          'auctions0': {
            'content1': 'În fiecare zi expiră foarte multe domenii. Prin serviciul de capturare se încearcă înregistrarea numelui de domeniu aflat în curs de ștergere și care urmează a fi șters pentru neplata taxei anuale. În momentul în care numele de domeniu devine disponibil pentru înregistrare, se va încerca înregistrarea acestuia.',
            'content2': 'De serviciul de capturare beneficiază doar clientul care a câștigat licitația pentru numele de domeniu respectiv. Rezervarea domeniului va fi făcută direct și numai cu datele furnizate de acesta.',
            'content3': 'Nu garantăm că reușim rezervarea / înregistrarea numele de domeniu, deoarece există șansa să fie rezervat / înregistrat de altcineva înaintea noastră.',
          },
          'auctions1': {
            'subtitle': 'Introducere (domenii ".ro")',
            'content1': 'Începând cu data de 30 august 2018, Registrul de domenii .ro a început ștergerea domeniilor pentru care nu s-a plătit taxa de mentenanță anuală înainte de data expirării. Aceste domenii au fost programate la ștergere automată (domenii care sunt în curs de ștergere). Ștergerea domeniilor se va efectua după o perioadă de 90 de zile de la data expirării, timp în care deținătorul poate să achite taxa de mentenanță anuală. Dacă deținătorul nu își reînnoiește dreptul de utilizare, atunci domeniul va fi șters. După ce un domeniu a fost șters, acesta devine disponibil pentru înregistrare conform principiului "primul venit, primul servit".',
          },
          'auctions2': {
            'subtitle': 'Licitații',
            'content1': 'Pentru început trebuie să verifici dacă domeniul care te interesează este în curs de ștergere. Poți să faci asta prin serviciul Whois sau prin verificarea zilnică a listelor cu domenii care urmează a fi "șterse".',
            'content2': 'Serviciul de capturare al unui domeniu se numește Dropcatch (sau Backorder). Dacă pentru un domeniu care urmează a fi șters există mai multe persoane interesate și nu există deja o ofertă agreată direct cu un singur client în avans, se va organiza o licitație publică unde persoana care a licitat cea mai mare sumă va beneficia de serviciul dropcatch.',
          },
          'auctions3': {
            'subtitle': 'Reguli generale: ',
            'listItem1': '• Nu putem garanta înregistrarea domeniului, chiar și în cazul în care se câștigă licitația',
            'listItem2': '• Dacă reușim să capturăm domeniul, ofertele devin obligatorii',
            'listItem3': '• Oferta minimă pentru licitație este de 15€',
            'listItem4': '• Oferta minimă de 15€ poate fi retrasă cu maxim 30 minute înainte de închiderea licitației. Dacă oferta ta este mai mare de 15€, aceasta nu se mai poate retrage',
            'listItem5': '• Oferta ta poate să nu fie cea mai mare. Dacă din diverse motive de siguranță cea mai mare ofertă este ștearsă, oferta ta poate să ajungă cea mai mare',
            'listItem6': '• Dacă se plasează două oferte egale, se va lua în considerare cea mai veche ofertă',
            'listItem7': '• Nu poți să îți micșorezi oferta, poți doar să o îmbunătățești',
            'listItem8': '• Nu poți să vezi cea mai mare ofertă, poți să vezi doar oferta ta și dacă este sau nu cea mai mare',
            'listItem9': '• Licitația se închide la ora 23:00 dacă oferta cea mai mare a fost plasată cu cel puțin 5 minute înainte de închiderea licitației',
            'listItem10': '• Dacă cea mai bună ofertă a fost plasată în ultimele 5 minute, licitația va fi prelungită',
            'listItem11': '• Dacă oferta ta este câștigătoare și noi reușim să capturăm domeniul, vei avea de achitat prețul licitat pentru serviciul Dropcatch + pachet înregistrare în valoare de 33 lei (pentru domeniile ".ro")',
          },
          'auctions4': {
            'subtitle': 'Prelungirea licitației',
            'content1': 'Licitația se prelungește dacă un utilizator plasează cea mai bună ofertă în ultimele 5 minute, garantând astfel tutoror participanților posibilitatea de a contra-licita. Licitația se poate prelungi cu 45 de minute maxim.',
            'content2': 'Dacă este extinsă până la ora 23:45, se consideră că s-a atins limita de prelungire și nu se mai poate licita după această oră.',
            'content3': 'Utilizatorul poate licita în perioada extinsă doar dacă a depus o ofertă înainte de ora 23:00.',
            'content4': 'Cu fiecare prelungire, se modifică suma minimă (de 5€) pentru pasul de licitație.',
          },
          'auctions5': {
            'subtitle': 'Backordering (or Dropcatch)',
            'content1': 'Nu garantăm că reușim capturarea unui domeniu. Există mai multe companii ca noi, iar șansele se împart.',
            'content2': 'În funcție de oferta câștigătoare se vor atribui și resursele pentru serviciul Dropcatch. Spre exemplu, un domeniu pentru care oferta câștigătoare a fost de 300€ are șanse mai mari să fie capturat decât un domeniu pentru care oferta cea mai mare a fost 100€.',
          },
          'auctions6': {
            'subtitle': 'Plata serviciilor',
            'content1': 'În cazul în care reușim să capturăm domeniul vei primi pe email o factură proformă pe care trebuie să o achiți în 72 ore. Datele de facturare și de înregistrare vor fi cele introduse de tine în contul tău. Te rugăm să te asiguri bine că sunt completate corect, ele nu pot fi modificate ulterior.',
          },
          'auctions7': {
            'subtitle': 'Neplata serviciilor',
            'content1': 'În cazul de neplată al serviciilor în 72 ore de la emiterea facturii proforme, rezervarea domeniului nu va mai fi valabilă, iar dreptul de a licita va fi suspendat până la plata serviciilor. Dacă plata serviciilor nu este efectuată, îți vom bloca contul permanent și îți vom factura penalități.',
          },
          'auctions8': {
            'subtitle': 'Alte informații',
            'content1': 'Te înștiințăm că ne rezervăm dreptul în mod unilateral de a refuza sau șterge o ofertă sau să atribuim un domeniu.',
          },
        },
        'dns': {
          'dns1': {
            'subtitle': 'Ce este un nameserver / DNS?',
            'content1': 'Un nameserver este o mapare între adresa unui device / server (adresa IP), și un nume desemnat acelui device / server. Fiecare device conectat la internet are o adresă IP unică, iar toate aceste adrese diferite sunt încadrate într-un sistem numit Domain Name System (sau DNS).',
            'content2': 'Abstractizând, DNS-ul este similar cu maparea dintre un cod poștal și o adresă sau cu o agendă telefonică (număr de telefon -> numele persoanei). DNS-ul conține sute de milioane sau chiar miliarde de astfel de adrese.',
          },
          'dns2': {
            'subtitle': 'Cum funcționează DNS-ul?',
            'content1': 'Când un utilizator accesează un website, se produce o interogare de DNS care întoarce IP-ul acestuia. Serverele DNS au scopul de a mapa adresele IP cu domeniile web. Internetul / computerele sunt concepute să comunice folosind adrese IP, în schimb oamenii memorează mult mai ușor denumiri (un nume de domeniu web, ex.: inreg.ro).',
          },
          'dns3': {
            'subtitle': 'Cât durează propagarea modificărilor DNS?',
            'content1': 'Modificările DNS pentru un domeniu se înregistrează aproape instant în baza de date a registrului, dar nu se poate calcula cu exactitate cât dureaza până când propagarea DNS este complet finalizată, deoarece într-un punct geografic aceasta ar putea fi încheiată, în timp în alt punct ar putea să fie încă în procesare. În medie propagarea completă durează între 24-48h.',
            'content2': 'Timpul de propagare a DNS-urilor este de obicei influențat de: perioada de timp pentru care serverele DNS stochează informațiile (TTL sau Time To Leave / Hop Limit), furnizorul de servicii de internet (ISP sau Internet Service Provider) și registrul numelui de domeniu.',
            'content3': 'Chiar și după o actualizare DNS aproape completă, este posibil să vizualizezi tot vechiul conținut al site-ului, deoarece web browserele rețin adesea informațiile într-o memorie temporară (denumită tehnic cache) motiv pentru care golirea acesteia poate deveni obligatorie pentru a facilita instant afișarea actualizată a paginii web.',
          },
        },
      },
      'homepageCards': {
        'firstCard': {
            'title': 'Licitații 24/7 pentru domenii premium',
            'description': 'În fiecare zi multe domenii bune devin disponibile, fie pentru că proprietarii nu le-au mai reînnoit, fie pentru că o firmă a dat faliment, sau pur și simplu pentru că nu a mai prezentat interes. Licitează acum pentru domeniul dorit, iar noi vom încerca să îl capturăm pentru tine.',
        },
        'secondCard': {
            'title': 'Administrează gratuit domeniile! Înregistrează. Transferă. Schimbă\u00a0DNS.',
            'description': 'Nivelul profesionist al serviciilor este asigurat de experți cu mulți ani de experiență în domeniu. Licitații fără nici un fel de plată în avans. Serviciul de înregistrare domeniu pe 1 an - 33 lei. Serviciul administrare cont și domeniu - gratuit.',
        },
        'thirdCard': {
            'title': 'Înregistrare nume de domeniu simplă, rapidă și sigură',
            'description': 'Capturezi, licitezi si înregistrezi domenii simplu și rapid. Pentru mai multe informații sau întrebări, te rugăm să ne scrii. Contactează-ne la: contact@inreg.ro',
        },
      },
      'homeView': {
        'auctionCarouselCardTitle': 'Top licitații active',
        'saleCarouselCardTitle': 'Pagini web de vânzare',
        'searchBlockMain': 'Caută domeniul dorit',
        'searchBlockPlaceholderText': 'Nume domeniu...',
      },
      'infoHeader': {
        'serviceStatus': 'starea serviciilor',
      },
      'minicart': {
        'cartInfo': 'Nu ai niciun produs în coș',
        'cartDetails': 'Vezi detalii coș',
        'delete': 'Domeniu șters din coș',
        'domain': 'domeniu',
        'subTotal': 'Subtotal:',
        'total': 'Total:',
        'textRegister': 'Înregistrare domeniu nou',
        'textRenew': 'Reînnoire domeniu',
        'textReserve': 'Înregistrare domeniu rezervat',
        'checkout': 'Checkout',
      },
      'minicartEmptyState': {
        'label': 'Coșul tău este gol',
        'description': 'Selectează una dintre opțiunile de mai jos pentru a găsi domeniul potrivit:',
        'domainSearch': 'Verifică disponibilitate',
        'domainAuctions': 'Licitații domenii',
      },
      'myAccountView': {
        'title': 'Contul meu',
        'overview': 'Panou general',
        'accountDetails': 'Detalii cont',
        'billingDetails': 'Detalii facturare',
        'myAuctions': 'Licitațiile mele',
        'myDomains': 'Domeniile mele',
        'accountSecurity': 'Securitatea contului',
        'accountSettings': 'Setările contului',
      },
      'myAccountOverviewView': {
        'editUserDetails': 'Editează datele contului pentru capturare sau înregistrare domenii. Validare număr de telefon.',
        'editBillingDetails': 'Editează datele de facturare.',
        'activeAuctionsLatestBids': 'Vezi licitațiile tale curente.',
        'changeDnsReviewDomain': 'Modifică nameservere sau vezi lista cu domeniile tale.',
        'reviewAdjustSecurity': 'Revizuire sau modificare setări de securitate.',
      },
      'myAccountCardNavList': {
        'quickAccess': 'Accesează direct',
        'frequentlyAccessedSettings': 'Setări accesate frecvent:',
        'addEditUserDetails': 'Adăugați sau editați date personale folosite pentru înregistrarea domeniilor',
        'chooseDefaultDetails': 'Alegeți datele utilizate în mod implicit (default) pentru înregistrarea domeniilor',
        'addEditBillingDetails': 'Adăugați sau editați date de facturare',
        'showActiveAuctions': 'Afișați toate licitațiile active la care participați',
        'manageNameservers': 'Gestionați nameservere',
        'goToDomains': 'Vezi domeniile tale',
        'phoneValidation': 'Validează numărul de telefon',
        'changeDefaultLanguage': 'Schimbați setările de limbă',
      },
      'navigationHeader': {
        'home': 'Home',
        'domainNames': 'Înregistrare Domenii',
        'domainAuctions': 'Licitații Domenii',
        'buyWebsite': 'Cumpără Websites',
        'sellWebsite': 'Vinde Websites',
        'whoisLookup': 'Whois Domenii',
        'myAccount': 'Contul Meu',
      },
      'newsletter': {
        'weekly': 'Săptămânal',
        'everyOtherWeek': 'O dată la două săptămâni',
        'monthly': 'Lunar',
        'unsubscribe': 'Dezabonare',
        'subscribe': 'Abonare',
        'confirmation': 'Preferințele dumneavoastră au fost actualizate',
        'changePreferences': 'Schimbă preferințe',
        'pleaseChoose': 'Vă rugăm să alegeți o opțiune',
        'email': 'Adresă de email *',
      },
      'paymentResponse': {
        'result': {
          'fail': 'Plată eșuată!',
          'success': 'Plată reușită!',
        },
        'paymentResponseTitle': 'Mulțumim pentru plată',
        'paymentResponSubtitle': 'Un email de confirmare a fost trimis la adresa de email înregistrată. Vă rugăm să verificați inbox-ul pentru a vedea detaliile tranzacției.',
        'domainRegisterSuccess': 'Domeniul tău a fost înregistrat cu succes.',
        'paymentFailedRetry': 'Plata nu a fost procesată cu succes. Te rugăm să inițiezi din nou procesul de plată.',
      },
      'phoneVerification': {
        'connectionError': 'Eroare de conexiune',
        'pageCouldNotLoad': 'Informațiile nu s-au putut încărca, te rugăm să verifici conexiunea la internet. Dacă problema persistă, ne poți contacta la:',
        'phoneVerified': 'Numărul de telefon - confirmat',
        'phoneVerificationDetails': 'Numărul de telefon este folosit pentru verificarea identității și pentru securizarea contului',
        'confirmPhone': 'Confirmă numărul de telefon',
        'retryCode': 'Retrimite cod de activare',
        'changePhone': 'Schimbă numărul de telefon',
        'retryCodeConfirmation': 'Un cod nou de activare a fost trimis cu succes către: ',
        'sendActivationCode': 'Trimite cod de activare',
        'smsSent': 'SMS-ul cu codul de activare a fost trimis cu succes către:',
        'enterActivationCode': 'Introduceți codul de activare primit',
        'pleaseEnterActivationCode': 'Te rugăm să introduci codul de activare',
        'confirmNumber': 'Confirmă numărul',
        'numberSuccessfullyConfirmed': 'Numărul de telefon a fost confirmat cu succes!',
        'goToAuctions': 'Mergi la licitații',
      },
      'privacyPolicyView': {
        'title': 'Politica de Confidențialitate',
        'summary': {
          'subtitle': 'Sumar',
          'content1': 'Inreg Solutions SRL prelucrează informațiile dumneavoastră personale pentru a vă putea furniza produse și servicii conforme unui standard înalt de calitate, site-uri web și aplicații mobile (în continuare denumite în mod colectiv „Serviciile”). De asemenea, colectează și folosește datele personale în următoarele scopuri: pentru a oferi răspunsuri întrebărilor și solicitărilor trimise către Inreg, analizelor de marketing, comunicărilor personalizate și marketingului direct.',
          'content2': 'Ca parte a prelucrării în aceste scopuri, putem dezvălui și transmite informațiile dumneavoastră furnizorilor care acționează în numele Inreg pentru servicii precum: suportul IT, suport pentru site, vânzări și servicii de marketing.',
          'content3': 'Această Politică are scopul de a vă explica care sunt datele pe care Inreg Solutions SRL le procesează, de ce și cum folosim datele personale și care sunt drepturile dumneavoastră.',
          'content4': 'Dacă în orice moment aveți întrebări cu privire la Serviciile Inreg, puteți contacta responsabilul cu protecția datelor -„RPD”, și echipa dedicată în cadrul Inreg care sprijină acest birou, contactându-ne la adresa contact@inreg.ro / contact@inreg.co.uk. Această căsuță de email este monitorizată și gestionată în mod activ, deoarece protecția datelor este în egală măsură importantă pentru Inreg, cum este și pentru dumneavoastră.',
        },
        'about': {
          'subtitle': 'Despre Inreg și Politica de Confidențialitate',
          'content1': 'Această Politică de Confidențialitate (denumită în continuare „Politica”) este furnizată de către Inreg Solutions SRL ("Inreg", "Inreg Solutions") care este un operator de date cu caracter personal pentru datele pe care le prelucrează în conformitate cu Regulamentul General privind Protecția Datelor (UE) 2016 / 679 și Legea privind Protecția Datelor 2018 (în continuare denumite colectiv "Legile privind Protecția Datelor"). Păstrarea datelor dumneavoastră în siguranță este importantă pentru Inreg, iar această Politică de Confidențialitate vă prezintă care sunt datele personale procesate de Inreg, de ce stochează datele, care este temeiul juridic și care sunt drepturile dumneavoastră. Vă rugăm să citiți cu atenție această Politică.',
        },
        'what': {
          'subtitle': 'Ce informații colectăm?',
          'content1': 'Inreg își propune să vă ofere o experiență personalizată cât mai bună, iar, în același timp, să își îmbunătățească și optimizeze performanța. În acest scop, datele personale sunt colectate direct de la dumneavoastră atunci când:',
          'listItem1': '(i) vă înregistrați și completați datele din secțiunea "Contul Meu" sau achiziționați oricare dintre Serviciile Inreg;',
          'listItem2': '(ii) completați formularele de contact sau solicitați actualizări, buletine de știri, comunicări personalizate sau participați la concursuri sau sondaje, aplicați pentru o poziție deschisă în societatea Inreg Solutions sau participați în alte activități care ar putea necesita informațiile dumneavoastră personale;',
          'listItem3': '(iii) solicitați asistență / suport.',
          'content2': 'Datele dumneavoastră personale includ informații precum:',
          'listItem4': 'Datele de contact - Acestea includ numele dumneavoastră complet, adresa sau oricare alte informații de contact, inclusiv număr de telefon și e-mail, dar și alte date colectate care vă pot identifica direct sau indirect.',
          'listItem5': 'Utilizarea site-ului web, a comunicațiilor electronice și utilizarea aplicației pe dispozitive - Inreg colectează informații precum deschiderea sau interacțiunile prin e-mail sau dacă utilizați orice platforma Inreg, broșuri online. Inreg colectează informații privind folosirea si comportamentul online cu ajutorul cookie-urilor, IP-ul dumneavoastră sau alte tehnologii de tracking, cum ar fi paginile vizitate, de unde ați accesat platforma, istoricul dumneavoastră de navigare și când / unde părăsiți platforma, browser-ul, tipul de dispozitiv, durata navigării inclusiv colectarea detaliilor privind localizarea dispozitivului mobil (dacă este permisă în setările aplicației), click-urile efectuate, produsele sau serviciile achiziționate, diverse date inclusiv analytics și alte tipuri de interacțiuni cu Serviciile Inreg. Puteți citi mai multe despre cookies pe inreg.co.uk/cookies sau inreg.ro/cookies.',
          'content3': 'Nu toate metodele prin care se colectează date sunt evidente pentru dumneavostră, iar ele pot varia dintr-un moment în altul. Prin urmare, vom evidenția și explica care ar putea fi acestea.',
          'content4': 'Date generale privind contul - identificatorul contului, achizițiile, datele la care produsele expiră sau sunt reînnoite sau informații similare trimise sau solicitate.',
          'content5': 'Cookie-urile și tehnologiile de tracking - după cum se prezintă mai jos, folosim cookie-uri și analytics. Vă rugăm să vizitați inreg.co.uk/cookies sau inreg.ro/cookies pentru a citi mai multe în legătură cu acestea.',
          'content6': 'Date despre utilizarea Serviciilor - acest tip de date este de obicei colectat automat. Acestea includ jurnalul de activitate, metadatele, timpul de încărcare al paginii și parametrii de performanță, locația aproximativă pentru a îmbunătăți Serviciile Inreg (optimizarea rezoluției DNS, rutarea, configurarea rețelei și a server-ului), preferințele de limbă, informațiile despre configurația browser-ului și plugin-urile și datele cookie-urilor. De asemenea, pot fi colectați identificatori unici ai dispozitivului și date privind erorile.',
          'content7': 'Pot fi solicitate date suplimentare din alte surse, iar acestea pot include informații despre dumneavoastră. Acestea includ baze de date accesibile publicului sau terțe părți de la care Inreg a adunat sau cumpărat date. Pentru a vă oferi o experiență mai bună, aceste informații pot fi combinate cu datele deja existente colectate și stocate. Scopul principal al acestei practici este îmbunătățirea exactității înregistrărilor, identificarea de noi potențiali clienți și furnizarea de produse și servicii personalizate. În cazul în care ne furnizați informații personale despre alte entități sau despre dumneavoastră, vom utiliza acele informații numai pentru scopul specific pentru care le-ați furnizat.',
        },
        'how': {
          'subtitle': 'Cum utilizăm datele dumneavoastră și baza legală pentru procesare.',
          'content1': 'Obiectivul Inreg este de a procesa cantitatea minim posibilă de date despre dumneavoastră și de a limita utilizarea și scopul numai la datele pentru care a fost acordată permisiunea; de a procesa doar datele necesare pentru a furniza în mod corespunzător Serviciile; sau cum ar putea fi solicitat pentru respectarea legislației sau privitor la alte scopuri legale. Pentru a face posibile toate acestea, am luat în considerare impactul asupra intereselor și drepturilor dumneavoastră și am luat măsurile de protecție adecvate pentru a ne asigura că intruziunea confidențialității este cât se poate de redusă în acest context.',
          'content2': 'Inreg folosește datele dumneavoastră personale în următoarele scopuri și este permis să facă acest lucru, pe următoarele temeiuri juridice:',
          'listItem1': '• Utilizarea serviciilor / solicitările clienților - Pentru a opera și a îmbunătăți Serviciile Inreg și a îndeplini cererile transmise, cum ar fi: înregistrarea unui nume de domeniu, cumpărarea unui plan de găzduire, solicitare de marketing sau alt tip de solicitare prin e-mail, sau orice platformă Inreg.',
          'listItem2': '•	Diagnosticarea și remedierea unei probleme: Pentru identificarea eventualelor riscuri de securitate, erori sau pentru îmbunătățirea Serviciilor.',
          'listItem3': '•	Detectarea și prevenirea abuzurilor asupra sistemelor Inreg.',
          'listItem4': '•	Colectarea și agregarea statisticilor de analiză.',
          'listItem5': '•	Înțelegerea utilizării Serviciilor Inreg de către dumneavoastră și alți clienți, astfel încât recomandările oferite să fie relevante.',
        },
        'thirdParties': {
          'subtitle': 'Partajarea către părți terțe',
          'content1': 'Datele personale pot fi distribuite societăților afiliate, partenerilor și furnizorilor de servicii terțe ce prezintă încredere, dacă este necesar pentru a furniza servicii în numele Inreg (de exemplu, gestionarea relației cu clienții, procesarea plăților - inclusiv plățile prin carduri de credit, servicii de marketing și publicitate, chestionare, statistici, concursuri, date demografice ale utilizatorilor, comunicări).',
          'content2': 'În cazul unei fuziuni, achiziții sau vânzări a tuturor sau a unei mări părți din activele Inreg, există posibilitatea ca informațiile să fie împărtășite cu noua entitate sau împărtășite altor terțe părți cu scopul de a facilita acest demers. Societatea achizitoare / entitatea poate continua să proceseze și să utilizeze datele dumneavoastră personale în modul și scopul pentru care au fost furnizate.',
        },
        'communication': {
          'subtitle': 'Canale de comunicare',
          'content1': 'Canalele de comunicare pot include e-mailuri, mesaje text, apeluri telefonice (inclusiv cele automate)',
          'content2': 'Puteți să vă actualizați preferințele de subscripție cu privire la primirea comunicărilor de la Inreg și / sau partenerii Inreg, contactând-ne la contact@inreg.ro / contact@inreg.co.uk.',
          'content3': 'Dacă considerați că informații personale despre dumneavoastră au fost furnizate către Inreg si doriți să le eliminați din baza noastră de date, vă rugăm să trimiteți o cerere la adresa contact@inreg.ro / contact@inreg.co.uk.',
          'content4': 'Dacă faceți o solicitare de ștergere a datelor dumneavoastră personale și dacă aceste date sunt necesare pentru produsele sau Serviciile pe care le-ați achiziționat, solicitarea va avea efect numai în măsura în care datele nu mai sunt necesare pentru Serviciile achiziționate sau solicitate pentru scopurile legitime ale Inreg sau cerințele legale sau contractuale de păstrare a înregistrărilor.',
        },
        'transfer': {
          'subtitle': 'Transferul datelor cu caracter personal în străinătate',
          'content1': 'Dacă utilizați Serviciile dintr-o altă țară decât țara în care se află sistemele Inreg, comunicările dumneavoastră cu Inreg pot duce la transferarea datelor peste granițele internaționale. Dacă apelați sau contactați Inreg prin mesaj text, prin chat sau prin e-mail, vă putem oferi asistență de la una dintre locațiile globale ale Inreg, din afara țării de origine. În orice caz, datele dumneavoastră personale sunt tratate în conformitate cu această Politică de Confidențialitate.'
        },
        'regulatory': {
          'subtitle': 'Respectarea cerințelor legale, de reglementare și de aplicare a legii',
          'content1': 'Inreg colaborează cu autoritățile și instituțile publice pentru aplicarea și respectarea legii. Inreg va dezvălui orice informații despre dumneavoastră autorităților sau instituțiilor publice sau alte parți terțe private, dacă consideră că este necesar sau adecvat să răspunda cererilor și procedurilor legale, pentru protejarea proprietatii și drepturilor Inreg sau pentru a proteja proprietatea și drepturile unei părți terțe, pentru a proteja siguranța publicului sau a oricărei persoane sau pentru a preveni sau a opri activitatea considerată ilegală sau neetică de către Inreg.',
          'content2': 'Inreg va lua măsurile rezonabile pentru a vă informa în cazul în care primește o solicitare de a furniza informațiile dumneavoastră personale terților în cadrul procedurii legale, în măsura în care este permis în mod legal să se întâmple această acțiune. Inreg va dezvălui datele dumneavoastră personale după cum este necesar pentru a se conforma cu ICANN sau cu orice alte reguli, reglementări și politici ale ccTLD. Din motive ce țin de menținerea securității, stabilității și rezilienței internetului, se poate recurge la transferul informațiilor legate de înregistrarea numelui de domeniu către registrul domeniului sau furnizorul de servicii escrow și publicarea acelor informații, conform cerințelor ICANN, în baza de date WHOIS publică.'
        },
        'thirdPartyWebsites': {
          'subtitle': 'Site-uri terțe',
          'content1': 'Site-ul web și aplicațiile mobile Inreg conțin legături către site-uri terțe. Nu ne facem responsabili pentru practicile de confidențialitate sau pentru conținutul site-urilor terțe. Citiți politica de confidențialitate a oricărui site pe care îl vizitați.',
        },
        'duration': {
          'subtitle': 'Cât timp păstrăm informațiile?',
          'content1': 'Aplicăm standarde general acceptate pentru a păstra în siguranță datele personale pe care le colectăm începând cu momentul în care datele sunt colectate si până în momentul  în care sunt procesate, primite și stocate. Prelucrarea datelor este supusa criptarii, dupa caz.',
          'content2': 'Stocarea datelor implica asocierea cu ID-uri unice și aceste date sunt păstrate doar atât timp cât este necesar pentru a furniza Serviciile solicitate și ulterior pentru o varietate de scopuri legale sau comerciale legitime. Acestea pot include perioade de păstrare mandatate de lege, obligatii contractuale sau obligații similare aplicabile sau necesare pentru gestionarea înregistrărilor financiare sau de business sau pentru păstrarea, rezolvarea, apărarea sau executarea drepturilor legale sau contractuale ale Inreg și pentru a putea raspunde oricăror întrebări pe care le-ați putea avea in legatura cu Serviciile sau pentru a susține orice proces de revendicare legală – Inreg poate păstra aceste date pentru o perioadă nedeterminată, dacă exista obligația legală de a face acest lucru.',
        },
        'age': {
          'subtitle': 'Restricții de vârstă',
          'content1': 'Serviciile Inreg sunt destinate achiziționării numai de către persoanele cu vârsta peste 18 ani. Serviciile Inreg nu sunt vizate sau destinate să atragă persoane sub vârsta de 18 ani. Dacă știți sau aveți motive să credeți ca cineva sub vârsta de 18 ani a furnizat date personale către Inreg, vă rugăm să contactați Inreg la contact@inreg.ro / contact@inreg.co.uk.',
        },
        'rights': {
          'subtitle': 'Drepturile tale',
          'content1': 'În conformitate cu legile privind protecția datelor, aveți următoarele drepturi referitoare la datele dumneavoastră personale:',
          'listItem1': '•	Dreptul de a vă opune prelucrării datelor dumneavoastră personale',
          'listItem2': '•	Dreptul de a solicita o copie a datelor dumneavoastră personale și informații despre modul în care le procesăm',
          'listItem3': '•	Dreptul de a solicita să vă mutați, să copiați sau să transferați datele dumneavoastră personale (portabilitatea datelor)',
          'listItem4': '•	Dreptul ca datele dumneavoastră personale să fie șterse',
          'listItem5': '•	Dreptul la corectarea datelor dumneavoastră personale în timp util',
          'listItem6': '•	Dreptul de a ne cere să nu vă mai contactăm cu marketingul direct',
          'listItem7': '•	Dreptul de a restricționa prelucrarea datelor dumneavoastră personale',
          'content2': 'Pentru a vă exercita drepturile, vă rugăm să ne contactați folosind datele de contact furnizate în acest document. Vă vom cere informații pentru a vă confirma identitatea și vom răspunde la solicitarea dumneavoastră în termen de 30 de zile de la confirmarea identității.',
        },
        'changes': {
          'subtitle': 'Modificări la Politica de Confidențialitate',
          'content1': 'Întrucât Inreg dezvoltă noi produse și servicii și noi utilizări ale informațiilor apar, Politica de Confidențialitate este revizuită și actualizată pentru a reflecta aceste schimbări. În plus, din când în când, se fac schimbări organizaționale, stilistice și gramaticale pentru prezentarea practicilor Inreg într-un mod care face Politică de Confidențialitate ușor de citit. Inreg își rezervă dreptul de a modifica oricând această Politică de Confidențialitate. Dacă se decide asupra unei sau mai multor modificări, aceasta / aceste modificări vor fi postate în această Politică de Confidențialitate și în orice alte locuri pe care Inreg le consideră adecvate, astfel încât să știți ce informații sunt colectate, cum sunt folosite și în ce circumstanțe Inreg ar putea să le dezvăluie. Veți fi notificat despre modificări prin e-mail sau prin intermediul unui anunț pe platformele Inreg, cu cel puțin treizeci (30) de zile înainte de implementarea modificărilor.',
        },
        'contact': {
          'subtitle': 'Cum contactați Inreg',
          'content1': 'Dacă doriți să luati legatura cu Inreg, să va modificați detaliile personale, să modificați orice preferințe de marketing, să adresați întrebări legate de confidențialitate sau daca aveti orice alte nelămuriri, vă rugăm să contactați Inreg, după cum urmează:',
          'listItem1': '•	Prin e-mail: contact@inreg.ro / contact@inreg.co.uk',
          'listItem2': '•	Prin poștă: Oficiul DPO, str. Fabricii 47, clădirea H, etajul 1, ap. 3, sector 6, 060822, București, România',
          'listItem3': '•	Telefon: (+40) 740.457.486',
          'content2': 'Inreg răspunde la solicitări, întrebări sau nelămuriri în termen de treizeci (30) de zile, conform mențiunii de mai sus din acest document.',
        },
        'copyright': {
          'content1': 'Drepturi de Autor © 2020 Inreg Solutions, SRL. Toate drepturile sunt rezervate.'
        },
      },
      'promoBannerBlock': {
        'title': 'Voucher',
        'dialogContent': 'Poti folosi voucherul pentru Lorem Ipsum. Reducerea de <strong>30%</strong> se supune condițiilor promoției.',
        'description': 'Voucher 30% reducere. ',
        'codeLabel': 'Folosește codul: '
      },
      'resetPasswordView': {
        'title': 'Te rugăm să introduci noua parolă',
        'resetPasswordInProgress': 'Se resetează parola...',
        'passwordSuccessfullyChanged': 'Parola a fost schimbată cu succes!',
        'confirmationEmailSent': 'Email de confirmare trimis.',
        'mainPage': 'Pagina principală',
        'myAccount': 'Contul meu',
        'password': 'Parolă',
        'minLengthPassword': 'Te rugăm să introduci o parolă cu minim 6 caractere',
        'confirmPassword': 'Confirmă parola',
        'enterPasswordAgain': 'Te rugăm să introduci parola încă o dată pentru verificare',
        'passwordsMustBeIdentical': 'Parola trebuie să fie identică cu cea introdusă mai sus'
      },
      'searchBlockHeader': {
        'findDomains': 'Începeți căutarea domeniului',
        'snackbarError': 'Poți căuta simultan cel mult 3 domenii',
        'snackbarErrorEmptyDomainNames': 'Acest câmp este obligatoriu, vă rugăm să introduceți un domeniu',
        'emptyDomainNamesError': 'Introduceți un domeniu în acest câmp',
        'domainTransfer': 'transferă un domeniu',
        'toInreg': 'la Inreg'
      },
      'searchedDomains': {
        'domainAvailable': 'Domeniu disponibil',
        'domainUnavailable': 'Domeniu indisponibil',
        'isAvailable': 'este disponibil',
        'isUnavailable': 'nu este disponibil',
        'addBtn': 'Adaugă toate domeniile selectate în coș',
        'learnMoreBtn': 'Află mai multe',
        'makeOfferBtn': 'Fă o ofertă',
        'viewBtn': 'Vezi în coș',
        'confirmation': 'Domeniu adăugat în coș',
        'youSearchedFor': 'Ați căutat domeniile:',
        'similarDomains': 'Domenii similare:',
        'year': 'an',
      },
      'secureLock': {
        'text': 'este securizat'
      },
      'securedWith': {
        'securedWith': 'Securizat cu',
        'letsEncryptAlt': 'Let\'s Encrypt logo',
        'cloudflareAlt': 'Cloudflare logo'
      },
      'shortcutMenu': {
        'cartItems': 'Coșul meu',
        'contactUs': 'Contactează-ne',
        'contactUsMain': 'În cazul în care ai întâmpinat probleme în timpul utilizării platformei te rugăm să ne furnizezi cât mai multe detalii astfel încât să putem identifica și rezolva problema cât mai rapid.',
        'blog': 'Blog',
        'myAccount': 'Contul meu',
        'serviceStatus': 'starea serviciilor',
      },
      'signInView': {
        'title': 'Autentificare',
        'password': 'Parolă',
        'goToSignUp': 'Creează un cont Inreg',
        'forgotPassword': 'Ați uitat parola?',
        'personalDataPrivacy': 'Te asigurăm că tratăm cu maximă seriozitate modul în care avem grijă de datele tale.',
        'personalDataPrivacyLabelBefore': 'Poți consulta',
        'privacyPolicyLinkText': 'Politica de Confidențialitate',
        'personalDataPrivacyLabelAfter': 'aici.'
      },
      'signUpView': {
        'title': 'Creează gratuit un cont',
        'activationEmailSent': 'Un email de activare al contului a fost trimis pe adresa',
        'accessActivationLink': 'Te rugăm să accesezi linkul din email pentru finalizarea înregistrării.',
        'navigateToMainPage': 'Navighează către pagina principală:',
        'mainPage': 'Pagina principală',
        'congratsSuccessfullyRegistered': 'Felicitări! Contul a fost activat cu succes.',
        'password': 'Parolă',
        'minLengthPassword': 'Te rugăm să introduci o parolă cu minim 6 caractere',
        'confirmPassword': 'Confirmă parola',
        'enterPasswordAgain': 'Te rugăm să introduci parola încă o dată pentru verificare',
        'passwordsMustBeIdentical': 'Parola trebuie să fie identică cu cea introdusă mai sus',
        'newsletterConsent': 'Vreau să primesc oferte personalizate, noutăți, și informații despre promoțiile, produsele, și serviciile Inreg.',
        'createAccountBtn': 'Creează cont',
        'userSaved': 'Utilizatorul',
        'userSaved2': 'a fost înregistrat cu succes',
        'goToSignIn': 'Ai deja cont Inreg? Conectează-te'
      },
      'similarDomains': {
        'loading': 'Se încarcă lista cu licitații...',
        'noUserDomains': 'Nu am găsit niciun domeniu asociat cu acest cont',
        'addBtn': 'Adaugă în coș',
        'labels': {
          'expireDate': 'Data expirării',
          'name': 'Nume domeniu',
          'nameservers': 'Nameservere',
          'privateNameservers': 'Namservere private',
          'domainStatus': 'Starea domeniului',
        },
        'error': {
          'connectionError': 'Informațiile nu s-au putut încărca, te rugăm să verifici conexiunea la internet. Dacă problema persistă, ne poți contacta la:',
        },
      },
      'snackbar': {
        'copied': '',
        'emailAddress': 'Email de contact',
        'phoneNumber': 'Număr de telefon',
        'toClipboard': 'copiat in clipboard!',
        'congratulations': 'Felicitări! Contul a fost activat cu succes.',
      },
      'termsAndConditions': {
        'privacyPolicy': 'Politica de Confidențialitate',
        'termsAndConditions': 'Termeni și Condiții',
        'usageConsent': 'Utilizarea site-ului ca și vizitator, înregistrarea ca și utilizator (sau organizație), înscrierea la newsletter și accesul la serviciile oferite prin intermediul acestuia presupune implicit că ați citit, înțeles și acceptat integral',
        'usageConsent2': 'precum și',
        'usageConsent3': '.'
      },
      'termsView': {
        'title': 'Termeni și Condiții - Inreg',
        'content': {
          'content0': 'Prin finalizarea comenzii online, efectuarea plății și prin utilizarea serviciilor furnizate de Inreg Solutions SRL, dumneavoastră confirmați că ați citit Termenii și Condițiile și sunteți de acord cu toate condițiile acestora, precum și cu orice reguli sau politici publicate de către Inreg Solutions SRL, Nominet UK - nominet.uk (aplicabile în mod special pentru numele de domeniu cu extensia ".co.uk" și ".uk") și ICI - RoTLD - rotld.ro (aplicabile în mod special pentru numele de domeniu cu extensia ".ro").',
          'content0a': 'Aceste Condiții Generale se vor aplica tuturor tranzacțiilor / serviciilor prestate și respectivele societăți de furnizare ale societății Inreg Solutions SRL, inclusiv celor viitoare.',
          'content0aBIS': 'Detaliile companiei. Nume: Inreg Solutions SRL, CUI / VAT ID: RO 40281673, Nr. de Înregistrare: J40/17616/2018, Adresa: str. Fabricii 47, clădirea H, etajul 1, ap. 3, sector 6, 060822, București, România.',
          'content0b': 'Contractele (comenzi și confirmări de comenzi) și anulările, inclusiv orice modificări sau schimbări ale acestora, vor fi efectuare în scris.',
          'content0c': 'Inreg Solutions SRL își rezervă dreptul de a subcontracta serviciile către terți.',
          'content0d': 'În cazul încetării anticipate a contractului de către Beneficiar, Inreg Solutions SRL va avea dreptul la o compensație rezonabilă a serviciilor sale.',
          'content0e': 'Dreptul de proprietate și dreptul de autor asupra tuturor documentelor furnizate celeilalte Părți vor rămâne ale Părții expeditoare.',
          'subtitle1': 'Alegerea numelui de domeniu și / sau pachetului de servicii',
          'content1': 'Decizia alegerii numelui de domeniu care este atașat oricărui serviciu sau pachetului de servicii aparține în totalitate dumneavoastră iar responsabilitatea verificării vă aparține exclusiv dumneavoastră. Nu reprezintă responsabilitatea noastră verificarea dacă prin rezervarea, înregistrarea sau înnoirea dreptului de folosință al unui domeniu se aduc prejudicii de orice fel. Nu reprezintă responsabilitatea noastră dacă prin folosirea domeniului / serviciului, în mod direct sau indirect, se încalcă drepturile legale ale unei părți și / sau terțe părți sau dacă numele domeniului folosit încalcă drepturile legale ale altor părți. În consecință, cade în sarcina dumneavoastră verificarea dacă numele selectat sau utilizarea acestuia încalcă drepturile legale ale altor părți. Sunteți informat și de acord că în cazul unei solicitări motivate din partea unei instanțe judecătorești și / sau altei instituții vom anula, modifica sau transfera domeniul și este responsabilitatea dumneavoastră să întocmiți o listă exactă cu informațiile de contact și să comunicați cu părțile litigante, posibil litigante și autoritățile guvernamentale. Nu este responsabilitatea noastră să vă aducem la cunoștință hotărârile judecătorești și / sau alte comunicații de acest tip.',
          'subtitle2': 'Despăgubiri',
          'content2': 'Inreg Solutions SRL este exonerată de răspundere față de orice cerere de despăgubire, sancțiune administrativă sau penală, pretenții civile, etc., având ca obiect o încălcare de către dumneavoastră a unui drept de proprietate intelectuală sau a altui drept al unui terț, sau rezultat din violarea oricăror reguli de operare sau politici legate de serviciile furnizate. Dumneavoastră vă revine obligația de despăgubire imediată și necondiționată față de Inreg Solution SRL și toate părțile implicate, inclusiv RoTLD pentru orice prejudiciu suferit ca urmare a acestor cereri, sancțiuni sau pretenții, pentru toate plângerile, pagubele, riscurile, costurile, inclusiv taxele legale, care sunt consecința directă sau indirectă a înregistrării sau utilizării domeniului rezervat / înregistrat de dumneavoastră, pentru folosirea ilegală a domeniului, sau în cazul încălcării acestei convenții de către dumneavoastră. Inreg Solutions SRL va fi îndreptățită să ceară și să fie despăgubită în totalitate pentru daunele suferite în legătură cu numele dumneavoastră de domeniu.',
          'subtitle3': 'Plata taxelor',
          'content3': 'Sunteți de acord să ne plătiți taxele aplicabile pentru serviciul / serviciile în cauză. Toate taxele sunt nerambursabile, în afara situațiilor în care vom specifica altfel.',
          'subtitle4': 'Expirarea și reînnoirea serviciului',
          'content4': 'Ținerea evidenței informațiilor legate de expirarea domeniului sau a altor servicii cade în responsabilitatea dumneavoastră. Cu toate acestea, vă putem trimite o înștiințare prin intermediul email-ului când urmează să expire domeniul înregistrat de dumneavoastră.',
          'subtitle5': 'Transferul',
          'content5': 'Sunteți conștient și de acord că transferul și serviciile de transfer ale domeniului sunt guvernate de politicile / termenii și condițiile RoTLD. Cu toate acestea trebuie să aveți în vedere că politicile / termenii și condițiile RoTLD se pot schimba ocazional. Găsiți informațiile pe pagina web RoTLD: rotld.ro. Uneori pot apărea erori la cererea de înregistrare de aceea trebuie să știți că toate riscurile pentru eșecul unui transfer cad în responsabilitatea dumneavoastră.',
          'subtitle6': 'Politica particulară',
          'content6': 'Sunteți conștient și sunteți de acord ca noi să trimitem informațiile înregistrate pe care ni le-ați furnizat, și pe care le stocăm, către registrul de administrare RoTLD sau altei terțe părți. Prin prezenta renunțați irevocabil la toate pretențiile și cauzele de acțiune pe care le-ați putea cere din această informare. De asemenea sunteți conștient că RoTLD poate modifica cerințele sau informațiile pe care dumneavoastră trebuie să ni le furnizați, dar și modul în care acestea sunt făcute disponibile către ei sau alte terțe părți. Mai multe informații găsiți pe rotld.ro.',
          'subtitle7': 'Confidențialitate',
          'content7': 'Atât dumneavoastră cât și noi suntem de acord să nu utilizăm datele furnizate în alte scopuri decât cele convenite și să nu dezvăluim datele confidențiale către altă parte. Clauza nu se aplică acelor date pentru care ni se permite să le dezvăluim conform acestei convenții, datelor dezvăluite angajaților, furnizorilor sau altora pentru respectarea corespunzătoare a convenției, datelor care ne sunt cerute de către regulamentul sau autoritatea competentă, datelor care intră în domeniul public și datelor necesare furnizării serviciilor.',
          'content7a': 'Niciuna dintre Părți nu va dezvălui și nu va utiliza nicio informație comercială sau tehnica primită de la cealaltă Parte pe parcursul relației comerciale ale acestora, în măsura în care respectiva informație nu este publică.',
          'content7b': 'Părțile se vor asigura că Subcontractanții sunt obligați în consecință.',
          'content7c': 'Orice referire la relația comercială dintre Părți în materialele publicitare sau de promovare necesită acordul prealabil în scris al Inreg Solutions SRL.',
          'content7d': 'Obligațiile menționate mai sus vor expira după 36 de luni de la încheierea relației comerciale dintre Părți. În plus, acestea nu se vor aplica în măsura în care dezvăluirea informațiilor sau a datelor este necesară în baza deciziei unei autorități publice sau a unei instanțe cu jurisdicție competență sau în legătură cu apărarea în orice proces inițiat împotriva Părții care face dezvăluirea.',
          'content7e': 'Know-how-ul dezvăluit de Inreg Solutions SRL pe parcursul procesului de licitație va fi protejat integral conform prezentului articol. Conceptul tehnic al  Inreg Solutions SRL în cadrul fazei de licitație nu va fi nici folosit de Beneficiar / Cumpărător în beneficiul propriu, nici nu va fi dezvăluit terților.',
          'subtitleServiceDelivery': 'Termenii serviciilor și livrării',
          'contentServiceDelivery': 'Prin utilizarea serviciilor noastre, sunteți de acord cu următoarele: Majoritatea ofertelor la licitații sunt obligatorii și definitive. Licitațiile câștigate implică o taxă de livrare echivalentă cu taxele de înregistrare atașate numelui de domeniu. Intervalul de timp pentru activarea, transferul și livrarea domeniilor variază, de obicei între 1 și 10 zile lucrătoare, în funcție de procedurile de procesare și administrative. Deși facem toate eforturile rezonabile pentru a finaliza toate procedurile prompt, nu garantăm date specifice de livrare. Plata integrală, inclusiv orice taxă de livrare aplicabilă, este necesară înainte de finalizarea activării sau transferului domeniului.',
          'subtitlePaymentMethods': 'Metode de plată. Procesator de plăți',
          'contentPaymentMethods': 'Procesatorul de plăți este PayU S.A., o societate constituită și care funcționează conform legislației din Polonia, înregistrată în Registrul Întreprinderilor ținut de Tribunalul Poznań-Nowe Miasto I Wilda din Poznań, sub numărul de înregistrare 0000274399, având sediul social situat în 186 Grunwaldzka, 60-166 Poznań, Polonia. PayU S.A. este procesatorul de plăți integrat în site-ul nostru, cu care Inreg Solutions S.R.L. are o relație contractuală. În plus față de plățile online prin PayU S.A., acceptăm și plăți prin transfer bancar. Ambele metode asigură tranzacții sigure și eficiente pentru serviciile noastre.',
          'subtitleReturnPolicy': 'Politica de retur',
          'contentReturnPolicy': 'Sunteți responsabil pentru verificarea comenzii dvs. înainte de a efectua plata. Fiecare comandă este personalizată pe baza informațiilor furnizate de dvs. și este procesată automat. Prin efectuarea plății, Inreg Solutions S.R.L. consideră că ați acceptat serviciile conform comenzii dvs. Prin urmare, serviciile noastre de captură de domeniu, înregistrare de domeniu sau prelungire a valabilității domeniului nu pot fi anulate, returnate sau rambursate după efectuarea plății.',
          'subtitleComplaintPolicy': 'Politica de reclamații',
          'contentComplaintPolicy': 'Inreg Solutions S.R.L. se angajează să rezolve eficient orice plângere pe care o puteți avea cu privire la serviciul nostru. Dacă am greșit ceva, ne pare rău și, acolo unde este posibil, vom încerca să remediem situația. De asemenea, ne propunem să învățăm din greșelile noastre și să folosim experiența obținută pentru a ne îmbunătăți serviciile în viitor.',
          'content2ComplaintPolicy': 'Inreg Solutions S.R.L. se așteaptă să ne aduceți la cunoștință orice reclamație și să ne oferiți șansa de a remedia situația. Ne propunem sa răspundem oricărei reclamații în maximum 3 zile lucrătoare.',
          'content3ComplaintPolicy': 'Ne puteți contacta la:',
          'content4ComplaintPolicy': 'Email: contact@inreg.ro',
          'content5ComplaintPolicy': 'Telefon: +40 740 457 486',
          'content6ComplaintPolicy': 'Address: Str. Fabricii 47, clădirea H, etajul 1, ap. 3, sector 6, 060822, București, România',
          'subtitle8': 'Durată',
          'content8': 'Această Convenție rămâne în vigoare pe toată durata termenului de înregistrare a domeniului și a altor servicii pentru care ați achitat taxele. În cazul reînnoirii domeniului sau extinderii serviciilor, termenul Convenției va fi extins automat conform cerințelor.',
          'subtitle9': 'Modificări privind termenii și condițiile efectuate de Inreg Solutions SRL',
          'content9': 'Termenii și condițiile prevăzute în această Convenție vor putea fi revizuți sau schimbați. Vă vom înștiința cu privire la modificare și prin acceptarea acestor Termenii și Condiții luați la cunoștință și sunteți de acord să revedeți periodic pagina noastră web (inreg.ro) pentru a avea cunoștință de orice modificare sau revizuire pe care o vom efectua. În cazul în care nu sunteți de acord cu modificarea, puteți să încetați această Convenție în orice moment prin trimiterea unui email sau a unui mesaj de poștă obișnuită atat către Inreg Solution SRL și către RoTLD. Nota de încetare va fi efectivă după primirea și procesarea ei. În cazul în care alegeți să încetați această convenție în urma unei modificări, toate taxele achitate de dumneavoastră sunt nerambursabile. Prin continuarea folosirii serviciilor ce fac obiectul acestor Termeni și Condiții, în urma oricărei revizuiri a acestei Convenții sau modificare a serviciilor, vă dați acordul că veți conforma acestor revizuiri sau schimbări.',
          'subtitle10': 'Suspendarea',
          'content10': 'Sunteți conștient și sunteți de acord că înregistrarea domeniului poate fi suspendată sau anulată pentru corectarea eventualelor erori sau greșeli sau în urma hotărârii dezbaterilor privind domeniul.',
          'subtitle11': 'Limitarea obligațiilor',
          'content11': 'Sunteți informat și de acord că în eventualitatea unor obligații sau despăgubiri din partea noastră, despăgubirea este limitată numai la suma pe care ați achitat-o pentru respectivele servicii. Inreg Solutions SRL nu este răspunzătoare pentru daunele directe, indirecte, întâmplătoare, speciale sau rezultate din utilizarea sau incapacitatea de utilizare a serviciilor sau pentru costul procurării de servicii înlocuitoare. Inreg Solutions SRL nu va fi răspunzatoare pentru daunele pricinuite prin folosirea domeniului de către dumneavoastră sau altă persoană. Inreg Solutions SRL nu este răspunzatoare de nicio pierdere sau de nicio acuzație care rezultă din aceasta, dar nu este limitată la: (1) pierderi sau acuze rezultate din întârzieri sau întreruperi ale accesului; (2) pierderi sau acuze rezultate din netransmiterea integrală sau parțială a datelor; (3) pierderi sau acuze rezultate din erori, omisiuni, declarații incorecte despre oricare sau toateserviciile furnizate în cadrul acestei înțelegeri; (4) pierderi sau acuze rezultate din operații de dezvoltare sau întrerupere a site-ului web sau a serviciului de email ale dumneavoastră.',
          'subtitle12': 'Absolvirea de răspundere',
          'content12': 'Sunteți conștient și sunteți de acord că un asemenea serviciu este furnizat "așa cum este" și "așa cum este disponibil". Astfel, Inreg Solutions SRL este absolvit de orice răspundere de orice fel, expresă sau implicită. Nu putem garanta că serviciile vor atinge cerințele dumneavoastră, sau că acestea vor fi neîntrerupte în timp, sigure, sau lipsite de erori. De asemenea, nu putem garanta rezultatele ce pot fi obținute din utilizarea serviciului(iilor) sau acuratețea și corectitudinea informațiilor obținute prin intermediul email-ului sau că defectele din serviciile software vor fi corectate. Înțelegeți și sunteți de acord că orice materiale și / sau orice date descărcate sau altfel obținute prin utilizarea serviciului, sunt utilizate pe propriul risc și veți fi în întregime responsabil pentru orice stricăciune cauzată sistemului de operare al calculatorului dumneavoastră sau pentru orice pierdere de date care rezultă din descărcarea unor asemenea materiale și / sau date. Niciun sfat sau informație, scrisă, verbală, obținută prin intermediul email-ului nu va crea nicio garanție dacă aceasta nu este prevăzută expres de acest document. Sunteți conștient și de acord că înregistrarea sau preînregistrarea (rezervarea) unui domeniu nu conferă imunitate în cazul obiecțiilor privind înregistrarea, rezervarea, sau utilizarea numelui de domeniu. Nu oferim nicio garanție specifică preînregistrării domeniului întrucât aceasta depinde de foarte mulți factori pe care nu îi putem prevedea.',
          'subtitle13': 'Domeniile .RO',
          'content13': 'Sunteți de acord și consimțiți că respectați "Contractul pentru Înregistrare domenii .RO" (rotld.ro/registration-agreement) precum și "Regulile de Înregistrare" (rotld.ro/reguli-de-înregistrare) așa cum au fost ele stabilite și publicate de către ICI București, operatorul Registrului de domenii .RO (RoTLD).',
          'subtitle14': 'Revizuiri aduse condițiilor generale',
          'content14': 'Sunteți de acord și înțelegeți că noi (Inreg Solutions SRL) și ICI - București (Registrul RoTLD) putem revizui această Convenție și schimba serviciile prevăzute în aceasta. Orice revizuire sau schimbare va fi anunțată prin poștă electronică, sau, după caz, pe pagina de web a Registrului RoTLD (rotld.ro). Sunteți de acord să revedeți pagina web a Registrului RoTLD (rotld.ro) inclusiv căsuța de poștă electronică periodic, pentru a avea cunoștință de orice revizuire. Dacă nu sunteți de acord cu orice revizuire a convenției, puteți înceta această Convenție oricând, anunțându-ne cu 30 de zile înainte prin poștă electronică. În cazul încetării acestei Convenții, orice taxe plătite de către dumneavoastră sunt nerambursabile, cu excepția cazului în care vă informăm contrariul. Sunteți de acord că prin continuarea folosirii serviciilor urmărind orice revizuire a acestei Convenții sau schimbare a serviciilor, veți respecta orice revizuire sau schimbare.',
          'subtitle15': 'Drept de refuz',
          'content15': 'Vă înștiințăm ca ne rezervăm dreptul de a refuza în mod unilateral să rezervăm sau să înregistrăm domeniul, sau să vă înregistrăm pe dumneavoastră pentru alte servicii, sau să ștergem numele dumneavoastră de domeniu în treizeci (30) de zile calendaristice de la confirmarea plății dumneavoastră pentru aceste servicii. În cazul în care refuzăm cele menționate mai sus, sau are loc ștergerea numelui de domeniu, suntem de acord să vă returnăm taxa plătită. Noi nu ne facem răspunzători față de dumneavoastră pentru pierderile sau pagubele ce pot apărea ca urmare a ștergerii sau a refuzului nostru sau al Registrului RoTLD.',
          'subtitle16': 'Separabilitate',
          'content16': 'Sunteți conștient și sunteți de acord că în eventualitatea în care un termen sau o prevedere este declarată invalidă sau că nu este în vigoare, acea prevedere sau termen va fi aplicată și interpretată în acord cu legile aplicabile și în conformitate cu intențiile inițiale ale părților. Restul termenilor și prevederilor rămân în vigoare.',
          'subtitle17': 'Neasociere',
          'content17': 'Acestă convenție nu se interpretează în sensul creării unui parteneriat sau a altei forme de asociere între părți.',
          'subtitle18': 'Vârsta',
          'content18': 'Prin acceptarea serviciilor, dumneavoastră confirmați că aveți vârsta legală pentru a încheia această convenție.',
          'subtitle19': 'Încălcarea contractului',
          'content19': 'Sunteți conștient și de acord ca eșecul dumneavoastră să se supună prevederilor din această convenție, regulilor sau politicilor Inreg Solutions SRL. Este eșecul dumneavoastră dacă informațiile sunt greșite, inexacte sau neadevărate ca parte a procesului de aplicație sau nu vă actualizați informațiile dumneavoastră curente, corecte sau complete. Prin acceptarea convenției sunteți conștient că ați citit și înțeles această convenție și sunteți de acord cu toți termenii și condițiile.',
          'subtitle20': 'Legea aplicabilă',
          'content20': 'Sunteți conștient și de acord că această convenție este supusă legilor statului Român și va fi guvernată și interpretată conform legii romăne. Orice acțiune menționată în această convenție trebuie să fie adusă în România și sunteți de acord irevocabil cu competența judiciară a acestor instanțe. Sunteți de acord cu regulile de dispute stabilite de RoTLD (rotld.ro/dispute-resolution-policy).',
        }
      },
      'topHeader': {
        'domainNames': 'Înregistrare Domenii',
        'domainAuctions': 'Licitații Domenii',
        'buyWebsite': 'Cumpără Websites',
        'sellWebsite': 'Vinde Websites',
        'whoisLookup': 'Whois Domenii',
        'myAccount': 'Contul Meu',
        'aboutUs': 'Despre Noi'
      },
      'domainTransferInfo': {
        'title': 'De ce să transferi domeniul la Inreg?',
        'content': {
          'subtitle1': 'Excelență în servicii de suport',
          'content1': 'Echipa de experți tehnici de primă clasă este oglinda culturii "orientate către client" a companiei noastre. Afirmăm cu multă încredere: Calitatea serviciilor noastre de suport se află în vârful listei de motive pentru care utilizatorii își transferă domeniile la Inreg.',
          'subtitle2': 'Prețul',
          'content2': 'Transferul unui domeniu este 100% gratuit. Prețurile mici pe care le practicam vorbesc de la sine: Costă doar €6.99 / an reînnoirea sau înregistrarea unui domeniu. Fără taxe sau comisioane ascunse. Am analizat competiția pentru a ne asigura că prețurile sunt cele mai mici cu putință.',
          'subtitle3': 'Administrare simplă a domeniilor',
          'content3': 'Instrumentul "Domeniile mele" conține funcționalități precum "Self domain management" și "Nameservere private" facilitând astfel tuturor clienților o gestionare simplă și rapidă a domeniilor pe care le dețin la Inreg.',
          'subtitle4': 'Transferul nu implică riscuri',
          'content4': 'Tehnic este imposibil să pierdeți numele de domeniu în timpul transferului. În plus, transferul este 100% securizat și procesul este monitorizat.',
          'subtitle5': 'Transferul este gratuit!',
          'content5': 'Nu percem nici o taxă pentru transferul domeniilor. Procesul este 100% GRATUIT.'
        },
      },
      'domainTransferInput': {
        'placeholder': 'Scrie numele domeniului',
        'error': {
          'invalid': 'Te rugăm să introduci un nume de domeniu valid.',
          'tldNotSupported': 'Momentan domeniile cu această extensie nu se pot transfera la Inreg.'
        }
      },
      'userAuctions': {
        'title': 'Licitațiile mele în curs de desfășurare',
        'info': 'Statusul tuturor licitațiilor tale în curs de desfășurare. Licitațiile încheiate sunt șterse automat din această secțiune.',
        'auctionList': {
          'loading': 'Se încarcă lista cu licitații active...',
          'noUserAuctions': 'Momentan nu ai nici o licitație în curs de desfășurare pe cont.',
          'browseActiveAuctions': 'Vizualizează pagina cu ',
          'browseActiveAuctionsLinkText': 'licitații în curs de desfășurare',
          'browseActiveAuctionsPost': '.'
        },
        'clickToNavigateToAuction': 'Click pentru a deschide pagina licitației',
        'error': {
          'sessionTimeout': 'Sesiunea ta a expirat',
          'sessionExpiredError': 'Sesiunea curentă a expirat. Te rugăm să te autentifici din nou.',
        },
        'openAuctionInNewTab': 'Deschide în tab nou',
        'labels': {
          'name': 'Nume domeniu',
          'offer': 'Oferta ta actuală',
          'winner': 'Cea mai mare ofertă',
        },
        'highestOffer': 'Oferta ta este cea mai mare',
        'notTheHighestOffer': 'Oferta ta nu este cea mai mare',
      },
      'userDetailsAdd': {
        'title': 'Adaugă date cont',
        'description': 'Datele de cont sunt utilizate pentru înregistrarea domeniilor sau pentru rezervarea acestora în urma capturării'
      },
      'userDetailsEmpty': {
        'label': 'Adaugă date de înregistrare și începe căutarea celui mai bun domeniu 🚀',
        'description': 'Pentru a putea înregistra, reînnoi sau licita domenii este necesar să adaugi datele de înregistrare.',
        'titleAdd': 'Adaugă date cont',
        'descriptionAdd': 'Datele de cont sunt utilizate pentru înregistrarea domeniilor sau pentru rezervarea acestora în urma capturării'
      },
      'userDetailsForm': {
        'name': 'Nume complet *',
        'entityType': 'Tip persoană *',
        'identificationNumber': 'CNP sau CUI (ex.: 1901230123456 sau RO123456) *',
        'referenceNumber': 'Număr înregistrare (ex.: J40/12345/2020) *',
        'address': 'Adresă *',
        'address2': 'Adresă - continuare (opțional)',
        'city': 'Oraș *',
        'state': 'Județ *',
        'postalCode': 'Cod poștal *',
        'country': 'Țară *',
        'email': 'Adresă de email *',
        'error': {
          'nameRequired': 'Te rugăm să introduci numele complet',
          'nameMinLength': 'Numele introdus este invalid, acesta trebuie să conțină cel puțin 3 caractere',
          'entityTypeRequired': 'Te rugăm să selectezi tipul entității',
          'identificationNumberRequired': 'Te rugăm să introduci CNP-ul sau CUI-ul, în funcție de caz',
          'identificationNumberMinLength': 'Te rugăm să introduci un cod valid',
          'identificationNumberMaxLength': 'Te rugăm să introduci un cod valid',
          'referenceNumberRequired': 'Te rugăm să introduci numărul de înregistrare',
          'referenceNumberMinLength': 'Te rugăm să introduci un număr de înregistrare valid',
          'addressRequired': 'Te rugăm să introduci adresa',
          'addressMinLength': 'Te rugăm să introduci o adresă validă',
          'cityRequired': 'Te rugăm să introduci orașul',
          'stateRequired': 'Te rugăm să introduci județul / statul',
          'postalCodeRequired': 'Te rugăm să introduci codul poștal',
          'postalCodeMinLength': 'Te rugăm să introduci un cod poștal valid',
          'countryRequired': 'Te rugăm să selectezi țara',
          'emailRequired': 'Te rugăm să introduci adresa de email',
          'emailValid': 'Te rugăm să introduci o adresă de email validă'
        },
      },
      'userDetailsItem': {
        'setAsDefault': 'Setează ca prestabilit',
        'setAsDefaultTooltip': 'Setează ca date de cont principale'
      },
      'userDetailsOther': {
        'title': 'Alte date de înregistrare asociate contului',
        'description': 'Puteți înlocui datele de cont utilizate pentru înregistrarea sau rezervarea domeniilor în urma capturării alegând o altă opțiune din listă',
        'editModalTitle': 'Administrează date cont',
        'setAsDefault': 'Setează ca prestabilit'
      },
      'userDetailsSummary': {
        'title': 'Date de înregistrare',
        'description': 'Aceste date sunt utilizate pentru înregistrarea domeniilor sau pentru rezervarea acestora în urma capturării'
      },
      'userDomains': {
        'title': 'Domeniile mele',
        'description': 'Domenii asociate cu acest cont. Poți administra singur domeniile făcând clic pe intrările din listă de mai jos.',
        'labels': {
          'expireDate': 'Data expirării',
          'expireDateReservedDomains': 'Rezervarea expiră la data',
          'name': 'Nume domeniu',
          'nameservers': 'Nameservere',
          'privateNameservers': 'Namservere private',
          'domainStatus': 'Starea domeniului',
          'priceEuro': 'Euro',
          'priceRon': 'Ron',
          'paid': 'Plătit',
        },
        'domainStatus': {
          'reserved': 'Rezervat',
          'registered': 'Înregistrat (OK)',
          'pendingDelete': 'În curs de ștergere'
        },
        'error': {
          'connectionError': 'Informațiile nu s-au putut încărca, te rugăm să verifici conexiunea la internet. Dacă problema persistă, ne poți contacta la:',
          'domainNotFound1': 'Uh-oh! Se pare că domeniul pe care l-ai căutat nu este asociat cu contul tău. Verifică dacă există eventuale greșeli de tastare sau vizitează secțiunea din contul tău denumită "',
          'domainNotFound2': '" pentru a vedea lista ta de domenii.',
        },
        'domainList': {
          'loading': 'Se încarcă lista cu domenii...',
          'noUserDomains': 'Nu am găsit niciun domeniu asociat cu acest cont'
        },
        'clickToNavigateToUserDomain': 'Click pentru a administra domeniul',
        'openUserDomainInNewTab': 'Deschide în tab nou'
      },
      'userDomainsStatus': {
        'addedToCart': 'Domeniul a fost adăugat în coș',
        'alreadyAdded': 'Domeniu deja existent în coș sau indisponibil'
      },
      'userDomainView': {
        'addToCartDomain': 'Adaugă în coș pentru a prelungi înregistrarea domeniului',
        'manageYourDomain': 'Administrează domeniul:',
        'summaryTitle': 'Informații generale',
        'registerDate': 'Data înregistrării',
        'expireDate': 'Data expirării',
        'domainStatus': 'Starea domeniului',
        'nameserversTitle': 'Servere de nume',
        'updateNameserver': 'Modifică nameservers',
        'transferDomain': 'Transferă domeniu',
        'inputLabel': 'Cheie',
        'transferBtn': 'Transferă domeniu',
        'successfulTransferDomain': 'Domeniul dumneavoastră a fost transferat cu succes',
        'registrationAvailability': 'Rezervarea domeniului este valabilă până la data: ',
        'error': {
          'invalidNameserver': 'Nameserver invalid. Exemplu: ns1.inreg.ro',
          'invalidNameserverAll': 'Nameserver invalid. Verificați valorile introduse.',
          'nameserverFurtherIssues': 'Dacă problema persistă, te rugăm să ne contactezi:',
          'showNameserversUnchanged': 'Nu ați făcut încă nici o modificare',
          'transferDomain': 'A aparut o eroare, vă rugăm să mai încercați o dată',
        }
      },
      'whoisView': 'Pagina "Whois Domenii" este în construcție momentan. În cazul în care aveți întrebări sau întâmpinați probleme în timpul utilizării platformei, vă rugăm să ne contactați pe adresa de email:',
    }
  }
});
