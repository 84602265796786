// @TODO - Refactor this module after Ionut implements his part
// import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { CheckoutService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  POST_CHECKOUT_ORDER,
  POST_VALIDATE_CHECKOUT_ORDER
} from './actions.type';
import {
  SET_CHECKOUT_PAYMENT_FORM,
  SET_RESET_CHECKOUT_ERROR,
  SET_CHECKOUT_ERROR,
  SET_CHECKOUT_PAYMENT_VALIDATION,
  SET_CHECKOUT_LOADING,
  SET_SUCCESSFUL_PAYMENT
} from './mutations.type';

const initialState = {
  isSuccess: false,
  checkoutErrors: false,
  checkoutPaymentForm: '',
  checkoutPaymentValidation: null,
  isCheckoutLoading: false
};

export const state = cloneDeep(initialState);

const getters = {
  checkoutPaymentForm(state) {
    return state.checkoutPaymentForm;
  },
  checkoutPaymentValidation(state) {
    return state.checkoutPaymentValidation;
  },
  checkoutErrors(state) {
    return state.checkoutErrors;
  },
  isCheckoutLoading(state) {
    return state.isCheckoutLoading;
  },
  isSuccess(state) {
    return state.isSuccess;
  }
};

const actions = {
  [POST_CHECKOUT_ORDER]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return CheckoutService.postCheckoutOrder(payload)
        .then(({ data }) => {
          commit(SET_CHECKOUT_PAYMENT_FORM, data);
          commit(SET_RESET_CHECKOUT_ERROR);
          return data;
        })
        .catch(({ response }) => {
          commit(SET_CHECKOUT_ERROR, true);
          throw new Error(response);
        });
    }
  },
  [POST_VALIDATE_CHECKOUT_ORDER]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return CheckoutService.postValidatePayU(payload)
        .then(({ data }) => {
          commit(SET_RESET_CHECKOUT_ERROR);
          const paymentResponse = data.success;
          if (paymentResponse) {
            console.log('Payment validation:', data);
            commit(SET_SUCCESSFUL_PAYMENT, true)
           } else {
            commit(SET_SUCCESSFUL_PAYMENT, false)
           }
          return data.paymentResponse;
        })
        .catch(({ response }) => {
          commit(SET_CHECKOUT_ERROR, true);
          throw new Error(response);
        });
    }
  }
};

const mutations = {
  [SET_CHECKOUT_PAYMENT_FORM](state, data) {
    if (data && data.length) {
      // console.log('DATA IS (intra in if):', data);
      state.checkoutPaymentForm = data;
    }
  },
  [SET_CHECKOUT_PAYMENT_VALIDATION](state, data) {
    state.checkoutPaymentValidation = data;
  },
  [SET_CHECKOUT_ERROR](state) {
    state.checkoutErrors = true;
  },
  [SET_RESET_CHECKOUT_ERROR](state) {
    state.checkoutErrors = false;
  },
  [SET_CHECKOUT_LOADING](state, flag) {
    state.isCheckoutLoading = flag;
  },
  [SET_SUCCESSFUL_PAYMENT](state, flag) {
    state.isSuccess = flag;
  },
  // [RESET_STATE]() {
  //   for (let f in state) {
  //     Vue.set(state, f, initialState[f]);
  //   }
  //   state.isUserAuctionsLoading = false;
  // }
};


export default {
  state,
  actions,
  getters,
  mutations,
};
