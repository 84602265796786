import { cloneDeep } from 'lodash';
import {
  MINICART_OPEN,
  MINICART_CLOSE
} from './actions.type';
import {
  SET_MINICART_OPEN,
  SET_MINICART_CLOSE,
} from './mutations.type';

const initialState = {
  minicartActive: false
};

export const state = cloneDeep(initialState);

const getters = {
  minicartActive(state) {
    return state.minicartActive;
  }
};

const actions = {
  [MINICART_OPEN]({ commit }) {
    commit(SET_MINICART_OPEN);
  },
  [MINICART_CLOSE]({ commit }) {
    commit(SET_MINICART_CLOSE);
  }
}

const mutations = {
  [SET_MINICART_OPEN](state) {
    state.minicartActive = true;
  },
  [SET_MINICART_CLOSE](state) {
    state.minicartActive = false;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
