import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { AccountService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  FETCH_PHONE,
  ADD_PHONE,
  RETRY_CODE,
  VERIFY_PHONE,
  ACCOUNT_RESET_STATE,
  NEWSLETTER_REGISTERED_USERS,
  NEWSLETTER_UNREGISTERED_USERS,
  FETCH_SUBSCRIBE_DATA,
  SEND_MESSAGE,
  START_MY_ACCOUNT_LOADER,
  STOP_MY_ACCOUNT_LOADER,
  CHANGE_PHONE,
  POST_BILLING_CURRENCY,
  FETCH_CURRENCY
} from './actions.type';
import {
  SET_AUTH_CODE_SENT,
  SET_PHONE_NUMBER,
  SET_PHONE_VERIFIED,
  SET_PHONE_VERIFIED_TEMP,
  SET_PHONE_ERROR,
  RESET_PHONE_ERROR,
  SET_ACCOUNT_CONNECTION_ERROR,
  SET_NEWSLETTER,
  SET_MY_ACCOUNT_LOADING,
  SET_CURRENCY,
  SET_CURRENCY_ERROR,
  SET_RESET_CURRENCY_ERROR,
  RESET_STATE
} from './mutations.type';

const initialState = {
  phoneErrors: null,
  accountErrors: null,
  currencyErrors: null,
  accountConnectionError: false,
  phone: {
    authCodeSent: false,
    verified: false,
    verifiedTemp: false,
    number: '',
  },
  newsletter: null,
  isMyAccountLoading: false,
  currency: ''
};

export const state = cloneDeep(initialState);

const getters = {
  authCodeSent(state) {
    return state.phone.authCodeSent;
  },
  phoneNumber(state) {
    return state.phone.number;
  },
  phoneVerified(state) {
    return state.phone.verified;
  },
  phoneVerifiedTemp(state) {
    return state.phone.phoneVerifiedTemp;
  },
  phoneErrors(state) {
    return state.phoneErrors;
  },
  accountErrors(state) {
    return state.accountErrors;
  },
  accountConnectionError(state) {
    return state.accountConnectionError;
  },
  newsletter(state) {
    return state.newsletter;
  },
  isMyAccountLoading(state) {
    return state.isMyAccountLoading;
  },
  currency(state) {
    return state.currency;
  },
  currencyErrors(state) {
    return state.currencyErrors;
  },
};

const actions = {
  [FETCH_PHONE]({ commit }) {
    if (JwtService.getToken()) {
      commit(SET_MY_ACCOUNT_LOADING, true);
      ApiService.setHeader();

      return AccountService.getPhone()
        .then(({ data }) => {
          commit(RESET_PHONE_ERROR);
          commit(SET_PHONE_NUMBER, data.phoneNumber);
          commit(SET_PHONE_VERIFIED, data.verified);
          commit(SET_MY_ACCOUNT_LOADING, false);
        })
        .catch(({ response }) => {
          if (response.data.status === 500) {
            commit(SET_ACCOUNT_CONNECTION_ERROR, true);
          } else {
            commit(SET_PHONE_ERROR, response.data.message);
          }
          commit(SET_MY_ACCOUNT_LOADING, false);
          console.log('Error: ', response);
        });
    }
  },
  [ADD_PHONE]({ commit }, phoneNumber) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return AccountService.addPhone(phoneNumber)
        .then(({ data }) => {
          commit(RESET_PHONE_ERROR);
          commit(SET_PHONE_NUMBER, data.phoneNumber);
          commit(SET_AUTH_CODE_SENT, true);
        })
        .catch(({ response }) => {
          commit(SET_PHONE_ERROR, response.data.message);
          console.log('Error: ', response);
        });
    }
  },
  [RETRY_CODE]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return AccountService.retryCode()
        .then(() => {
          commit(RESET_PHONE_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_PHONE_ERROR, response.data.message);
          console.log('Error: ', response);
        });
    }
  },
  [CHANGE_PHONE]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return AccountService.changePhone()
        .then(() => {
          commit(RESET_PHONE_ERROR);
          commit(SET_AUTH_CODE_SENT, false);
        })
        .catch(({ response }) => {
          commit(SET_PHONE_ERROR, response.data.message);
          console.log('Error: ', response);
          commit(SET_AUTH_CODE_SENT, false);
        });
    }
  },
  [VERIFY_PHONE]({ commit }, authCode) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return AccountService.verifyPhone(authCode)
        .then(({ data }) => {
          commit(RESET_PHONE_ERROR);
          commit(SET_PHONE_NUMBER, data.phoneNumber);
          commit(SET_PHONE_VERIFIED, data.verified);
          commit(SET_PHONE_VERIFIED_TEMP, data.verified);
        })
        .catch(({ response }) => {
          commit(SET_PHONE_ERROR, response.data.message);
          console.log('Error: ', response);
        });
    }
  },
  [NEWSLETTER_REGISTERED_USERS]({ commit }, payload) {
    return AccountService.postNewsletterRegisteredUsers({
      email: payload.email,
      newsletter: payload.newsletter
    })
      .then(() => {
        commit(SET_NEWSLETTER, payload)
      })
      .catch(({ response }) => {
        console.log('Error: ', response);
      });
  },
  [NEWSLETTER_UNREGISTERED_USERS]({ commit }, payload) {
    return AccountService.postNewsletterUnregisteredUsers({
        email: payload.email,
        newsletter: payload.newsletter,
        captchaToken: payload.captchaToken
      })
      .then(() => {
        payload = {
          email: payload.email,
          newsletter: payload.newsletter,
        }
        commit(SET_NEWSLETTER, payload);
      })
      .catch(({ response }) => {
        console.log('Error: ', response);
      });
  },
  [FETCH_SUBSCRIBE_DATA]({ commit }) {
    return AccountService.getSubscribeDetails({})
      .then(({ data }) => {
        commit(SET_NEWSLETTER, data);
      })
      .catch(({ response }) => {
        console.log('Error: ', response);
      });
  },
  [SEND_MESSAGE]({ commit }, payload) {
    console.log(payload) // @todo to remove this

    return AccountService.postSendMessage({
        email: payload.email,
        name: payload.name,
        phoneNumber: payload.phone,
        message: payload.text
      })
      .then(() => {
        commit(RESET_PHONE_ERROR);
      })
      .catch(({ response }) => {
        console.log('Error: ', response);
      });
  },
  [START_MY_ACCOUNT_LOADER]({ commit }) {
    commit(SET_MY_ACCOUNT_LOADING, true);
  },
  [STOP_MY_ACCOUNT_LOADER]({ commit }) {
    commit(SET_MY_ACCOUNT_LOADING, false);
  },
  [POST_BILLING_CURRENCY]({ commit }, params) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      return AccountService.postBillingCurrency(params)
        .then(({ data }) => {
          commit(SET_RESET_CURRENCY_ERROR);
          commit(SET_CURRENCY, data.currency);
        })
        .catch(({ response }) => {
          commit(SET_CURRENCY_ERROR, response.data.message);
          console.log('Error: ', response);
        });
    }
  },
  [FETCH_CURRENCY]({ commit }) {
    if (JwtService.getToken()) {
      commit(SET_MY_ACCOUNT_LOADING, true);
      ApiService.setHeader();

      return AccountService.getBillingCurrency()
        .then(({ data }) => {
          commit(SET_RESET_CURRENCY_ERROR);
          commit(SET_CURRENCY, data.currency);
          commit(SET_MY_ACCOUNT_LOADING, false);
        })
        .catch(({ response }) => {
          if (response.data.status === 500) {
            commit(SET_ACCOUNT_CONNECTION_ERROR, true);
          } else {
            commit(SET_CURRENCY_ERROR, response.data.message);
          }
          commit(SET_MY_ACCOUNT_LOADING, false);
          console.log('Error: ', response);
        });
    }
  },
  [ACCOUNT_RESET_STATE]({ commit }) {
    commit(RESET_STATE);
  }
};

const mutations = {
  [SET_AUTH_CODE_SENT](state, flag) {
    state.phone.authCodeSent = flag;
  },
  [SET_PHONE_NUMBER](state, phoneNumber) {
    state.phone.number = phoneNumber;
    state.isUserAuctionsLoading = false;
  },
  [SET_PHONE_VERIFIED](state, flag) {
    state.phone.verified = flag;
  },
  [SET_PHONE_VERIFIED_TEMP](state, flag) {
    state.phone.verifiedTemp = flag;
  },
  [SET_ACCOUNT_CONNECTION_ERROR](state, flag) {
    state.accountConnectionError = flag;
  },
  [SET_PHONE_ERROR](state, error) {
    state.phoneErrors = error;
  },
  [RESET_PHONE_ERROR](state) {
    state.phoneErrors = null;
  },
  [SET_NEWSLETTER](state, value) {
    state.newsletter = value;
  },
  [SET_MY_ACCOUNT_LOADING](state, loadingFlag) {
    state.isMyAccountLoading = loadingFlag;
  },
  [SET_CURRENCY](state, data) {
    state.currency = data;
  },
  [SET_CURRENCY_ERROR](state, error) {
    state.currencyErrors = error;
  },
  [SET_RESET_CURRENCY_ERROR](state) {
    state.currencyErrors = null;
  },
  [RESET_STATE]() {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
