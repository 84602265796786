import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { BillingDetailsService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  FETCH_BILLING_DETAILS_ALL,
  FETCH_BILLING_DETAILS_BY_ID,
  POST_BILLING_DETAILS_ADD,
  POST_BILLING_DETAILS_REMOVE,
  POST_BILLING_DETAILS_EDIT,
  POST_BILLING_DETAILS_SET_DEFAULT,
  UPDATE_BILLING_DETAILS,
  SET_BILLING_DETAILS_FORM_SENT_FLAG,
  SET_BILLING_DETAILS_ADD_STATE_FLAG,
  SET_BILLING_DETAILS_EDIT_STATE_FLAG,
  SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG,
  SHOW_BILLING_DETAILS_ADD_DIALOG,
  RESET_BILLING_DETAILS,
  RESET_BILLING_DETAILS_MODULE
} from './actions.type';
import {
  SET_BILLING_DETAILS_ERROR,
  SET_BILLING_DETAILS,
  SET_BILLING_DETAILS_ADDRESSES,
  SET_BILLING_DETAILS_DEFAULT,
  SET_BILLING_DETAILS_NON_DEFAULT,
  SET_BILLING_DETAILS_LOADING,
  SET_BILLING_DETAILS_FORM_SENT,
  SET_BILLING_DETAILS_ADD_STATE,
  SET_BILLING_DETAILS_EDIT_STATE,
  SET_BILLING_DETAILS_OTHER_EDIT_STATE,
  SET_SHOW_BILLING_DETAILS_ADD_DIALOG,
  SET_RESET_BILLING_DETAILS,
  SET_RESET_BILLING_DETAILS_ERROR,
  SET_RESET_BILLING_DETAILS_MODULE
} from './mutations.type';

const initialState = {
  billingDetailsError: null,
  billingDetails: {
    entityType: '',
    registrantName: '',
    entityName: '',
    identificationNumber: '',
    referenceNumber: '',
    address1: '',
    address2: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    email: '',
    phone: '',
  },
  billingDetailsAddress: {
    entityType: '',
    registrantName: '',
    entityName: '',
    identificationNumber: '',
    referenceNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    email: '',
    phone: '',
  },
  billingDetailsAddresses: null,
  billingDetailsDefault: null,
  billingDetailsNonDefault: null,
  isBillingDetailsLoading: false,
  isBillingDetailsFormSent: false,
  isBillingDetailsAddState: false,
  isBillingDetailsEditState: false,
  isBillingDetailsOtherEditState: false,
  showBillingDetailsAddDialog: false
};

export const state = cloneDeep(initialState);

const getters = {
  billingDetails(state) {
    return state.billingDetails;
  },
  billingDetailsError(state) {
    return state.billingDetailsError;
  },
  billingDetailsAddresses(state) {
    return state.billingDetailsAddresses;
  },
  billingDetailsDefault(state) {
    return state.billingDetailsDefault;
  },
  billingDetailsNonDefault(state) {
    return state.billingDetailsNonDefault;
  },
  isBillingDetailsLoading(state) {
    return state.isBillingDetailsLoading;
  },
  isBillingDetailsFormSent(state) {
    return state.isBillingDetailsFormSent;
  },
  isBillingDetailsAddState(state) {
    return state.isBillingDetailsAddState;
  },
  isBillingDetailsEditState(state) {
    return state.isBillingDetailsEditState;
  },
  isBillingDetailsOtherEditState(state) {
    return state.isBillingDetailsOtherEditState;
  },
  showBillingDetailsAddDialog(state) {
    return state.showBillingDetailsAddDialog;
  }
};

const actions = {
  [FETCH_BILLING_DETAILS_ALL]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return BillingDetailsService.getBillingDetailsAll()
        .then(({ data }) => {
          commit(SET_RESET_BILLING_DETAILS_ERROR);
          commit(SET_BILLING_DETAILS_ADDRESSES, data);
          commit(SET_BILLING_DETAILS_DEFAULT, data);
          commit(SET_BILLING_DETAILS_NON_DEFAULT, data);
        })
        .catch(response => {
          commit(SET_BILLING_DETAILS_ERROR, response);
          throw new Error(response);
        });
    }
  },
  [POST_BILLING_DETAILS_ADD]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return BillingDetailsService.postBillingDetailsAdd({
          personType: payload.entityType,
          postalCode: payload.postalCode,
          registrantName: payload.registrantName,
          entityName: payload.entityName,
          cnp: payload.identificationNumber,
          registrationNumber: payload.referenceNumber,
          address1: payload.address1,
          address2: payload.address2,
          city: payload.city,
          state: payload.state,
          country: payload.country,
          email: payload.email,
          phone: payload.phone
        })
        .then(() => {
          commit(SET_RESET_BILLING_DETAILS_ERROR);
          commit(SET_BILLING_DETAILS_FORM_SENT, true);
        })
        .catch(({ response }) => {
          commit(SET_BILLING_DETAILS_ERROR, response.data.message);
          commit(SET_BILLING_DETAILS_FORM_SENT, false);
          throw new Error(response);
        });
    }
  },
  [POST_BILLING_DETAILS_REMOVE]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return BillingDetailsService.postBillingDetailsRemove(payload)
        .then(() => {
          commit(SET_RESET_BILLING_DETAILS_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_BILLING_DETAILS_ERROR, response.data.message);
          throw new Error(response);
        });
    }
  },
  [POST_BILLING_DETAILS_EDIT]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return BillingDetailsService.postBillingDetailsEdit({
          personType: payload.entityType,
          postalCode: payload.postalCode,
          registrantName: payload.registrantName,
          entityName: payload.entityName,
          cnp: payload.identificationNumber,
          registrationNumber: payload.referenceNumber,
          address1: payload.address1,
          address2: payload.address2,
          city: payload.city,
          state: payload.state,
          country: payload.country,
          email: payload.email,
          phone: payload.phone,
          id: payload.id
        })
        .then(() => {
          commit(SET_RESET_BILLING_DETAILS_ERROR);
          commit(SET_BILLING_DETAILS_FORM_SENT, true);
        })
        .catch(({ response }) => {
          commit(SET_BILLING_DETAILS_ERROR, response.data.message);
          commit(SET_BILLING_DETAILS_FORM_SENT, false);
          throw new Error(response);
        });
    }
  },
  [FETCH_BILLING_DETAILS_BY_ID]({ commit }, addressId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return BillingDetailsService.getBillingDetailsById(addressId)
        .then(({ data }) => {
          commit(SET_BILLING_DETAILS, data);
          commit(SET_RESET_BILLING_DETAILS_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_BILLING_DETAILS_ERROR, response.data.message);
          throw new Error(response);
        });
    }
  },
  [POST_BILLING_DETAILS_SET_DEFAULT]({ commit }, addressId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return BillingDetailsService.postBillingDetailsSetDefault(addressId)
        .then(() => {
          commit(SET_RESET_BILLING_DETAILS_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_BILLING_DETAILS_ERROR, response.data.message);
          throw new Error(response);
        });
    }
  },
  [UPDATE_BILLING_DETAILS]({ commit }, billingDetails) {
    commit(SET_BILLING_DETAILS, billingDetails);
  },
  [SET_BILLING_DETAILS_FORM_SENT_FLAG]({ commit }, flag) {
    commit(SET_BILLING_DETAILS_FORM_SENT, flag);
  },
  [SET_BILLING_DETAILS_ADD_STATE_FLAG]({ commit }, flag) {
    commit(SET_BILLING_DETAILS_ADD_STATE, flag);
  },
  [SET_BILLING_DETAILS_EDIT_STATE_FLAG]({ commit }, flag) {
    commit(SET_BILLING_DETAILS_EDIT_STATE, flag);
  },
  [SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG]({ commit }, flag) {
    commit(SET_BILLING_DETAILS_OTHER_EDIT_STATE, flag);
  },
  [SHOW_BILLING_DETAILS_ADD_DIALOG]({ commit }, flag) {
    commit(SET_SHOW_BILLING_DETAILS_ADD_DIALOG, flag);
  },
  [RESET_BILLING_DETAILS]({ commit }) {
    commit(SET_RESET_BILLING_DETAILS);
  },
  [RESET_BILLING_DETAILS_MODULE]({ commit }) {
    commit(SET_RESET_BILLING_DETAILS_MODULE);
  }
};

const mutations = {
  [SET_BILLING_DETAILS](state, billingDetails) {
    state.billingDetails.id = billingDetails.id;
    state.billingDetails.entityType = billingDetails.personType;
    state.billingDetails.postalCode = billingDetails.postalCode;
    state.billingDetails.registrantName = billingDetails.registrantName;
    state.billingDetails.entityName = billingDetails.entityName;
    state.billingDetails.identificationNumber = billingDetails.cnp;
    state.billingDetails.referenceNumber = billingDetails.registrationNumber;
    state.billingDetails.address1 = billingDetails.address1;
    state.billingDetails.address2 = billingDetails.address2;
    state.billingDetails.city = billingDetails.city;
    state.billingDetails.state = billingDetails.state;
    state.billingDetails.country = billingDetails.country;
    state.billingDetails.email = billingDetails.email;
    state.billingDetails.phone = billingDetails.phone;
  },
  [SET_BILLING_DETAILS_ADDRESSES](state, data) {
    if (data && data.length) {
      state.billingDetailsAddresses = data;
    }
  },
  [SET_BILLING_DETAILS_DEFAULT](state, data) {
    for (const item of data) {
      if (item.isBillingDefault === true) {
        state.billingDetailsDefault = { ...item };
        break;
      }
    }
  },
  [SET_BILLING_DETAILS_NON_DEFAULT](state, data) {
    const tempBillingDetailsNonDefault = data.filter(item => {
      if (item.isBillingDefault !== true) {
        return item;
      }
    });

    if (tempBillingDetailsNonDefault && tempBillingDetailsNonDefault.length) {
      state.billingDetailsNonDefault = tempBillingDetailsNonDefault;
    }
  },
  [SET_BILLING_DETAILS_LOADING](state, flag) {
    state.isBillingDetailsLoading = flag;
  },
  [SET_BILLING_DETAILS_FORM_SENT](state, flag) {
    state.isBillingDetailsFormSent = flag;
  },
  [SET_BILLING_DETAILS_ADD_STATE](state, flag) {
    state.isBillingDetailsAddState = flag;
    state.isBillingDetailsEditState = false;
    state.isBillingDetailsOtherEditState = false;
  },
  [SET_BILLING_DETAILS_EDIT_STATE](state, flag) {
    state.isBillingDetailsAddState = false;
    state.isBillingDetailsEditState = flag;
    state.isBillingDetailsOtherEditState = false;
  },
  [SET_BILLING_DETAILS_OTHER_EDIT_STATE](state, flag) {
    state.isBillingDetailsAddState = false;
    state.isBillingDetailsEditState = false;
    state.isBillingDetailsOtherEditState = flag;
  },
  [SET_SHOW_BILLING_DETAILS_ADD_DIALOG](state, flag) {
    state.showBillingDetailsAddDialog = flag;
  },
  [SET_BILLING_DETAILS_ERROR](state, error) {
    state.billingDetailsError = error;
  },
  [SET_RESET_BILLING_DETAILS](state) {
    for (let f in state.billingDetails) {
      Vue.set(state.billingDetails, f, initialState.billingDetails[f]);
    }
  },
  [SET_RESET_BILLING_DETAILS_ERROR](state) {
    state.billingDetailsError = null;
  },
  [SET_RESET_BILLING_DETAILS_MODULE](state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
