<template>
  <router-link class="MinicartButton o-default-body-xs nav-button"
               :to="links.cart.page"
               :event="!isMobileOrTablet ? 'click' : ''"
               @click.native.prevent="isMobileOrTablet ? handleMouseEvent() : ''">
    <md-badge class="md-primary minicart-badge"
              :md-content="itemsInCart"
              md-dense>
      <i class="material-icons">shopping_cart</i>
    </md-badge>
    <span class="nav-button-label">{{ $t('shortcutMenu.cartItems') }}</span>
    <div class="basket-arrow-down">
      <i class="material-icons arrow-drop-down">arrow_drop_down</i>
    </div>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  MINICART_OPEN,
  MINICART_CLOSE
} from '@/store/actions.type';

export default {
  name: 'MinicartButton',
  props: {
    itemsInCart: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {
      links: {
        cart: {
          page: '/cart'
        },
      }
    }
  },
  computed: {
    ...mapGetters([
      'isMobileOrTablet',
      'minicartActive'
    ])
  },
  methods: {
    closeMyAccountDropdown() {
      this.$store.dispatch(MINICART_CLOSE);
    },
    toggleMinicart() {
      this.minicartActive ? this.$store.dispatch(MINICART_CLOSE) : this.$store.dispatch(MINICART_OPEN);
    },
    // Method that use setTimeout to schedule the DOM modifications caused by mouseenter/mouseleave as a separate task
    // https://github.com/vuejs/vue/issues/9859
    handleMouseEvent() {
      setTimeout(() => {
        this.toggleMinicart()
      }, 0);
    }
  },
  watch: {
    '$route'() {
      this.$store.dispatch(MINICART_CLOSE);
    }
  },
};
</script>

<style lang="scss">
.MinicartButton {

  .minicart-badge {

    &.md-badge-content {
      margin-right: .4rem;
      padding-right: .2rem;
    }

    &.md-position-top {
      height: 2rem;
      width: 2rem;
    }
  }
}
</style>

<style lang="scss" scoped>
.MinicartButton {

  &.nav-button {
    padding-right: 0;
  }

  .basket-arrow-down {
    display: inline-block;
  }
}
</style>
