import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import account from './account.module';
import auctions from './auctions.module';
import billingDetails from './billingDetails.module';
import cart from './cart.module';
import minicart from './minicart.module';
import checkout from './checkout.module';
import location from './location.module';
import shortcutMenu from './shortcutMenu.module';
import userAuctions from './userAuctions.module';
import userDetails from './userDetails.module';
import userDomains from './userDomains.module';
import util from './util.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    account,
    auctions,
    billingDetails,
    cart,
    minicart,
    checkout,
    location,
    shortcutMenu,
    userAuctions,
    userDetails,
    userDomains,
    util,
  }
});
