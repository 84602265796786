// Auth
export const ACTIVATE_USER = 'activateUser';
export const CHECK_AUTH = 'checkAuth';
export const FORGOT_PASSWORD = 'forgotPassword';
export const RESET_PASSWORD = 'resetPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const SIGNIN = 'signIn';
export const SIGNOUT = 'signOut';
export const SIGNUP = 'signUp';
export const UPDATE_USER_EMAIL = 'updateUserEmail';
export const RESET_AUTH_ERROR = 'resetAuthError';

// Auctions
export const AUCTION_RESET_STATE = 'resetAuctionState';
export const DELETE_BID = 'deleteBid';
export const FETCH_AUCTION = 'fetchAuction';
export const FETCH_AUCTIONS = 'fetchAuctions';
export const PLACE_BID = 'placeBid';

// Loader
export const START_AUTH_LOADER = 'startAuthLoader';
export const STOP_AUTH_LOADER = 'stopAuthLoader';

// My account
export const ACCOUNT_RESET_STATE = 'resetAccountState';
export const ADD_PHONE = 'addPhone';
export const CHANGE_PHONE = 'changePhone';
export const FETCH_PHONE = 'fetchPhone';
export const RETRY_CODE = 'retryCode';
export const VERIFY_PHONE = 'verifyPhone';
export const NEWSLETTER = 'newsLetter';
export const NEWSLETTER_REGISTERED_USERS = 'newsletterRegisteredUsers';
export const NEWSLETTER_UNREGISTERED_USERS = 'newsletterUnregisteredUsers';
export const FETCH_SUBSCRIBE_DATA = 'fetchSubscribeData';
export const SEND_MESSAGE = 'sendMessage';
export const START_MY_ACCOUNT_LOADER = 'startMyAccountLoader';
export const STOP_MY_ACCOUNT_LOADER = 'stopMyAccountLoader';
export const FETCH_CURRENCY = 'fetchCurrency';
export const POST_BILLING_CURRENCY = 'postBillingCurrency';

// Billing details
export const FETCH_BILLING_DETAILS_ALL = 'fetchBillingDetailsAll';
export const FETCH_BILLING_DETAILS_BY_ID = 'fetchBillingDetailsById';
export const POST_BILLING_DETAILS_ADD = 'postBillingDetailsAdd';
export const POST_BILLING_DETAILS_REMOVE = 'postBillingDetailsRemove';
export const POST_BILLING_DETAILS_EDIT = 'postBillingDetailsEdit';
export const POST_BILLING_DETAILS_SET_DEFAULT = 'postBillingDetailsSetDefault';
export const UPDATE_BILLING_DETAILS = 'updateBillingDetails';
export const SET_BILLING_DETAILS_FORM_SENT_FLAG = 'setBillingDetailsFormSentFlag';
export const SET_BILLING_DETAILS_ADD_STATE_FLAG = 'setBillingDetailsAddStateFlag';
export const SET_BILLING_DETAILS_EDIT_STATE_FLAG = 'setBillingDetailsEditStateFlag';
export const SET_BILLING_DETAILS_OTHER_EDIT_STATE_FLAG = 'setBillingDetailsOtherEditStateFlag';
export const SHOW_BILLING_DETAILS_ADD_DIALOG = 'showBillingDetailsAddDialog';
export const RESET_BILLING_DETAILS = 'resetBillingDetails';
export const RESET_BILLING_DETAILS_MODULE = 'resetBillingDetailsModule';

// User details
export const FETCH_USER_DETAILS_ALL = 'fetchUserDetailsAll';
export const FETCH_USER_DETAILS_BY_ID = 'fetchUserDetailsById';
export const POST_USER_DETAILS_ADD = 'postUserDetailsAdd';
export const POST_USER_DETAILS_REMOVE = 'postUserDetailsRemove';
export const POST_USER_DETAILS_EDIT = 'postUserDetailsEdit';
export const POST_USER_DETAILS_SET_DEFAULT = 'postUserDetailsSetDefault';
export const UPDATE_USER_DETAILS = 'updateUserDetails';
export const SET_USER_DETAILS_FORM_SENT_FLAG = 'setUserDetailsFormSentFlag';
export const SET_USER_DETAILS_ADD_STATE_FLAG = 'setUserDetailsAddStateFlag';
export const SET_USER_DETAILS_EDIT_STATE_FLAG = 'setUserDetailsEditStateFlag';
export const SET_USER_DETAILS_OTHER_EDIT_STATE_FLAG = 'setUserDetailsOtherEditStateFlag';
export const RESET_USER_DETAILS = 'resetUserDetails';
export const RESET_USER_DETAILS_MODULE = 'resetUserDetailsModule';

// User auctions
export const FETCH_USER_AUCTIONS = 'fetchUserAuctions';
export const AUCTION_RESET_ERROR = 'auctionResetError';
export const AUCTION_VIEW_RESET_ERROR = 'auctionViewResetError';
export const USER_AUCTION_RESET_STATE = 'userAuctionResetState';

// User domains
export const FETCH_USER_DOMAIN = 'fetchUserDomain';
export const FETCH_USER_DOMAINS = 'fetchUserDomains';
export const POST_USER_DOMAIN_NAMESERVERS = 'postUserDomainNameservers';
export const USER_DOMAIN_RESET_STATE = 'resetUserDomainState';
export const TRANSFER_DOMAIN = 'transferDomain';
export const FETCH_POPULAR_TRANSFER_BENEFITS = 'fetchPopularTransferBenefits';
export const FETCH_CAPTURED_DOMAINS = 'fetchCapturedDomains';
export const LIKE_POPULAR_TRANSFER_BENEFITS = 'likePopularTransferBenefits';
export const REMOVE_LIKE_POPULAR_TRANSFER_BENEFITS = 'removeLikePopularTransferBenefits';

// Cart
export const FETCH_CART = 'fetchCart';
export const ADD_TO_CART = 'addToCart';
export const DELETE_ENTRY = 'deleteEntry';
export const CHECK_DOMAIN = 'checkDomain';
export const CART_RESET_STATE = 'cartResetState';
export const UPDATE_PRODUCT_QUANTITY = 'updateProductQuantity';
export const UPDATE_ITEMS_IN_CART = 'updateItemsInCart';

// Minicart
export const MINICART_OPEN = 'minicartOpen';
export const MINICART_CLOSE = 'minicartClose';

// Checkout
export const POST_CHECKOUT_ORDER = 'postCheckoutOrder';
export const POST_VALIDATE_CHECKOUT_ORDER = 'postValidateCheckoutOrder';

// My account dropdown
export const MY_ACCOUNT_DROPDOWN_OPEN = 'myAccountDropdownOpen';
export const MY_ACCOUNT_DROPDOWN_CLOSE = 'myAccountDropdownClose';

// Util
export const SHOW_CONFIRMATION_DIALOG = 'showConfirmationDialog';
export const HIDE_CONFIRMATION_DIALOG = 'hideConfirmationDialog';
