import Vue from 'vue';
import { cloneDeep } from 'lodash';
import { UserDetailsService } from '@/common/api.service';
import ApiService from '@/common/api.service';
import JwtService from '@/common/jwt.service';
import {
  FETCH_USER_DETAILS_ALL,
  FETCH_USER_DETAILS_BY_ID,
  POST_USER_DETAILS_ADD,
  POST_USER_DETAILS_REMOVE,
  POST_USER_DETAILS_EDIT,
  POST_USER_DETAILS_SET_DEFAULT,
  UPDATE_USER_DETAILS,
  SET_USER_DETAILS_FORM_SENT_FLAG,
  SET_USER_DETAILS_ADD_STATE_FLAG,
  SET_USER_DETAILS_EDIT_STATE_FLAG,
  SET_USER_DETAILS_OTHER_EDIT_STATE_FLAG,
  RESET_USER_DETAILS,
  RESET_USER_DETAILS_MODULE,
} from './actions.type';
import {
  SET_USER_DETAILS_ERROR,
  SET_USER_DETAILS,
  SET_USER_DETAILS_ADDRESSES,
  SET_USER_DETAILS_DEFAULT,
  SET_USER_DETAILS_NON_DEFAULT,
  SET_USER_DETAILS_LOADING,
  SET_USER_DETAILS_FORM_SENT,
  SET_USER_DETAILS_ADD_STATE,
  SET_USER_DETAILS_EDIT_STATE,
  SET_USER_DETAILS_OTHER_EDIT_STATE,
  SET_RESET_USER_DETAILS,
  SET_RESET_USER_DETAILS_ERROR,
  SET_RESET_USER_DETAILS_MODULE
} from './mutations.type';

const initialState = {
  userDetailsError: null,
  userDetails: {
    entityType: '',
    registrantName: '',
    entityName: '',
    identificationNumber: '',
    referenceNumber: '',
    address1: '',
    address2: '',
    postalCode: '',
    city: '',
    state: '',
    country: '',
    email: '',
    phone: '',
  },
  userDetailsAddress: {
    entityType: '',
    registrantName: '',
    entityName: '',
    identificationNumber: '',
    referenceNumber: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    country: '',
    email: '',
    phone: '',
  },
  userDetailsAddresses: null,
  userDetailsDefault: null,
  userDetailsNonDefault: null,
  isUserDetailsLoading: false,
  isUserDetailsFormSent: false,
  isUserDetailsAddState: false,
  isUserDetailsEditState: false,
  isUserDetailsOtherEditState: false
};

export const state = cloneDeep(initialState);

const getters = {
  userDetails(state) {
    return state.userDetails;
  },
  userDetailsError(state) {
    return state.userDetailsError;
  },
  userDetailsAddresses(state) {
    return state.userDetailsAddresses;
  },
  userDetailsDefault(state) {
    return state.userDetailsDefault;
  },
  userDetailsNonDefault(state) {
    return state.userDetailsNonDefault;
  },
  isUserDetailsLoading(state) {
    return state.isUserDetailsLoading;
  },
  isUserDetailsFormSent(state) {
    return state.isUserDetailsFormSent;
  },
  isUserDetailsAddState(state) {
    return state.isUserDetailsAddState;
  },
  isUserDetailsEditState(state) {
    return state.isUserDetailsEditState;
  },
  isUserDetailsOtherEditState(state) {
    return state.isUserDetailsOtherEditState;
  }
};

const actions = {
  [FETCH_USER_DETAILS_ALL]({ commit }) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDetailsService.getUserDetailsAll()
        .then(({ data }) => {
          commit(SET_RESET_USER_DETAILS_ERROR);
          commit(SET_USER_DETAILS_ADDRESSES, data);
          commit(SET_USER_DETAILS_DEFAULT, data);
          commit(SET_USER_DETAILS_NON_DEFAULT, data);
        })
        .catch(response => {
          commit(SET_USER_DETAILS_ERROR, response);
          throw new Error(response);
        });
    }
  },
  [POST_USER_DETAILS_ADD]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDetailsService.postUserDetailsAdd({
          personType: payload.entityType,
          postalCode: payload.postalCode,
          registrantName: payload.registrantName,
          entityName: payload.entityName,
          cnp: payload.identificationNumber,
          registrationNumber: payload.referenceNumber,
          address1: payload.address1,
          address2: payload.address2,
          city: payload.city,
          state: payload.state,
          country: payload.country,
          email: payload.email,
          phone: payload.phone
        })
        .then(() => {
          commit(SET_RESET_USER_DETAILS_ERROR);
          commit(SET_USER_DETAILS_FORM_SENT, true);
        })
        .catch(({ response }) => {
          commit(SET_USER_DETAILS_ERROR, response.data.message);
          commit(SET_USER_DETAILS_FORM_SENT, false);
          throw new Error(response);
        });
    }
  },
  [POST_USER_DETAILS_REMOVE]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDetailsService.postUserDetailsRemove(payload)
        .then(() => {
          commit(SET_RESET_USER_DETAILS_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_USER_DETAILS_ERROR, response.data.message);
          throw new Error(response);
        });
    }
  },
  [POST_USER_DETAILS_EDIT]({ commit }, payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDetailsService.postUserDetailsEdit({
          personType: payload.entityType,
          postalCode: payload.postalCode,
          registrantName: payload.registrantName,
          entityName: payload.entityName,
          cnp: payload.identificationNumber,
          registrationNumber: payload.referenceNumber,
          address1: payload.address1,
          address2: payload.address2,
          city: payload.city,
          state: payload.state,
          country: payload.country,
          email: payload.email,
          phone: payload.phone,
          id: payload.id
        })
        .then(() => {
          commit(SET_RESET_USER_DETAILS_ERROR);
          commit(SET_USER_DETAILS_FORM_SENT, true);
        })
        .catch(({ response }) => {
          commit(SET_USER_DETAILS_ERROR, response.data.message);
          commit(SET_USER_DETAILS_FORM_SENT, false);
          throw new Error(response);
        });
    }
  },
  [FETCH_USER_DETAILS_BY_ID]({ commit }, addressId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDetailsService.getUserDetailsById(addressId)
        .then(({ data }) => {
          commit(SET_USER_DETAILS, data);
          commit(SET_RESET_USER_DETAILS_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_USER_DETAILS_ERROR, response.data.message);
          throw new Error(response);
        });
    }
  },
  [POST_USER_DETAILS_SET_DEFAULT]({ commit }, addressId) {
    if (JwtService.getToken()) {
      ApiService.setHeader();

      return UserDetailsService.postUserDetailsSetDefault(addressId)
        .then(() => {
          commit(SET_RESET_USER_DETAILS_ERROR);
        })
        .catch(({ response }) => {
          commit(SET_USER_DETAILS_ERROR, response.data.message);
          throw new Error(response);
        });
    }
  },
  [UPDATE_USER_DETAILS]({ commit }, userDetails) {
    commit(SET_USER_DETAILS, userDetails);
  },
  [SET_USER_DETAILS_FORM_SENT_FLAG]({ commit }, flag) {
    commit(SET_USER_DETAILS_FORM_SENT, flag);
  },
  [SET_USER_DETAILS_ADD_STATE_FLAG]({ commit }, flag) {
    commit(SET_USER_DETAILS_ADD_STATE, flag);
  },
  [SET_USER_DETAILS_EDIT_STATE_FLAG]({ commit }, flag) {
    commit(SET_USER_DETAILS_EDIT_STATE, flag);
  },
  [SET_USER_DETAILS_OTHER_EDIT_STATE_FLAG]({ commit }, flag) {
    commit(SET_USER_DETAILS_OTHER_EDIT_STATE, flag);
  },
  [RESET_USER_DETAILS]({ commit }) {
    commit(SET_RESET_USER_DETAILS);
  },
  [RESET_USER_DETAILS_MODULE]({ commit }) {
    commit(SET_RESET_USER_DETAILS_MODULE);
  }
};

const mutations = {
  [SET_USER_DETAILS](state, userDetails) {
    state.userDetails.id = userDetails.id;
    state.userDetails.entityType = userDetails.personType;
    state.userDetails.postalCode = userDetails.postalCode;
    state.userDetails.registrantName = userDetails.registrantName;
    state.userDetails.entityName = userDetails.entityName;
    state.userDetails.identificationNumber = userDetails.cnp;
    state.userDetails.referenceNumber = userDetails.registrationNumber;
    state.userDetails.address1 = userDetails.address1;
    state.userDetails.address2 = userDetails.address2;
    state.userDetails.city = userDetails.city;
    state.userDetails.state = userDetails.state;
    state.userDetails.country = userDetails.country;
    state.userDetails.email = userDetails.email;
    state.userDetails.phone = userDetails.phone;
  },
  [SET_USER_DETAILS_ADDRESSES](state, data) {
    if (data && data.length) {
      state.userDetailsAddresses = data;
    }
  },
  [SET_USER_DETAILS_DEFAULT](state, data) {
    for (const item of data) {
      if (item.dropCatch === true) {
        state.userDetailsDefault = { ...item };
        break;
      }
    }
  },
  [SET_USER_DETAILS_NON_DEFAULT](state, data) {
    const tempUserDetailsNonDefault = data.filter(item => {
      if (item.dropCatch !== true) {
        return item;
      }
    });

    if (tempUserDetailsNonDefault && tempUserDetailsNonDefault.length) {
      state.userDetailsNonDefault = tempUserDetailsNonDefault;
    }
  },
  [SET_USER_DETAILS_LOADING](state, flag) {
    state.isUserDetailsLoading = flag;
  },
  [SET_USER_DETAILS_FORM_SENT](state, flag) {
    state.isUserDetailsFormSent = flag;
  },
  [SET_USER_DETAILS_ADD_STATE](state, flag) {
    state.isUserDetailsAddState = flag;
    state.isUserDetailsEditState = false;
    state.isUserDetailsOtherEditState = false;
  },
  [SET_USER_DETAILS_EDIT_STATE](state, flag) {
    state.isUserDetailsAddState = false;
    state.isUserDetailsEditState = flag;
    state.isUserDetailsOtherEditState = false;
  },
  [SET_USER_DETAILS_OTHER_EDIT_STATE](state, flag) {
    state.isUserDetailsAddState = false;
    state.isUserDetailsEditState = false;
    state.isUserDetailsOtherEditState = flag;
  },
  [SET_USER_DETAILS_ERROR](state, error) {
    state.userDetailsError = error;
  },
  [SET_RESET_USER_DETAILS](state) {
    for (let f in state.userDetails) {
      Vue.set(state.userDetails, f, initialState.userDetails[f]);
    }
  },
  [SET_RESET_USER_DETAILS_ERROR](state) {
    state.userDetailsError = null;
  },
  [SET_RESET_USER_DETAILS_MODULE](state) {
    for (let f in state) {
      Vue.set(state, f, initialState[f]);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
